@media only screen and (min-width:768px) and (max-width:992px) {
 
  
    // ===============Personal Detail===================
    .personal-detail {
        
        .form-area {
            .custm-btn-sec {
                // display: block !important;
            }

            #defaultResumeBtn {
                padding: 10px 40px;
                border-radius: 40px;
                background: #1B375C;
                font-size: 16px;
                text-decoration: none;
                color: #FFF;
                text-align: center;
                font-weight: 500;
                border: 1px solid #1B375C;
            }

            padding: 22px;

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .exp-input {
                    display: block !important;
                }


                .verify-btn {
                    padding: 6px 28px;
                }

                .cus-pass-section {
                    span {
                        top: 47px;
                    }
                }

                .cv-upload {
                    .upload {
                        position: relative;

                        .cv-upload-input-cus {
                            width: 100%;
                        }

                        img {
                            width: 30px;
                            height: 30px;
                            top: 0;
                            position: absolute;
                            right: 0;
                        }
                    }

                    .resume {
                        h4 {
                            font-size: 16px;
                        }

                        p {
                            font-size: 14px;
                        }
                    }

                }


                .cus-verify {
                    img {
                        display: none;
                    }
                }

                .profile-edit-btn {
                    .edit-btn {
                        a {
                            padding: 5px 40px;
                        }
                    }
                }

                .skip {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .next {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }
            }
        }
    }

    // ===============Educational Detail===================
    .educational-detail {
        .form-area {
            padding: 22px;

            .edu-title {
                h1 {
                    font-size: 20px;
                    padding: 10px 0;
                }
            }

            .table-responsive {
                .table-hover {
                    tbody {
                        tr {
                            td {
                                .defaultResumeBtn {
                                    padding: 5px 15px;
                                    border-radius: 40px;
                                    background: #1B375C;
                                    font-size: 16px;
                                    text-decoration: none;
                                    color: #FFF;
                                    text-align: center;
                                    font-weight: 500;
                                    border: 1px solid #1B375C;
                                }

                                .view_resume_btn {
                                    padding: 5px 15px;
                                    border-radius: 40px;
                                    border: 1px solid #1B375C;
                                    background: #FFF;
                                    text-decoration: none;
                                    color: #1B375C;
                                    text-align: center;
                                    font-size: 16px;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                .skip {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .next {
                    padding: 8px 35px;
                    font-size: 16px;
                }
            }

            .custm-btn-sec {
                display: block !important;
            }
        }
    }

    // ===============Professional Detail===================
    .professional-detail {
        .form-area {
            padding: 22px;

            .edu-title {
                h1 {
                    font-size: 20px;
                    padding: 10px 0;
                }
            }

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .work-confo {
                    display: block !important;
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                .skip {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .next {
                    padding: 8px 35px;
                    font-size: 16px;
                }
            }

            .custm-btn-sec {
                display: block !important;
            }
        }
    }

    // ===============Professional Detail===================
    .project-detail {
        .form-area {
            padding: 22px;
            form {
                .skill-select-cus span + div {
                    justify-content: center;
                    align-items: center;
                    padding: 0px 5px;
                }
            }
            .project-title {
                h1 {
                    font-size: 20px;
                    padding: 10px 0;
                }
            }

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                .skip {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .next {
                    padding: 8px 35px;
                    font-size: 16px;
                }
            }

            .custm-btn-sec {
                display: block !important;
            }
        }
    }

    // ===============Certification===================
    .certification {
        .form-area {
            padding: 22px;

            .project-title {
                h1 {
                    font-size: 20px;
                    padding: 10px 0;
                }
            }

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                .skip {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .next {
                    padding: 8px 35px;
                    font-size: 16px;
                }
            }

            .custm-btn-sec {
                display: block !important;
            }
        }
    }

    // ===============Social Median===================
    .social-media {
        .form-area {
            padding: 22px;

            .project-title {
                h1 {
                    font-size: 20px;
                    padding: 10px 0;
                }
            }

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                }

                .next {
                    padding: 10px 15px;
                    border-radius: 40px;
                    background: #1B375C;
                    font-size: 15px;
                    text-decoration: none;
                    color: #FFF;
                    text-align: center;
                    font-weight: 500;
                    border: none;
                }
                .skip {
                    padding: 10px 37px;
                    border-radius: 40px;
                    border: 1px solid #1B375C;
                    background: #FFF;
                    text-decoration: none;
                    color: #1B375C;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 500;
                }
            }

            .custm-btn-sec {
                display: block !important;
            }
        }
    }


    // ===============Key Skills===================
    .key-skills {
        .form-area {
            padding: 22px;

            .project-title {
                h1 {
                    font-size: 20px;
                    padding: 10px 0;
                }
            }

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                .skip {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .next {
                    padding: 8px 35px;
                    font-size: 16px;
                }
            }

            .custm-btn-sec {
                display: block !important;
            }
        }
    }

    @media only screen and (min-width:200px) and (max-width:767px) {

        // ===============Personal Detail===================
        .form-area {
            .personal-detail {
                .cv-upload {
                    .resume {
                        h4 {
                            font-size: 14px !important;
                        }

                        p {
                            font-size: 14px !important;
                        }
                    }
                }
            }
        }
    }

}

@media only screen and (min-width:200px) and (max-width:767px) {
    // .custom-stepper div div {
    //     flex-direction: column;
    //     display: flex  !important;
    // }
    // .hidden-div{
    //     border-top: none !important;
    //     border-left: 1px solid rgb(224, 224, 224);
    //     height: 100% !important;
    //     z-index: -1;
    //     right: 0 !important;
    //     left: 3% !important;
    // }
    // .stepper-box{
    //     flex-direction: row !important;
    //     width: 100% !important;
    //     align-items: start !important;
    //     padding-top: 34px !important;
    // }
    // .custom-stepper div > div > div {
    //     // flex-direction: row;
    //     // width: 100% !important;
     
    //     gap: 10px;
    // }
    
    // .custom-stepper div > div > div > div {

    //     margin: 0 !important;
    
    // }
    // ===============Personal Detail===================
    .personal-detail {
        .form-area {
            .custm-btn-sec {
                display: block !important;
            }

            padding: 22px;

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .exp-input {
                    display: block !important;
                }


                .verify-btn {
                    padding: 6px 28px;
                }

                .cus-pass-section {
                    span {
                        top: 47px;
                    }
                }

                .cv-upload {
                    .upload {
                        position: relative;

                        .cv-upload-input-cus {
                            width: 100%;
                        }

                        img {
                            width: 30px;
                            height: 30px;
                            top: 0;
                            position: absolute;
                            right: 0;
                        }
                    }

                    .resume {
                        h4 {
                            font-size: 16px;
                        }

                        p {
                            font-size: 14px;
                        }
                    }

                }


                .cus-verify {
                    img {
                        display: none;
                    }
                }

                .profile-edit-btn {
                    .edit-btn {
                        a {
                            padding: 5px 40px;
                        }
                    }
                }

                .skip {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .next {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }
            }
        }
    }

    // ===============Educational Detail===================
    .educational-detail {
        
        .form-area {
            padding: 22px;
            .cust-add-btn {
                padding: 7px 40px;
                border-radius: 10px;
                border: 1px solid rgba(10, 101, 204, 0.5);
                color: #0A65CC;
                text-decoration: none;
                font-size: 14px;
                font-weight: 500;
                background-color: unset;
            }
            .next {
                padding: 10px 15px;
                border-radius: 40px;
                background: #1B375C;
                font-size: 15px;
                text-decoration: none;
                color: #FFF;
                text-align: center;
                font-weight: 500;
                border: none;
            }
            .skip {
                padding: 10px 37px;
                border-radius: 40px;
                border: 1px solid #1B375C;
                background: #FFF;
                text-decoration: none;
                color: #1B375C;
                text-align: center;
                font-size: 15px;
                font-weight: 500;
            }
            .edu-title {
                h1 {
                    font-size: 15px;
                    padding: 10px 0;
                }
            }

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                .skip {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .next {
                    padding: 8px 35px;
                    font-size: 16px;
                }
            }

            .custm-btn-sec {
                display: block !important;
            }
        }
    }

    // ===============Professional Detail===================
    .professional-detail {
        .form-area {
            padding: 22px;
           .cust-add-btn {
                padding: 8px 45px;
                border-radius: 10px;
                border: 1px solid rgba(10, 101, 204, 0.5);
                color: #0A65CC;
                text-decoration: none;
                font-size: 14px;
                font-weight: 500;
                background-color: unset;
            }
            .next {
                padding: 10px 15px;
                border-radius: 40px;
                background: #1B375C;
                font-size: 15px;
                text-decoration: none;
                color: #FFF;
                text-align: center;
                font-weight: 500;
                border: none;
            }
            .skip {
                padding: 10px 37px;
                border-radius: 40px;
                border: 1px solid #1B375C;
                background: #FFF;
                text-decoration: none;
                color: #1B375C;
                text-align: center;
                font-size: 15px;
                font-weight: 500;
            }
            .edu-title {
                h1 {
                    font-size: 15px;
                    padding: 10px 0;
                }
            }

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .work-confo {
                    display: block !important;
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                .skip {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .next {
                    padding: 8px 35px;
                    font-size: 16px;
                }
            }

            .custm-btn-sec {
                display: block !important;
            }
        }
    }

    // ===============Professional Detail===================
    .project-detail {
       
        .form-area {
            padding: 22px;
            form {
                .skill-select-cus span + div {
                    justify-content: center;
                    align-items: center;
                    padding: 0px 5px;
                }
            }
            .cust-add-btn {
                padding: 7px 40px;
                border-radius: 10px;
                border: 1px solid rgba(10, 101, 204, 0.5);
                color: #0A65CC;
                text-decoration: none;
                font-size: 14px;
                font-weight: 500;
                background-color: unset;
            }
            .next {
                padding: 10px 15px;
                border-radius: 40px;
                background: #1B375C;
                font-size: 15px;
                text-decoration: none;
                color: #FFF;
                text-align: center;
                font-weight: 500;
                border: none;
            }
            .skip {
                padding: 10px 37px;
                border-radius: 40px;
                border: 1px solid #1B375C;
                background: #FFF;
                text-decoration: none;
                color: #1B375C;
                text-align: center;
                font-size: 15px;
                font-weight: 500;
            }
            .edu-title {
                h1 {
                    font-size: 15px;
                    padding: 10px 0;
                }
            }
            .project-title {
                h1 {
                    font-size: 15px;
                    padding: 10px 0;
                }
            }

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                .skip {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .next {
                    padding: 8px 35px;
                    font-size: 16px;
                }
            }

            .custm-btn-sec {
                display: block !important;
            }
        }
    }

    // ===============Certification===================
    .certification {
        .form-area {
            padding: 22px;
            .cust-add-btn {
                font-size: 15px;
                margin-bottom: 5px;
            }
           .edu-title h1 {
                color: #333;
                text-align: center;
                font-family: Barlow;
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 0px;
            }
            .next {
                padding: 10px 15px;
                border-radius: 40px;
                background: #1B375C;
                font-size: 15px;
                text-decoration: none;
                color: #FFF;
                text-align: center;
                font-weight: 500;
                border: none;
            }
            .skip {
                padding: 10px 37px;
                border-radius: 40px;
                border: 1px solid #1B375C;
                background: #FFF;
                text-decoration: none;
                color: #1B375C;
                text-align: center;
                font-size: 15px;
                font-weight: 500;
            }
            .project-title {
                h1 {
                    font-size: 15px;
                    padding: 10px 0;
                }
            }

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                .skip {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .next {
                    padding: 8px 35px;
                    font-size: 16px;
                }
            }

            .custm-btn-sec {
                display: block !important;
            }
        }
    }

    // ===============Social Median===================
    .social-media {
        .form-area {
            padding: 22px;

            .project-title {
                h1 {
                    font-size: 15px;
                    padding: 10px 0;
                    margin-bottom: 0;
                }
            }

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                }

                // .next {
                //     padding: 8px 35px;
                //     font-size: 16px;
                // }
                .next {
                    padding: 10px 15px;
                    border-radius: 40px;
                    background: #1B375C;
                    font-size: 12px;
                    text-decoration: none;
                    color: #FFF;
                    text-align: center;
                    font-weight: 500;
                    border: none;
                }
                .skip {
                    padding: 10px 37px;
                    border-radius: 40px;
                    border: 1px solid #1B375C;
                    background: #FFF;
                    text-decoration: none;
                    color: #1B375C;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 500;
                }
            }

            .custm-btn-sec {
                display: block !important;
            }
        }
    }


    // ===============Key Skills===================
    .key-skills {
        .form-area {
            padding: 22px;
            .cust-add-btn {
                font-size: 15px;
            }
            .next {
                padding: 10px 15px;
                border-radius: 40px;
                background: #1B375C;
                font-size: 15px;
                text-decoration: none;
                color: #FFF;
                text-align: center;
                font-weight: 500;
                border: none;
            }
            .skip {
                padding: 10px 37px;
                border-radius: 40px;
                border: 1px solid #1B375C;
                background: #FFF;
                text-decoration: none;
                color: #1B375C;
                text-align: center;
                font-size: 15px;
                font-weight: 500;
            }
            .project-title {
                h1 {
                    font-size: 15px;
                    padding: 10px 0;
                }
            }

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                .skip {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .next {
                    padding: 8px 35px;
                    font-size: 16px;
                }
            }

            .custm-btn-sec {
                display: block !important;
            }
        }
    }

    @media only screen and (min-width:200px) and (max-width:767px) {

        // ===============Personal Detail===================
        .form-area {
            .personal-detail {
                .cv-upload {
                    .resume {
                        h4 {
                            font-size: 14px !important;
                        }

                        p {
                            font-size: 14px !important;
                        }
                    }
                }
            }
        }
    }

}