.most-view-job-section {
  margin: 10px 0px 30px 0px;
  /* silder css start */
}
.most-view-job-section .second-section .similer-post-heading {
  margin-bottom: 50px;
}
.most-view-job-section .second-section .slick-slider .slick-next:before,
.most-view-job-section .second-section .slick-slider .slick-prev:before {
  font-size: 26px;
  line-height: 1;
  opacity: 0.75;
  color: rgb(27, 55, 92);
}
.most-view-job-section .second-section .slick-slider .slick-next,
.most-view-job-section .second-section .slick-slider .slick-prev {
  font-size: 0;
  line-height: 0;
  top: -20%;
  width: 20px;
  height: 20px;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
}
.most-view-job-section .second-section .slick-slider .slick-next {
  right: 40px !important;
}
.most-view-job-section .second-section .slick-slider .slick-prev {
  right: 90px !important;
  left: unset;
}
.most-view-job-section .second-section .slick-slider .slick-next:before {
  position: absolute;
  content: "\f054";
  color: #ffffff;
  font-size: 12px;
  font-family: fontawesome;
  background: rgb(27, 55, 92);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 24px;
  border: 1px solid rgb(27, 55, 92);
}
.most-view-job-section .second-section .slick-slider .slick-prev:before {
  position: absolute;
  content: "\f053";
  color: #ffffff;
  font-size: 12px;
  font-family: fontawesome;
  background: rgb(27, 55, 92);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 24px;
  border: 1px solid rgb(27, 55, 92);
}
.most-view-job-section .second-section {
  border-radius: 4px;
  background: #FFF;
  margin-top: 30px;
  padding: 30px 15px 15px 15px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.most-view-job-section .second-section .similer-post-heading h2 {
  font-size: 32px;
  font-weight: 700;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.most-view-job-section .card {
  padding: 20px !important;
  margin: 0px 10px;
  max-height: 240px;
  min-height: 240px;
}
.most-view-job-section .card .card-sub-dv {
  display: flex;
}
.most-view-job-section .card .card-sub-dv .company_image {
  border: 1px solid #ccc;
  width: 65px;
  height: 65px;
  border-radius: 5px;
}
.most-view-job-section .card .card-sub-dv .company_image img {
  border: 1px solid #ccc;
  width: 65px;
  height: 65px;
  border-radius: 5px;
  -o-object-fit: contain;
     object-fit: contain;
}
.most-view-job-section .card .card-sub-dv .company_image img[alt] {
  font-size: 0.75em;
}
.most-view-job-section .card .footer-heading {
  text-align: left;
}
.most-view-job-section .card .footer-heading .cursor-default {
  cursor: default;
}
.most-view-job-section .card .footer-heading p a.remove-link-style {
  color: black;
  text-decoration: none;
}
.most-view-job-section .card .footer-heading h3 {
  color: #141414;
  font-size: 20px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.most-view-job-section .card .footer-heading h3 a {
  color: #000;
  text-decoration: none;
}
.most-view-job-section .card .footer-heading p {
  color: #565656;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.most-view-job-section .card .footer-heading p span a {
  color: #2B85FF;
  text-decoration: none;
  font-size: 12px;
  margin-left: 25px;
  font-weight: 500;
  border-radius: 3px;
  background: rgba(43, 133, 255, 0.1);
  padding: 3px 6px;
}
.most-view-job-section .card .footer-list-card {
  text-align: left;
}
.most-view-job-section .card .footer-list-card ul {
  padding: unset;
}
.most-view-job-section .card .footer-list-card ul li {
  list-style: none;
  color: #565656;
  font-size: 16px;
  font-weight: 400;
  margin-top: 5px;
}
.most-view-job-section .card .footer-list-card ul li img {
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .most-view-job-section .card {
    margin: 0px 2px;
    height: 190px;
    padding: 20px !important;
  }
  .most-view-job-section .card .footer-heading p {
    color: #565656;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 0px;
  }
  .most-view-job-section .card .footer-heading p span a {
    margin-left: 0;
  }
  .most-view-job-section .card .card-sub-dv {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
  }
  .most-view-job-section .card .card-sub-dv .footer-heading h3 {
    margin-bottom: 0px;
  }
  .most-view-job-section .card .card-sub-dv .footer-heading h3 .footer-heading h3 {
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .most-view-job-section .card {
    margin: 0px 2px;
    padding: 20px !important;
  }
  .most-view-job-section .card .footer-heading p {
    color: #565656;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
  }
  .most-view-job-section .card .footer-heading p span a {
    margin-left: 0;
  }
  .most-view-job-section .card .card-sub-dv {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }
  .most-view-job-section .card .card-sub-dv .footer-heading h3 {
    margin-bottom: 0px;
  }
  .most-view-job-section .card .card-sub-dv .footer-heading p {
    margin-bottom: 0px;
  }
  .most-view-job-section .card .card-sub-dv .footer-heading .footer-heading h3 {
    margin-bottom: 0px;
  }
  .most-view-job-section .card .card-sub-dv .footer-heading .footer-heading p {
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 320px) {
  .most-view-job-section .card .card-sub-dv {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }
  .most-view-job-section .card .card-sub-dv .footer-heading h3 {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 600;
  }
  .most-view-job-section .card .card-sub-dv .footer-heading p {
    margin-bottom: 0px;
    font-size: 12px;
  }
  .most-view-job-section .card .card-sub-dv p {
    margin-bottom: 0px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 767px) {
  .most-view-job-section .second-section .similer-post-heading h2 {
    font-size: 25px;
    margin-left: 0px !important;
    font-weight: 700;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .most-view-job-section .second-section .slick-slider .slick-prev {
    right: 70px !important;
    left: unset;
  }
  .most-view-job-section .second-section .slick-slider .slick-next,
  .most-view-job-section .second-section .slick-slider .slick-prev {
    font-size: 0;
    line-height: 0;
    top: -15%;
    width: 20px;
    height: 20px;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0;
  }
}/*# sourceMappingURL=MostViewedJobsSlider.css.map */