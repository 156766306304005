.recruiter-advice {
    .header-banner {
        height: 450px;
        background-image: url(../../../../../public/assets/images/recruiter-advice/banner.png);
        background-position: 100%;
        background-size: cover;
    }
}

// ======================

.custm-rec-sec {
    .recruiter-detail {
        padding: 20px 0 0 20px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .head-area {
        h2 {
            text-align: left;
            color: #1B375C;
            font-family: Barlow;
            font-size: 48px;
            font-weight: 700;
            padding: 0 0 30px 0;
        }
    }

    .custm-bud-text {
        h4 {
            text-align: left;
            color: #1B375C;
            font-family: Barlow;
            font-size: 28px;
            font-weight: 700;
            padding: 50px 0 0 0;
        }

        p {
            text-align: left;
            color: #1E1E1E;
            text-align: justify;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 176%;
            padding: 0 60px 0 0;
        }
    }

    .custm-bud-img {
        img {
            width: 400px;
            height: 350px;
            object-fit: cover;
            border-radius: 10px;
            border-radius: 25px;
            border: 10px solid #fff;
            background: #FFF;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
        }

    }
}



@media only screen and (min-width:568px) and (max-width:992px) {}

@media only screen and (min-width:200px) and (max-width:567px) {
    .custm-rec-sec {
        .head-area {
            h2 {
                font-size: 22px;
                padding: 20px 0;
            }
        }

        .recruiter-detail {
            padding: unset;

            .custm-bud-text {
                h4 {
                    font-size: 22px;
                    padding: unset;
                }

                p {
                    padding: unset;
                    font-size: 15px;
                }

            }
        }
    }
}