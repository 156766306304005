.assessment-question-sec {
  /* Answered */
  /* not answered */
  /* Timmer test */
  /*  */
}
.assessment-question-sec .color {
  background: #1B375C;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}
.assessment-question-sec .cust_text {
  color: #1B375C;
  font-size: 25px;
}
.assessment-question-sec .cust_box {
  box-shadow: inset -1px -4px 5px 7px rgba(240, 231, 240, 0.62);
}
.assessment-question-sec .cust-margin_whole {
  margin: 5px;
}
.assessment-question-sec .cust_detail {
  /* -webkit-box-shadow: 0px 3px 2px 7px rgba(240,235,240,0.42);
  -moz-box-shadow: 0px 3px 2px 7px rgba(240,235,240,0.42);
  box-shadow: 0px 3px 2px 7px rgba(240,235,240,0.42); */
  box-shadow: 0px 6px 16px 0px rgba(223, 223, 223, 0.4196078431);
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
  border-radius: 10px;
  border: 1px solid rgba(223, 223, 223, 0.4196078431);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  min-width: auto;
  min-height: 60px;
  text-align: center;
}
.assessment-question-sec .cust_detail a {
  text-decoration: none;
  color: #000;
}
.assessment-question-sec .cust_detail a .text {
  margin-left: 10px;
}
.assessment-question-sec .ans {
  font-size: 16px;
  font-weight: 500;
  padding: 2px;
}
.assessment-question-sec .notans {
  font-size: 16px;
  font-weight: 500;
  padding: 2px;
}
.assessment-question-sec .cust_form {
  box-shadow: inset 4px -3px 8px 2px rgba(240, 235, 240, 0.3);
  margin-left: 10px;
  margin: 5px;
}
.assessment-question-sec .cust_flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.assessment-question-sec .timer {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}
.assessment-question-sec .ques_heading {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}
.assessment-question-sec .cust_ques {
  box-shadow: -7px 7px 8px 2px rgba(240, 235, 240, 0.58);
  margin: 5px 0px 0px 0px;
  padding: 8px;
  border: 2px solid #1B375C;
  border-radius: 5px;
}
.assessment-question-sec .cust_list {
  box-shadow: -7px 7px 8px 2px rgba(240, 235, 240, 0.58);
  margin: 5px 0px 0px 0px;
  padding: 8px;
}
.assessment-question-sec .cust_list label {
  width: 100%;
}
.assessment-question-sec .cust_button1 {
  width: 140px;
  height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  box-shadow: -2px 7px 8px 9px rgba(240, 235, 240, 0.56);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 25px;
}
.assessment-question-sec .cust_button2 {
  width: 140px;
  height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  box-shadow: -2px 7px 8px 9px rgba(240, 235, 240, 0.56);
  margin-top: 10px;
  margin-bottom: 10px;
}
.assessment-question-sec .cust_buttonsub {
  box-shadow: inset -2px 7px 8px 9px rgba(240, 235, 240, 0.65);
}
.assessment-question-sec .cust_head {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 20px;
  margin-right: 25px;
  align-items: center;
}
.assessment-question-sec .ques {
  box-shadow: 0px 6px 16px 0px rgba(223, 223, 223, 0.4196078431);
  border: 1px solid rgba(223, 223, 223, 0.4196078431);
  margin-left: 0px;
  margin-right: 0px;
  padding: 5px;
  margin-top: 20px;
  border-radius: 10px;
}
.assessment-question-sec .cust_para {
  color: #1D2026;
  font-weight: 500;
  line-height: 20.5px;
}
.assessment-question-sec .cust_para p {
  margin-bottom: 0px;
}
.assessment-question-sec .ques-section {
  line-height: 2.5;
  word-spacing: 5.5px;
  text-align: center;
}
.assessment-question-sec .ques-section button {
  margin: 5px 10px;
  padding: 0px;
  border-radius: 50% !important;
  height: 40px;
  width: 40px;
  line-height: 30px;
  text-align: center;
}
.assessment-question-sec .cust_form {
  margin-left: 0;
  margin: 0px;
  /* .cust_ques{
      margin:0px 0px 0px 0px ;
      padding: 0px;

  } */
}
.assessment-question-sec .cust_form .cust_head {
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 0px;
  margin-right: 0px;
}
.assessment-question-sec .cust_form .ques {
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
  margin-top: 10px;
}
.assessment-question-sec .cust_form .ques-section {
  word-spacing: 2.5;
}

@media screen and ((min-width: 320px) and (max-width: 576px)) {
  .assessment-question-sec .cust_text {
    font-size: 18px;
  }
  .assessment-question-sec .ques_heading {
    font-size: 18px;
  }
  .assessment-question-sec .timer {
    font-size: 18px;
  }
  .assessment-question-sec .cust_head {
    margin-left: 5px;
    margin-right: 5px;
  }
}
@media screen and (min-width: 1440px) {
  .assessment-question-sec .ques-section {
    line-height: 3.5;
    word-spacing: 29.5px;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .assessment-question-sec .ques-section {
    line-height: 2.5;
    word-spacing: 4.5px;
    text-align: center;
  }
}/*# sourceMappingURL=AssessmentQuestion.css.map */