.invite-interview {

  .btn-outline-primary {
    width: 33%;
  }

  .btn-check:checked+.btn-outline-primary {
    background-color: #007bff;
    color: white;
  }

  .btn-check:focus+.btn-outline-primary {
    box-shadow: none;
  }

  .form-select,
  .form-control {
    margin-bottom: 15px;
  }

  .modal-footer {
    border-top: none;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .btn-secondary,
  .btn-primary {
    width: fit-content;
    display: flex;
  }

  p {
    font-size: 14px;
  }

  box-shadow: 0 0 10px rgb(0 0 0 / 38%);
  border-radius: 8px;

  .accordion-button:not(.collapsed) {
    box-shadow: none;
    background: none;
  }

  .accordion {
    .accordion-button:focus {
      box-shadow: none !important;
    }

    .accordion-button {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .accordion-body {
      h3 {
        font-size: 18px !important;
        font-weight: 500 !important;
        color: #999;
      }

      h5 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
      }

      p {
        margin-bottom: 5px;
      }

      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    margin: 0 20px;

    .accordion-item {
      border-top: none;
      border-left: none;
      border-right: none;

      h3 {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  .radio-group label {
    width: auto !important;
  }

  .radio-group label:hover {
    background-color: #f0f0f0;
  }

  .radio-group input[type="radio"]:checked+label {
    background-color: #0056b3;
    color: white;
    border-color: #0056b3;
  }

  .radio-group input[type="radio"]:checked+label {
    svg {
      fill: #fff;
    }
  }

  .radio-group label:first-of-type {
    border-radius: 5px 0 0 5px;
  }

  .radio-group label:last-of-type {
    border-radius: 0 5px 5px 0;
  }

  .radio-group label:not(:first-of-type):not(:last-of-type) {
    border-radius: 0;
  }

  .profile-header {
    // display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
    // border-bottom: 1px solid #ddd;

    .btn-group {
      .dropdown-menu {
        li.invite-interview-list-cursor-pointer {
          cursor: pointer;
        }
      }
    }
  }


  .profile-actions {
    flex-wrap: wrap !important;
    row-gap: 10px !important;
   
    button {
      margin-right: 10px;
    }

    .interview-btn {
      background-color: #1b375c;
      color: white;
    }
  }

  .profile-info {
    padding: 0px 20px 20px 20px;

    h3 {
      font-size: 20px;
      font-weight: 600;
      margin: 10px 0px;
    }

    p {
      word-break: break-all;
      margin: 5px 0;
    }
  }

  .profile-status {
    background-color: #e8f5e9;
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .alert-info {
    margin: 0 20px 20px 20px;
    background-color: #f1f3f4;
  }

}

.text-xs {
  font-size: 12px !important;
}

input[type="date"],
input[type="time"] {
  border: 1px solid #767676;
  padding: .375rem .75rem;
  border-radius: 0.375rem;
}

.custom-modal {
  .option-group {
    margin-bottom: 1rem;
  }

  .update-interview-form {
    .interview-request-submit-button {
      color: white;
      background-color: #08385c;
    }
  }

  .option-label {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    // border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    cursor: pointer;
  }

  .option-label:hover {
    background-color: #f8f9fa;
  }

  .option-label input {
    margin-right: 1rem;
  }

  .recommended-badge {
    background-color: #e7f3ff;
    color: #007bff;
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    font-size: 0.875rem;
    margin-left: 1rem;
  }

  .option-description {
    margin-left: 2rem;
    color: #6c757d;
    font-size: 0.875rem;
  }

  // #availability-tab {
  //   border-radius: 5px 0 0 5px;
  // }

  #specific-time-tab {
    border-radius: 0 5px 5px 0;
  }

  .custom-tabs {
    border-bottom: none;
    margin-bottom: 20px;
  }

  .custom-tabs .nav-item {
    margin-right: 0;
  }

  .custom-tabs .nav-link {
    border: 1px solid #999;

    border-radius: 0;
    color: #000;
    font-weight: bold;
  }

  .custom-tabs .nav-link.active {
    background-color: #000;
    color: #fff;
  }
}

@media screen and (min-width:992px) and (max-width:1199px) {
  .custom-modal {
    .flex-lg-col {
      flex-direction: column;
    }
  }

}

@media screen and (max-width:575px) {
  .custom-modal {
    .flex-lg-col {
      flex-direction: column;
    }
  }

  .invite-interview {
    .profile-header {
      padding: 12px 3px;
    }

    .accordion {
      .accordion-body {
        h3 {
          font-size: 16px !important;
        }
      }
    }
  }

}
