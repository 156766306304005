.submitted-successfull-return-job-section {
    .second-section {
        border-radius: 4px;
        background: #FFF;
        margin: 50px 0px;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .main-head {
        align-items: center;
        padding: 58px;
    }

    .para {
        color: #1D2026;
        font-size: 16px;
        text-align:left ;
    }

    .head {
        color: #1D2026;
        font-size: 42px;
        font-style: normal;
        text-align: left;
    }

    .head2 {
        color: #1D2026;
        font-size: 26px;
        font-style: normal;
        text-align: left;
    }

    .banner-btn {
        border-radius: 40px;
        width: 190px;
        margin: 0px 10px;
        padding: 10px 0px;
        background: #1B375C;
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
    }

    .custom-btn {
        padding: 30px 116px 30px 116px;
        background: #FFF;
        box-shadow: 0px 16px 80px 0px rgba(0, 0, 0, 0.12);
    }

    .btn-cus {
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        display: flex;
    }

    .popup-bg {
        border-radius: 10px;
        background: #1B375C;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .modal-header-custom {
        border-bottom: unset;
        padding: 12px;
    }

    .pop-custom button {
        border-radius: 40px;
        background: #00A1FF;
        padding: 12px 82.5px 13px 82.5px;
        font-size: 22px;
        height: 59px;
        color: #1B375C;
        font-weight: 500;
        border: none;
        margin-top: 15px;
    }

    .pop-custom p {
        color: #FFF;

        font-size: 30px;

        font-weight: 700;


    }

    .pop-custom {
        display: flex;
        justify-content: space-evenly;

    }

    .para span {
        color: blue;
        font-size: 18px;
    }


    .btn-popup {
        border-radius: 1.25rem;
        background-color: white;
    }
}


@media only screen and (min-width:767px) and (max-width:992px) {
    .submitted-successfull-return-job-section {
        .main-head {
            align-items: center;
            padding: 10px;
        }
        .pop-custom {
            display: flex;
            justify-content: space-evenly;
            p {
                color: #FFF;
                font-size: 16px;
                font-weight: 500;
            }
            button{
                border-radius: 40px;
                background: #00A1FF;
                padding: 12px 34px 10px 34px;
                font-size: 13px;
                height: 57px;
                color: #1B375C;
                font-weight: 500;
                border: none;
                margin-top: 0;
            }
        }
        .head {
            line-height: 38px;
            font-size: 30px;
        }

        .head2 {
            line-height: 36px;
            font-size: 24px;
        }

        .custom-btn {
            padding: 0px;
            box-shadow: unset;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
        }

        .banner-btn {
            width: 230px;
            padding: 8px 0px;
            border: none;
            outline: none;
        }

        .para {
            font-size: 16px;
        }

        .mark-top {
            margin-top: 20px !important;
        }
    }

}
@media only screen and (min-width:200px) and (max-width:767px) {
    .submitted-successfull-return-job-section {
        .main-head {
            align-items: center;
            padding: 10px;
        }
        .pop-custom {
            display: flex;
            justify-content: space-evenly;
            flex-direction: column;
            p {
                color: #FFF;
                font-size: 16px;
                font-weight: 500;
            }
            button{
                border-radius: 40px;
                background: #00A1FF;
                padding: 12px 34px 10px 34px;
                font-size: 13px;
                height: 57px;
                color: #1B375C;
                font-weight: 500;
                border: none;
                margin-top: 0;
            }
        }
        .head {
            line-height: 38px;
            font-size: 30px;
        }

        .head2 {
            line-height: 36px;
            font-size: 24px;
        }

        .custom-btn {
            padding: 0px;
            box-shadow: unset;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
        }

         .banner-btn {
            padding: 0px;
            width: 100%;
            /* height: 43px; */
            padding: 8px 20px;
            border: none;
            outline: none;
            margin: 0px 5px;
        }

        .para {
            font-size: 16px;
        }

        .mark-top {
            margin-top: 20px !important;
        }
    }

}