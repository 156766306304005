.blog-list {
  .blog-head {
    position: relative;
    border-radius: 4px;
    background: #f4faff;
    box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5),
      0px 4px 20px 0px rgba(29, 95, 181, 0.2) inset;
    padding: 15px 0;

    h1 {
      color: #1b375c;
      font-family: Barlow;
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 0;
    }

    span {
      position: absolute;
      left: 0;
      padding-left: 20px;
      color: #0A65CC;

      .fa-arrow-left {
        margin-right: 10px;
      }
    }
    
  }

  .blog-info {
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5),
      -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
    margin-bottom: 80px;

    .blog-card {
      .category {
        img {
          width: 16px;
          height: 16px;
        }

        h5 {
          margin: 0 12px;
          padding: 5px 35px;
          border-radius: 2px;
          background: #d3e7f5;
          color: var(--Text, #151875);
          font-size: 14px;
          font-weight: 400;
        }
      }

      .blog-category {
        div.share-btn-blog {
          .st-center {
            .st-btn {
              display: inline-block !important;
            }
          }
        }
      }

      .date {
        img {
          width: 16px;
          height: 16px;
        }

        h5 {
          margin: 0 12px;
          padding: 5px 15px;
          border-radius: 2px;
          background: #ffe7f9;
          color: var(--Text, #151875);
          font-size: 14px;
          font-weight: 600;
        }
      }

      .blog-title {
        h2 {
          text-align: left;
          margin: unset;
          color: #1b375c;
          font-family: Barlow;
          font-size: 28px;
          font-weight: 700;
        }
      }

      .blog-para {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;


        text-align: left;
        color: var(--sub-text-color, #8a8fb9);
        font-size: 16px;
        font-weight: 400;
        // display: -webkit-box;
        // -webkit-line-clamp: 3;
        // -webkit-box-orient: vertical;
        // overflow: hidden;

      }

      .blog-para2 {
        p {
          text-align: left;
          color: #8a8fb9;
          font-size: 16px;
          font-weight: 400;
        }
      }

      .blog-para3 {
        background: #fafafb;
        padding: 0 10px;
        border-left: 2px solid #fc45a0;

        p {
          text-align: left;
          color: #969cb4;
          font-size: 18px;
          font-style: italic;
          font-weight: 600;
          line-height: 40px;
        }
      }

      .read-btn {
        a {
          background-color: unset;
          border: unset;
          color: #1b375c;
          font-size: 18px;
          font-weight: 600;
          text-decoration: none;
        }
      }
    }

    .view-all-btn {
      a {
        background-color: unset;
        border: unset;
        color: #1b375c;
        font-size: 18px;
        font-weight: 600;
        text-decoration: none;
      }
    }

    .blog-media {
      .media-box1 {
        position: relative;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .textarea {
        p {
          text-align: left;
          color: #8a8fb9;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 25.6px;
        }
      }
    }

    .category-box {
      margin-bottom: 40px;

      h4 {
        text-align: left;
        color: #1B375C;
        font-family: Barlow;
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      h5 {
        text-align: left;
        border-radius: 3px;
        background: #1B375C;
        color: #FFF;
        font-family: Barlow;
        font-size: 14px;
        font-weight: 400;
        padding: 10px 15px;
        margin-bottom: 20px;
      }

      ul {
        padding: unset;
        display: unset;
        justify-content: flex-start;

        li {
          width: 100%;
          word-break: break-word;
          list-style: none;

          &:hover {
            background-color: #1B375C;
            color: white
          }

        }

        // li::hover{
        //   color: #1B375C;
        // }

      }

      ul.d-flex-remove {
        display: unset !important;
      }
    }

    .search-box {
      margin-bottom: 30px;

      h4 {
        text-align: left;
        color: #1b375c;
        font-family: Barlow;
        font-size: 22px;
        font-weight: 600;
      }

      .custm-search {
        position: relative;

        img {
          position: absolute;
          right: 12px;
          top: 12px;
        }
      }
    }

  }

  .pagination-section nav.nav-class ul.pagination {
    justify-content: start;
  }

  li:hover {
    color: #1B375C;
  }

}