.personal-detail {
  padding: 50px 0;
}
.personal-detail .stepper-container {
  width: 100%;
  /* Adjust this value to the desired width */
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 20px;
}
.personal-detail .stepper-box {
  padding-left: 12px;
  padding-right: 12px;
  width: 16% !important;
}
.personal-detail .stepper-area {
  height: 100%;
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.personal-detail .form-area {
  padding: 40px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.personal-detail .form-area .profile-edit-btn {
  display: inline-grid;
  align-content: space-between;
}
.personal-detail .form-area .profile-edit-btn .edit-btn {
  padding: 8px 45px;
  border-radius: 50px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  background-color: unset;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}
.personal-detail .form-area .profile-edit-btn .edit-btn:hover {
  background-color: #0067cc;
  color: #fff;
}
.personal-detail .form-area .profile-edit-btn .vodeo-btn a {
  padding: 8px 17px;
  font-size: 14px;
  border-radius: 50px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  background-color: unset;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  color: #000;
}
.personal-detail .form-area .profile-edit-btn .vodeo-btn a:hover {
  background-color: #0067cc;
  color: #fff;
}
.personal-detail .form-area form label {
  text-align: left;
}
.personal-detail .form-area form label span {
  color: red;
}
.personal-detail .form-area form .cv-upload-input-cls {
  cursor: pointer;
}
.personal-detail .form-area form .cv-upload-input-cus {
  visibility: hidden;
}
.personal-detail .form-area form .verify-btn {
  color: #FFF;
  display: inline-flex;
  padding: 7px 40px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.35);
  background: #0A65CC;
  text-decoration: none;
}
.personal-detail .form-area form select {
  display: flex;
  padding: 8px 40px;
  justify-content: center;
  align-items: center;
  color: rgba(102, 102, 102, 0.6);
}
.personal-detail .form-area form .cv-upload {
  border-radius: 12px;
  border: 1px dashed #0A65CC;
  padding: 15px;
}
.personal-detail .form-area form .cv-upload .resume h4 {
  color: #0A65CC;
  font-size: 22px;
  font-weight: 700;
  text-align: left;
}
.personal-detail .form-area form .cv-upload .resume p {
  margin: unset;
  color: rgba(102, 102, 102, 0.6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.personal-detail .form-area form .cv-upload .upload img {
  width: 30px;
  height: 30px;
}
.personal-detail .form-area form .cus-verify {
  position: relative;
}
.personal-detail .form-area form .cus-verify img {
  position: absolute;
  right: 25px;
  top: 40px;
}
.personal-detail .form-area form .cus-pass-section {
  position: relative;
}
.personal-detail .form-area form .cus-pass-section span {
  position: absolute;
  right: 25px;
  top: 43px;
}
.personal-detail .form-area form h6 span a {
  color: #0A65CC;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}
.personal-detail .form-area form .skip {
  padding: 10px 40px;
  border-radius: 40px;
  border: 1px solid #1B375C;
  background: #FFF;
  text-decoration: none;
  color: #1B375C;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.personal-detail .form-area form .next {
  padding: 10px 40px;
  border-radius: 40px;
  background: #1B375C;
  text-decoration: none;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #1B375C;
}
.personal-detail .popup-video-play .player-wrapper video {
  width: 100%;
  height: 100%;
}
.personal-detail .personal-page-modal-body .save-btn-cstm {
  border: 1px solid #023250;
  border-radius: 5px;
  background-color: #fff;
  transition: all 0.5s;
  color: #023250;
}
.personal-detail .personal-page-modal-body .save-btn-cstm:hover {
  background-color: #023250;
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.personal-detail .personal-page-modal-body .upload-with-icon {
  border: 1px solid #023250;
  border-radius: 5px;
  background-color: #fff;
  transition: all 0.5s;
  color: #023250;
}
.personal-detail .personal-page-modal-body .upload-with-icon:hover {
  background-color: #023250;
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.personal-detail .personal-page-modal-body .file-name-custom-dv span {
  font-size: 14px;
}

.educational-detail {
  padding: 50px 0;
}
.educational-detail .stepper-container {
  width: 100%;
  /* Adjust this value to the desired width */
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 20px;
}
.educational-detail .stepper-box {
  padding-left: 12px;
  padding-right: 12px;
  width: 16% !important;
}
.educational-detail .stepper-area {
  height: 100%;
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.educational-detail .cv-default-btn {
  border: none;
  background: #08385c;
  color: #fff;
  padding: 3px 8px;
  border-radius: 5px;
}
.educational-detail .education-details-t-body tr {
  border-bottom: 1px solid #ccc;
  align-items: center;
}
.educational-detail .education-details-t-body tr th {
  border-bottom: none;
}
.educational-detail .education-details-t-body tr td {
  border-bottom: none;
}
.educational-detail .education-details-t-body tr td.two-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.educational-detail .education-details-t-body tr td.two-btn button {
  padding: 10px;
}
.educational-detail .stepper-area {
  height: 100%;
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.educational-detail .form-area {
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.educational-detail .form-area .table-responsive .table-hover tbody tr td .defaultResumeBtn {
  padding: 5px 15px;
  border-radius: 40px;
  background: #1B375C;
  font-size: 16px;
  text-decoration: none;
  color: #FFF;
  text-align: center;
  font-weight: 500;
  border: 1px solid #1B375C;
  white-space: nowrap;
}
.educational-detail .form-area .table-responsive .table-hover tbody tr td .view_resume_btn {
  padding: 5px 15px;
  border-radius: 40px;
  border: 1px solid #1B375C;
  background: #FFF;
  text-decoration: none;
  color: #1B375C;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  max-width: 100%;
}
.educational-detail .form-area .profile-back-btn .back-btn a {
  background-color: unset;
  border: unset;
  color: #0A65CC;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}
.educational-detail .form-area .profile-edit-btn {
  display: inline-grid;
  align-content: space-between;
}
.educational-detail .form-area .profile-edit-btn .edit-btn {
  padding: 8px 45px;
  border-radius: 50px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  background-color: unset;
  text-decoration: none;
}
.educational-detail .form-area .cust-add-btn {
  padding: 8px 45px;
  border-radius: 10px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  color: #0A65CC;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  background-color: unset;
}
.educational-detail .form-area .cust-add-btn:hover {
  background-color: #0A65CC;
  color: #fff;
}
.educational-detail .form-area .edu-title h1 {
  color: #333;
  text-align: center;
  font-family: Barlow;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
}
.educational-detail .form-area .table-responsive thead th {
  padding: 15px;
}
.educational-detail .form-area .table-responsive tbody tr .universiity {
  width: 200px;
}
.educational-detail .form-area .skip {
  padding: 10px 40px;
  border-radius: 40px;
  border: 1px solid #1B375C;
  background: #FFF;
  text-decoration: none;
  color: #1B375C;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.educational-detail .form-area .next {
  padding: 10px 40px;
  border-radius: 40px;
  background: #1B375C;
  font-size: 16px;
  text-decoration: none;
  color: #FFF;
  text-align: center;
  font-weight: 500;
  border: 1px solid #1B375C;
}
.educational-detail .form-area form label {
  text-align: left;
}
.educational-detail .form-area form label span {
  color: red;
}
.educational-detail .form-area form .upload-docu-icn-cls .upload-docu-icon {
  position: relative;
}
.educational-detail .form-area form .upload-docu-icn-cls .upload-docu-icon input {
  padding: unset;
  border-radius: unset;
  border: unset;
  position: absolute;
  visibility: hidden;
}
.educational-detail .form-area form .upload-docu-icn-cls .upload-docu-icon img {
  position: absolute;
  top: 20px;
  right: 13px;
}
.educational-detail .form-area form .upload-docu-icn-cls .upload-docu-icon .upload-docu-label {
  padding: 15px 27px 17px 24px;
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.35);
  color: rgba(102, 102, 102, 0.3490196078);
  cursor: pointer;
}
.educational-detail .form-area form .upload-docu-icn-cls .upload-docu-icon .label-img {
  display: block;
}
.educational-detail .form-area form input {
  padding: 15px 27px 17px 24px;
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.35);
}
.educational-detail .form-area form input::-moz-placeholder {
  color: rgba(102, 102, 102, 0.6);
}
.educational-detail .form-area form input::placeholder {
  color: rgba(102, 102, 102, 0.6);
}
.educational-detail .form-area form select {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 27px 17px 24px;
  color: rgba(102, 102, 102, 0.6);
}

.professional-detail {
  padding: 50px 0;
}
.professional-detail .stepper-container {
  width: 100%;
  /* Adjust this value to the desired width */
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 20px;
}
.professional-detail .stepper-box {
  padding-left: 12px;
  padding-right: 12px;
  width: 16% !important;
}
.professional-detail .stepper-area {
  height: 100%;
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.professional-detail .form-area {
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.professional-detail .form-area .skill-used-col .form-control.form-select {
  padding: 0px;
}
.professional-detail .form-area .skill-used-col .form-control.form-select > *:nth-child(3) {
  padding: 9px 27px 9px 24px;
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.35);
}
.professional-detail .form-area .profile-back-btn .back-btn a {
  background-color: unset;
  border: unset;
  color: #0A65CC;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}
.professional-detail .form-area .profile-edit-btn {
  display: inline-grid;
  align-content: space-between;
}
.professional-detail .form-area .profile-edit-btn .edit-btn a {
  padding: 8px 45px;
  border-radius: 50px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  background-color: unset;
  text-decoration: none;
}
.professional-detail .form-area .cust-add-btn {
  padding: 8px 45px;
  border-radius: 10px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  color: #0A65CC;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  background-color: unset;
}
.professional-detail .form-area .cust-add-btn:hover {
  background-color: #0A65CC;
  color: #fff;
}
.professional-detail .form-area .edu-title h1 {
  color: #333;
  text-align: center;
  font-family: Barlow;
  font-size: 32px;
  font-weight: 700;
  width: 100%;
}
.professional-detail .form-area .position-relative {
  position: relative;
}
.professional-detail .form-area .end-0 {
  right: 0;
}
.professional-detail .form-area .skip {
  padding: 10px 40px;
  border-radius: 40px;
  border: 1px solid #1B375C;
  background: #FFF;
  text-decoration: none;
  color: #1B375C;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.professional-detail .form-area .next {
  padding: 10px 40px;
  border-radius: 40px;
  background: #1B375C;
  font-size: 16px;
  text-decoration: none;
  color: #FFF;
  text-align: center;
  font-weight: 500;
}
.professional-detail .form-area form label {
  text-align: left;
}
.professional-detail .form-area form label span {
  color: red;
}
.professional-detail .form-area form input {
  padding: 15px 27px 17px 24px;
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.35);
}
.professional-detail .form-area form input::-moz-placeholder {
  color: rgba(102, 102, 102, 0.6);
}
.professional-detail .form-area form input::placeholder {
  color: rgba(102, 102, 102, 0.6);
}
.professional-detail .form-area form select {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 27px 17px 24px;
  color: rgba(102, 102, 102, 0.6);
}

.project-detail {
  padding: 50px 0;
}
.project-detail .stepper-container {
  width: 100%;
  /* Adjust this value to the desired width */
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 20px;
}
.project-detail .stepper-box {
  padding-left: 12px;
  padding-right: 12px;
  width: 16% !important;
}
.project-detail .stepper-area {
  height: 100%;
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.project-detail .form-area {
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.project-detail .form-area .profile-back-btn .back-btn a {
  background-color: unset;
  border: unset;
  color: #0A65CC;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}
.project-detail .form-area .profile-edit-btn {
  display: inline-grid;
  align-content: space-between;
}
.project-detail .form-area .profile-edit-btn .edit-btn a {
  padding: 8px 45px;
  border-radius: 50px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  background-color: unset;
  text-decoration: none;
}
.project-detail .form-area .project-title h1 {
  color: #333;
  text-align: center;
  font-family: Barlow;
  font-size: 32px;
  font-weight: 700;
  width: 100%;
}
.project-detail .form-area .cust-add-btn {
  padding: 8px 45px;
  border-radius: 10px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  color: #0A65CC;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  background-color: unset;
}
.project-detail .form-area .cust-add-btn:hover {
  background-color: #0A65CC;
  color: #fff;
}
.project-detail .form-area .skip {
  padding: 10px 40px;
  border-radius: 40px;
  border: 1px solid #1B375C;
  background: #FFF;
  text-decoration: none;
  color: #1B375C;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.project-detail .form-area .next {
  padding: 10px 40px;
  border-radius: 40px;
  background: #1B375C;
  text-decoration: none;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #1B375C;
}
.project-detail .form-area form .skill-select-cus {
  padding: 0;
}
.project-detail .form-area form .skill-select-cus span + div {
  justify-content: center;
  align-items: center;
  padding: 8px 5px;
}
.project-detail .form-area form label {
  text-align: left;
}
.project-detail .form-area form label span {
  color: red;
}
.project-detail .form-area form input {
  padding: 15px 27px 17px 24px;
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.35);
}
.project-detail .form-area form input::-moz-placeholder {
  color: rgba(102, 102, 102, 0.6);
}
.project-detail .form-area form input::placeholder {
  color: rgba(102, 102, 102, 0.6);
}
.project-detail .form-area form select {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 27px 17px 24px;
}

.key-skills {
  padding: 50px 0;
}
.key-skills .stepper-container {
  width: 100%;
  /* Adjust this value to the desired width */
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 20px;
}
.key-skills .stepper-box {
  padding-left: 12px;
  padding-right: 12px;
  width: 16% !important;
}
.key-skills .stepper-area {
  height: 100%;
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.key-skills .form-area {
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.key-skills .form-area .profile-back-btn .back-btn a {
  background-color: unset;
  border: unset;
  color: #0A65CC;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}
.key-skills .form-area .profile-edit-btn {
  display: inline-grid;
  align-content: space-between;
}
.key-skills .form-area .profile-edit-btn .edit-btn a {
  padding: 8px 45px;
  border-radius: 50px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  background-color: unset;
  text-decoration: none;
}
.key-skills .form-area .project-title h1 {
  color: #333;
  text-align: center;
  font-family: Barlow;
  font-size: 32px;
  font-weight: 700;
  width: 100%;
}
.key-skills .form-area .cust-add-btn {
  padding: 8px 45px;
  border-radius: 10px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  color: #0A65CC;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  background-color: unset;
}
.key-skills .form-area .cust-add-btn:hover {
  background-color: #0A65CC;
  color: #fff;
}
.key-skills .form-area .skip {
  padding: 10px 40px;
  border-radius: 40px;
  border: 1px solid #1B375C;
  background: #FFF;
  text-decoration: none;
  color: #1B375C;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.key-skills .form-area .next {
  padding: 10px 40px;
  border-radius: 40px;
  background: #1B375C;
  border: 1px solid #1B375C;
  text-decoration: none;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.key-skills .form-area form label {
  text-align: left;
}
.key-skills .form-area form label span {
  color: red;
}
.key-skills .form-area form input {
  padding: 15px 27px 17px 24px;
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.35);
}
.key-skills .form-area form select {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 27px 17px 24px;
  color: rgba(102, 102, 102, 0.6);
}

.certification {
  padding: 50px 0;
}
.certification .stepper-container {
  width: 100%;
  /* Adjust this value to the desired width */
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 20px;
}
.certification .stepper-box {
  padding-left: 12px;
  padding-right: 12px;
  width: 16% !important;
}
.certification .stepper-area {
  height: 100%;
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.certification .form-area {
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.certification .form-area .profile-back-btn .back-btn a {
  background-color: unset;
  border: unset;
  color: #0A65CC;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}
.certification .form-area .profile-edit-btn {
  display: inline-grid;
  align-content: space-between;
}
.certification .form-area .profile-edit-btn .edit-btn a {
  padding: 8px 45px;
  border-radius: 50px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  background-color: unset;
  text-decoration: none;
}
.certification .form-area .edu-title h1 {
  color: #333;
  text-align: center;
  font-family: Barlow;
  font-size: 32px;
  font-weight: 700;
  width: 100%;
}
.certification .form-area .cust-add-btn {
  padding: 8px 45px;
  border-radius: 10px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  color: #0A65CC;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  background-color: unset;
}
.certification .form-area .cust-add-btn:hover {
  background-color: #0A65CC;
  color: #fff;
}
.certification .form-area .skip {
  padding: 10px 40px;
  border-radius: 40px;
  border: 1px solid #1B375C;
  background: #FFF;
  text-decoration: none;
  color: #1B375C;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.certification .form-area .next {
  padding: 10px 40px;
  border-radius: 40px;
  background: #1B375C;
  border: 1px solid #1B375C;
  text-decoration: none;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.certification .form-area form label {
  text-align: left;
}
.certification .form-area form label span {
  color: red;
}
.certification .form-area form input {
  padding: 15px 27px 17px 24px;
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.35);
}
.certification .form-area form input::-moz-placeholder {
  color: rgba(102, 102, 102, 0.6);
}
.certification .form-area form input::placeholder {
  color: rgba(102, 102, 102, 0.6);
}
.certification .form-area form select {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 27px 17px 24px;
}

.social-media {
  padding: 50px 0;
}
.social-media .stepper-container {
  width: 100%;
  /* Adjust this value to the desired width */
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 20px;
}
.social-media .stepper-box {
  padding-left: 12px;
  padding-right: 12px;
  width: 16% !important;
}
.social-media .stepper-area {
  height: 100%;
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.social-media .form-area {
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.social-media .form-area .profile-back-btn .back-btn a {
  background-color: unset;
  border: unset;
  color: #0A65CC;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}
.social-media .form-area .profile-edit-btn {
  display: inline-grid;
  align-content: space-between;
}
.social-media .form-area .profile-edit-btn .edit-btn {
  padding: 8px 45px;
  border-radius: 50px;
  border: 1px solid rgba(10, 101, 204, 0.5019607843);
  background-color: unset;
  text-decoration: none;
}
.social-media .form-area .profile-edit-btn .edit-btn:hover {
  color: white;
  background-color: #0A65CC;
}
.social-media .form-area .project-title h1 {
  color: #333;
  text-align: center;
  font-family: Barlow;
  font-size: 32px;
  font-weight: 700;
}
.social-media .form-area .cust-add-btn a {
  padding: 8px 10px;
  border-radius: 10px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  color: #0A65CC;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  background-color: unset;
}
.social-media .form-area .next {
  padding: 10px 40px;
  border-radius: 40px;
  background: #1B375C;
  font-size: 16px;
  text-decoration: none;
  color: #FFF;
  text-align: center;
  font-weight: 500;
}
.social-media .form-area .skip {
  padding: 10px 40px;
  border-radius: 40px;
  border: 1px solid #1B375C;
  background: #FFF;
  text-decoration: none;
  color: #1B375C;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.social-media .form-area form label {
  text-align: left;
}
.social-media .form-area form label span {
  color: red;
}
.social-media .form-area form input {
  padding: 15px 27px 17px 24px;
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.35);
}
.social-media .form-area form select {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 27px 17px 24px;
}
.social-media .form-area form .social-icons .social-lft {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid rgba(52, 67, 75, 0.15);
  padding: 2px;
}
.social-media .form-area form .social-icons p {
  color: #8D9CA5;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  margin: unset;
}
.social-media .form-area form .social-icons .check {
  width: 16px;
  height: 16px;
  margin: 0 20px;
  margin-left: auto;
}
.social-media .form-area form .social-icons img {
  width: 45px;
  height: 45px;
}/*# sourceMappingURL=cv.css.map */