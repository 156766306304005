.career-with-large-employement {
  /*=============================================== silder-start-css=============================== */
}
.career-with-large-employement .similer-post-heading {
  margin-bottom: 50px;
}
.career-with-large-employement .footer-list-card ul li {
  list-style: none;
  color: #565656;
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
}
.career-with-large-employement .footer-list-card ul li img {
  margin-right: 10px;
}
.career-with-large-employement .footer-list-card ul {
  padding: unset;
}
.career-with-large-employement .card-custom {
  border: none;
  padding: 20px !important;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.career-with-large-employement .card-first-custom {
  border: none;
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.career-with-large-employement .card-first-custom img.industry-logo {
  max-width: 65px;
  min-width: 65px;
  max-height: 65px;
  min-height: 65px;
}
.career-with-large-employement .card-second-custom {
  border: none;
  padding: 50px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.career-with-large-employement .card-second-custom .card-inner-item {
  text-align: center;
}
.career-with-large-employement .card-second-custom .card-inner-item img {
  text-align: center;
}
.career-with-large-employement .card-custom-2 {
  border: none;
  padding: 35px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.career-with-large-employement .card-custom-3 {
  border: none;
  padding: 30px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.career-with-large-employement .footer-heading h3 {
  color: #141414;
  font-size: 16px;
  font-weight: 500;
}
.career-with-large-employement .footer-heading span a {
  color: #2B85FF;
  text-decoration: none;
  font-size: 15px;
  margin-left: 15px;
  font-weight: 500;
  border-radius: 3px;
  background: rgba(43, 133, 255, 0.1);
}
.career-with-large-employement .footer-icon span a {
  color: #FE6108;
  text-decoration: none;
  font-size: 15px;
  margin-left: 15px;
  font-weight: 500;
  border-radius: 3px;
  background: rgba(254, 97, 8, 0.1);
}
.career-with-large-employement .footer-heading p {
  color: #565656;
  font-size: 15px;
  font-weight: 400;
}
.career-with-large-employement .second-section {
  border-radius: 4px;
  background: #FFF;
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.career-with-large-employement .second-section .cursor-pointer {
  cursor: pointer;
}
.career-with-large-employement .card-heading h3 {
  color: #141414;
  font-size: 26px;
  font-weight: 700;
}
.career-with-large-employement .card-second-heading h6 {
  color: #141414;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .career-with-large-employement .career-section .custom-form-section {
    display: flex !important;
    flex-direction: column;
  }
  .career-with-large-employement .search-custom .form-custom {
    height: 41px;
    width: 296px;
    margin-top: 6px;
  }
  .career-with-large-employement .career-section {
    padding: 0px;
  }
  .career-with-large-employement .form-custom-btn {
    width: 89px;
    height: 41px;
    font-size: 15px;
    margin-top: 11px;
  }
  .career-with-large-employement .career-section .career-top-heading h2 {
    font-size: 28px;
  }
  .career-with-large-employement .career-section .career-top-heading span {
    font-size: 28px;
  }
}
.career-with-large-employement {
  margin: 10px 0px 30px 0px;
  /* silder css start */
}
.career-with-large-employement .second-section .slick-slider .slick-next:before,
.career-with-large-employement .second-section .slick-slider .slick-prev:before {
  font-size: 26px;
  line-height: 1;
  opacity: 0.75;
  color: rgb(27, 55, 92);
}
.career-with-large-employement .second-section .slick-slider .slick-next,
.career-with-large-employement .second-section .slick-slider .slick-prev {
  font-size: 0;
  line-height: 0;
  top: -20%;
  width: 20px;
  height: 20px;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
}
.career-with-large-employement .second-section .slick-slider .slick-next {
  right: 40px !important;
}
.career-with-large-employement .second-section .slick-slider .slick-prev {
  right: 90px !important;
  left: unset;
}
.career-with-large-employement .second-section .slick-slider .slick-next:before {
  position: absolute;
  content: "\f054";
  color: #ffffff;
  font-size: 12px;
  font-family: fontawesome;
  background: rgb(27, 55, 92);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 24px;
  border: 1px solid rgb(27, 55, 92);
}
.career-with-large-employement .second-section .slick-slider .slick-prev:before {
  position: absolute;
  content: "\f053";
  color: #ffffff;
  font-size: 12px;
  font-family: fontawesome;
  background: rgb(27, 55, 92);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 24px;
  border: 1px solid rgb(27, 55, 92);
}
.career-with-large-employement .second-section {
  border-radius: 4px;
  background: #FFF;
  margin-top: 30px;
  padding: 30px 15px 15px 15px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.career-with-large-employement .second-section .similer-post-heading h2 {
  font-size: 32px;
  font-weight: 700;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.career-with-large-employement .card {
  padding: 20px !important;
  margin: 0px 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 180px;
}
.career-with-large-employement .card .card-sub-dv {
  display: flex;
}
.career-with-large-employement .card .footer-heading {
  text-align: left;
}
.career-with-large-employement .card .footer-heading h3 {
  color: #141414;
  font-size: 20px;
  font-weight: 500;
}
.career-with-large-employement .card .footer-heading p {
  color: #565656;
  font-size: 14px;
  font-weight: 400;
}
.career-with-large-employement .card .footer-heading p span a {
  color: #2B85FF;
  text-decoration: none;
  font-size: 12px;
  margin-left: 25px;
  font-weight: 500;
  border-radius: 3px;
  background: rgba(43, 133, 255, 0.1);
  padding: 3px 6px;
}
.career-with-large-employement .card .footer-list-card {
  text-align: left;
}
.career-with-large-employement .card .footer-list-card ul {
  padding: unset;
}
.career-with-large-employement .card .footer-list-card ul li {
  list-style: none;
  color: #565656;
  font-size: 16px;
  font-weight: 400;
  margin-top: 5px;
}
.career-with-large-employement .card .footer-list-card ul li img {
  margin-right: 10px;
  display: inline-block;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .career-with-large-employement .card {
    margin: 0px 2px;
    padding: 20px !important;
  }
  .career-with-large-employement .card .footer-heading p {
    color: #565656;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .career-with-large-employement .card .footer-heading p span a {
    margin-left: 0;
  }
  .career-with-large-employement .card .card-sub-dv {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 767px) and (max-width: 992px) {
  .career-with-large-employement .card {
    margin: 0px 2px;
    padding: 20px !important;
  }
  .career-with-large-employement .card .footer-heading p {
    color: #565656;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .career-with-large-employement .card .footer-heading p span a {
    margin-left: 0;
  }
  .career-with-large-employement .card .card-sub-dv {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
}/*# sourceMappingURL=CareerswithLargeEmployment.css.map */