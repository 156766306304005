// ===================Matchinhh Hiring=================
.rec-post-mt-hi-banner {
    .banner-area {
        background: url("../../../../public/assets/images/matching-hiring/h-banner.png");
        background-size: cover;
        background-position: 100%;
        background-repeat: no-repeat;
        height: 500px;
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        .banner-sec-lft {
            
            &:after {
                position: absolute;
                content: '';
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                background: #1b375c;
                opacity: 0.9;
                z-index: -1;
            }

            &::before {
                position: absolute;
                content: '';
                height: 100%;
                width: 70%;
                top: 0;
                left: 0;
                background: #1B375C;
                opacity: 0.9;
                clip-path: polygon(0 0, 100% 0, 64% 100%, 0% 100%);
                z-index: 0;
            }
            .banner-sec-lft-cus{
                position: relative;
                z-index: 2;
            }
            .banner-text {
                // position: absolute;
                // top: 50%;
                // left: 50%;
                // transform: translate(-50%, -50%);
                // width: 600px;

                h1 {
                    text-align: left;
                    color: #FFF;
                    // font-family: Barlow;
                    font-size: 50px;
                    font-weight: 600;
                    // margin-bottom: 30px;
                }

                p {
                    text-align: left;
                    color: #FFF;
                    // font-family: Barlow;
                    font-size: 16px;
                    line-height: normal;
                    margin-bottom: 30px;
                }

                a {
                    border-radius: 50px;
                    background: #0A65CC;
                    color: #FFF;
                    border: unset;
                    text-decoration: none;
                    padding: 10px 40px;
                    font-size: 16px;
                    font-weight: 400;

                }

            }
        }
    }

}

@media only screen and (min-width:993px) and (max-width:1200px) {
    .rec-post-mt-hi-banner {
        .banner-area {
            .banner-sec-lft{
                &::before {
                    position: absolute;
                    content: "";
                    height: 100%;
                    width: 70%;
                    top: 0;
                    left: 0;
                    background: #1B375C;
                    opacity: 0.9;
                    clip-path: polygon(0 0, 100% 0, 64% 100%, 0% 100%);
                }
            }
            height:300px;

            .banner-text {
                left: auto !important;
                transform: none !important;
                width: auto !important;
                top: 22% !important;

                h1 {
                    font-size: 36px !important;
                }

                p {
                    font-size: 15px !important;
                    margin-bottom: 15px !important;
                }

                a {
                    padding: 7px 25px !important;
                    font-size: 16px !important;
                }
            }
        }
    }
}

@media only screen and (min-width:768px) and (max-width:992px) {
    .rec-post-mt-hi-banner {
        .banner-area {
            height: 240px;

            .banner-text {
                left: auto !important;
                transform: none !important;
                width: auto !important;
                top: 22% !important;

                h1 {
                    font-size: 20px !important;
                }

                p {
                    font-size: 15px !important;
                    margin-bottom: 15px !important;
                }

                a {
                    padding: 7px 25px !important;
                    font-size: 16px !important;
                }
            }

            .banner-sec-lft {
                // &::before {
                //     clip-path: unset;
                //     width: 55%;
                // }
                &:after {
                    position: absolute;
                    content: '';
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    background: #1b375c;
                    opacity: 0.9;
                    z-index: -1;
                }
    
                &::before {
                    position: absolute;
                    content: '';
                    height: 100%;
                    width: 70%;
                    top: 0;
                    left: 0;
                    background: #1B375C;
                    opacity: 0.9;
                    clip-path: polygon(0 0, 100% 0, 64% 100%, 0% 100%);
                    z-index: 0;
                }
            }

            .banner-sec-lft+.col-lg-6 {
                display: none;
            }
        }
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .rec-post-mt-hi-banner {
        .banner-area {
            height: 240px;

            .banner-text {
                left: auto !important;
                transform: none !important;
                width: auto !important;
                top: 22% !important;

                h1 {
                    font-size: 20px !important;
                }

                p {
                    font-size: 15px !important;
                    margin-bottom: 15px !important;
                }

                a {
                    padding: 7px 25px !important;
                    font-size: 16px !important;
                }
            }

            .banner-sec-lft {
                &::before {
                    clip-path: unset;
                    width: 100%;
                }
            }

            .banner-sec-lft+.col-lg-6 {
                display: none;
            }
        }
    }
}