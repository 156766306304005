.news-table .profile-description {
  max-height: 100px;
  overflow-y: auto;
  overflow: hidden;
}
.news-table .profile-description::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}
.news-table .profile-description::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}
.news-table .profile-description::-webkit-scrollbar-thumb {
  background-color: #1B375C;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
}
.news-table .capitalize {
  text-transform: capitalize;
}
.news-table img {
  max-width: 150px;
  max-height: 150px;
  -o-object-fit: contain;
     object-fit: contain;
}
.news-table video {
  max-width: 150px;
  max-height: 150px;
  -o-object-fit: contain;
     object-fit: contain;
}
.news-table .delete-button {
  border: 1px solid #dc3545;
  background: none;
  border-radius: 0.5rem;
  background-color: white;
  padding: 3px 8px;
  font-size: 16px;
  font-weight: 600;
  color: #dc3545;
}
.news-table .w-fit {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.center-text {
  text-align: center;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}/*# sourceMappingURL=ProfileListing.css.map */