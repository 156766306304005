.career-test-listing {
    .container {
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }


    .title {
        h1 {
            color: #141414;
            font-family: Barlow;
            font-size: 32px;
            font-weight: 700;
        }

        a {
            text-decoration: none;
            padding: 8px 50px;
            border-radius: 8px;
            border: 1px solid rgba(27, 55, 92, 0.64);
            background: var(--light-background-transparent, rgba(255, 255, 255, 0.00));
            color: #1B375C;
            font-size: 18px;
            font-weight: 600;
        }
    }

    .custm-info-box {
        background: #F6F6F6;
        border-radius: 10px;
        img{
            border-radius: 5px;
        }
        .textarea {
            padding: 20px 20px;

            h3 {
                text-align: left;
                color: var(--Main, #073E87);
                font-size: 25px;
                font-weight: 600;
                margin: 15px 0;
            }

            p {
                text-align: left;
                color: var(--Sub-ink, #747D8C);
                font-size: 15px;
                font-weight: 400;
            }

            ul {
                padding: unset;

                li {
                    list-style: none;

                    img {
                        padding: 0 10px 4px 0;
                        object-fit: none;
                    }

                }
            }
        }
    }
}

@media only screen and (min-width:200px) and (max-width:567px) {

    .career-test-listing {
        .title {
            display: block !important;

            h1 {
                font-size: 25px;
            }

            a {
                font-size: 14px;
                padding: 8px 22px;
            }
        }

        .custm-info-box {
            .textarea {
                h3 {
                    font-size: 18px;
                }

                ul {
                    display: block !important;
                }
            }
        }
    }

}