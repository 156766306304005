.make-link-style {
  background-color: unset;
  border: unset;
  color: #1b375c;
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.share-btn-blogs .st-center .st-btn {
  display: inline-block !important;
}

.blog-desc-para p {
  text-align: justify;
}/*# sourceMappingURL=BlogDetail.css.map */