.rec-chat-section {
    .main-subs-chat {

        .cus-head {
            width: 100%;
            height: 65px;
            flex-shrink: 0;
            border-radius: 4px;
            background: #F4FAFF;
            box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), 0px 4px 20px 0px rgba(29, 95, 181, 0.20) inset;

            h5 {
                color: #1B375C;
                font-family: Barlow;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 22px;
                text-transform: capitalize;
                padding: 20px 12px 12px 12px;
            }
        }

        .subscrip-card {
            border-radius: 4px;
            padding: 20px 20px 30px 20px;
            background: #FFF;
            box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;

            .my-cus-chat {
                height: 535px;
                overflow-y: scroll;

                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    background-color: #F5F5F5;
                }

                &::-webkit-scrollbar {
                    width: 8px;
                    background-color: #F5F5F5;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #1b375c !important;
                    background-image: -webkit-linear-gradient(45deg,
                            rgba(255, 255, 255, .2) 25%,
                            transparent 25%,
                            transparent 50%,
                            rgba(255, 255, 255, .2) 50%,
                            rgba(255, 255, 255, .2) 75%,
                            transparent 75%,
                            transparent)
                }
            }

            .user-listing-left {
                height: 650px;
                overflow-y: scroll;

                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    background-color: #F5F5F5;
                }

                &::-webkit-scrollbar {
                    width: 5px;
                    background-color: #F5F5F5;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #1b375c !important;
                    background-image: -webkit-linear-gradient(45deg,
                            rgba(255, 255, 255, .2) 25%,
                            transparent 25%,
                            transparent 50%,
                            rgba(255, 255, 255, .2) 50%,
                            rgba(255, 255, 255, .2) 75%,
                            transparent 75%,
                            transparent)
                }
            }

            .cus-input {
                position: relative;

                .cus-ctrl {
                    border-radius: 10px;
                    border: 1px solid #e1e1e5;
                    padding: 15px 10px;
                }

                img {
                    position: absolute;
                    cursor: pointer;
                    top: 50%;
                    right: 0%;
                    transform: translate(-50%, -50%);
                }
            }

            .cus-list {
                a {
                    text-decoration: none;
                    color: #141522;
                    // font-family: Plus Jakarta Sans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    letter-spacing: -0.28px;
                }

                li:first-child {
                    border-radius: 10px;
                    background: #f1f1f1;

                    .cus-class-p {
                        color: #8E92BC;
                        /* text-align: right; */
                        /* font-family: Plus Jakarta Sans; */
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        /* line-height: normal; */
                        letter-spacing: -0.12px;
                        --bs-text-opacity: 1;
                    }

                    .cus-msg {
                        color: #141522;

                        /* Regular/Type@12 */
                        // font-family: Plus Jakarta Sans;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.12px;
                        margin-bottom: 0;
                    }
                }

                li:nth-child(3) {

                    .cus-class-p {
                        color: #8E92BC;
                        /* text-align: right; */
                        /* font-family: Plus Jakarta Sans; */
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        /* line-height: normal; */
                        letter-spacing: -0.12px;
                        --bs-text-opacity: 1;
                    }

                    .cus-msg {
                        color: #141522;

                        /* Regular/Type@12 */
                        // font-family: Plus Jakarta Sans;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.12px;
                        margin-bottom: 0;
                    }
                }

                li:nth-child(5) {

                    .cus-class-p {
                        color: #8E92BC;
                        /* text-align: right; */
                        /* font-family: Plus Jakarta Sans; */
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        /* line-height: normal; */
                        letter-spacing: -0.12px;
                        --bs-text-opacity: 1;
                    }

                    .cus-msg {
                        color: #141522;

                        /* Regular/Type@12 */
                        // font-family: Plus Jakarta Sans;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.12px;
                        margin-bottom: 0;
                    }
                }

                li:nth-child(7) {

                    .cus-class-p {
                        color: #8E92BC;
                        /* text-align: right; */
                        /* font-family: Plus Jakarta Sans; */
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        /* line-height: normal; */
                        letter-spacing: -0.12px;
                        --bs-text-opacity: 1;
                    }

                    .cus-msg {
                        color: #141522;

                        /* Regular/Type@12 */
                        // font-family: Plus Jakarta Sans;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.12px;
                        margin-bottom: 0;
                    }
                }


                .cus-class-p {
                    color: #8E92BC;
                    /* text-align: right; */
                    /* font-family: Plus Jakarta Sans; */
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    /* line-height: normal; */
                    letter-spacing: -0.12px;
                    --bs-text-opacity: 1;

                }

                .inner-else-li {
                    color: #8E92BC;
                    /* font-family: Plus Jakarta Sans; */
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    /* line-height: normal; */
                    letter-spacing: -0.12px;
                    margin-bottom: 0px;
                }




            }

            .card.cus-card {
                border: unset;
                margin-top: 20px;
            }

            .card-body.cus-c-b {
                padding: unset;
            }

            .cus-class-sec-2 {
                a {
                    text-decoration: none;
                    color: #141522;
                    // font-family: Plus Jakarta Sans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    letter-spacing: -0.28px;
                }

                span {
                    color: #141522;

                    /* Medium/Type@12 */
                    // font-family: Plus Jakarta Sans;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    // line-height: normal;
                    letter-spacing: -0.24px;
                }

                .cus-img {
                    img {
                        border-radius: 100px;
                        border: 1px solid #F5F5F7;
                    }


                }
            }

            .my-cus-chat {
                .cus-small {
                    color: #8E92BC;
                    text-align: right;
                    // font-family: Plus Jakarta Sans;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.12px;
                }

                .bg-primaryy {

                    border-radius: 10px 0px 10px 10px;
                    background: #0090FF;
                    box-shadow: 0px 1px 3px 0px rgba(84, 111, 255, 0.10);
                }

                .cus-p {
                    border-radius: 0px 10px 10px 10px;
                    background: #FFF;
                    box-shadow: 0px 1px 3px 0px rgba(84, 111, 255, 0.10);
                    color: #141522;

                    /* Medium/Type@14 */
                    // font-family: Plus Jakarta Sans;
                    font-size: 14px;
                    // font-style: normal;
                    // font-weight: 500;
                    line-height: 150%;
                    /* 21px */
                    letter-spacing: -0.28px;
                }
            }

            .cus-hr {
                background: #F5F5F7;
                height: 1px;
            }

            .cus-inpi {
                color: #8E92BC;

                /* Medium/Type@14 */
                // font-family: Plus Jakarta Sans;
                font-size: 14px;
                /* 21px */
                letter-spacing: -0.28px;
            }

            .cus-g {
                position: relative;

                .cus-img {
                    position: absolute;
                    top: 26px;
                    right: 87px;
                }
            }
        }
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .rec-chat-section {
        .dv-parent-flex {
            justify-content: center;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: flex-start;
            align-items: center;
        }
    }
}