.blog-category .search-box {
  margin-bottom: 30px;
}
.blog-category .search-box h4 {
  text-align: left;
  color: #1B375C;
  font-family: Barlow;
  font-size: 22px;
  font-weight: 600;
}
.blog-category .search-box .custm-search {
  position: relative;
}
.blog-category .search-box .custm-search img {
  position: absolute;
  right: 12px;
  top: 12px;
}
.blog-category .category-box {
  margin-bottom: 40px;
}
.blog-category .category-box h4 {
  text-align: left;
  color: #1B375C;
  font-family: Barlow;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}
.blog-category .category-box h5 {
  text-align: left;
  border-radius: 3px;
  background: #1B375C;
  color: #FFF;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 15px;
  margin-bottom: 20px;
}
.blog-category .category-box ul {
  padding: unset;
  display: flex;
  justify-content: flex-start;
}
.blog-category .category-box ul li {
  width: 122px;
  list-style: none;
}
.blog-category .recent-post {
  padding: 0 5px;
}
.blog-category .recent-post h3 {
  text-align: left;
  color: #1B375C;
  font-family: Barlow;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 30px;
}
.blog-category .recent-post h4 {
  text-align: left;
  color: #1B375C;
  font-family: Barlow;
  font-size: 22px;
  font-weight: 600;
}
.blog-category .recent-post .custm-posts .post-detail .post-text h4 {
  text-align: left;
  color: var(--sale-tage-coloe, #3F509E);
  font-size: 14px;
  font-weight: 400;
}
.blog-category .recent-post .custm-posts .post-detail .post-text h5 {
  text-align: left;
  color: var(--sub-text-color, #8A8FB9);
  font-size: 11px;
  font-weight: 600;
}
.blog-category .social h4 {
  text-align: left;
  color: #1B375C;
  font-family: Barlow;
  font-size: 22px;
  font-weight: 600;
}
.blog-category .social .social-icon img {
  margin-right: 10px;
}/*# sourceMappingURL=blog-category.css.map */