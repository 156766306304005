.jobs-section {
    .apply-job-section {
        .img {
            width: 75px;
            height: 75px;

            img {
                width: auto;
                object-fit: contain;

            }
        }

        h3 {
            font-size: 18px;
            font-weight: 600;
            color: #2d2d2d;
        }
    }

    .jobs {
        h4 {
            font-size: 18px;
            font-weight: 600;
            color: #595959;

        }
    }

    .progress-bar {
        background-color: #9d2b6b;
    }

    .progress {
        height: 8px;

    }

    .border-bottom {
        border-bottom: 1px solid #e4e2e0;
    }

    span {
        font-size: 13px;
    }

    .border-dotted {
        border-bottom: 1px dashed #949494;
    }

    .share-btn {
        padding: 8px 18px;
        border-radius: 0.5rem;
        font-size: 16px;
        font-weight: 500;
        background: none;
        border: none;

        svg {
            fill: #767676 !important;
            width: 20px !important;
            height: 20px !important;

        }

    }

    .share-btn:hover,
    .rating-btn:hover {
        color: rgb(22, 64, 129);
        background-color: rgb(238, 241, 254);
    }

    .rating-btn {
        padding: 5px 18px;
        border-radius: 0.5rem;
        font-size: 16px;
        font-weight: 500;
        background: #f3f2f1;
        border: none;

    }

    .feedback {
        p {
            font-size: 14px;
            font-weight: 400;
            color: #595959;
            line-height: 24px;
        }

        svg {
            width: 13px;
            height: 15px;
            fill: #9d2b6b;
        }
    }

    .useful-review {
        background: #eef1fe;
        border-radius: 8px;
        padding: 12px 15px;

        span {
            color: #164081;

        }

        h2 {
            color: #164081;
            font-weight: 600;
            font-size: 17px;
        }
    }


    .review-box-container {
        flex-wrap: wrap;

        .review-box {
            padding: 6px 14px;
            border-radius: 30px;
            font-size: 16px;
            font-weight: 500;
            background: #f3f2f1;
        }
    }

    h3 {
        color: #2d2d2d;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.25;
    }

    h5,
    h4 {

        font-weight: 400;
        color: #595959;
    }

    h5 {
        font-size: 18px;
    }

    h4 {

        font-size: 16px;
    }



    .form-section,
    .form-section-2 {



        .sort-rating {
            border-left: 1px solid #b6b6b6;
            border-radius: 6px 0 0 6px;
            border-right: 1px solid #b6b6b6;
        }

        .date {
            border-radius: 0px 6px 6px 0px;
            border-right: 1px solid #b6b6b6;
        }

        .sort-rating,
        .date {
            padding: 6px 14px;
            border-top: 1px solid #b6b6b6;
            border-bottom: 1px solid #b6b6b6;
            background-color: #fff;
            font-size: 15px;
            font-weight: 400;
            color: #000;
        }

        label {
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.5;
            margin-bottom: 6px;
        }

        .rating {
            display: flex;
            flex-wrap: wrap;
            gap: 11px;

            .box {
                border: 1px solid #b6b6b6;
                padding: 6px 14px;
                background: #fff;
                border-radius: 50px;
            }

            span {
                font-size: 14px;
                font-weight: 400;
                line-height: 1.5;
            }

            svg {
                width: 20px;

                fill: #9d2b6b;

            }
        }
    }
}

.form-control:focus {
    border-color: #2557a7;
    box-shadow: inset 0 -3px 0 0 #2557a7;
}

.claimed-profile {
    h4 {
        color: #595959;
        font-weight: 600;
        font-size: 16px;
    }

    svg {
        fill: #1f662c;
        width: 25px;
    }
}

.update-button {
    border: 1px solid #4771c0;
    background: none;
    border-radius: 0.5rem;
    background-color: white;
    padding: 6px 14px;
    font-size: 16px;
    font-weight: 600;
    color: #4771c0;

}

.update-button:hover {
    background-color: #3b7ce312;
}

.accordion {
    h4 {
        font-size: 16px !important;
        font-weight: 600;
        line-height: 1.5;
        color: #2d2d2d;
    }
}

.rate-recent-company {
    .rate-star {
        background: #eef1fe;
        border-radius: 6px;
        padding: 6px 15px;
        width: fit-content;
    }

    background: #eef1fe;
    border-radius: 8px;
    padding: 30px 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        font-size: 14px;
    }

    h4 {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.5rem;
        color: #2d2d2d;
    }

    svg {
        width: 25px !important;
        height: 25px !important;
    }
}

.rounded-box-container {
    border-radius: .5rem;

    border: 1px solid #d4d2d0;

    .box-rounded-div {
        border-bottom: 1px solid #d4d2d0;
        padding: 15px 28px;

        .job-dropdown-btn {
            background: none;
            border: none;
            border-radius: 6px;
            padding: 10px;

        }

        .job-dropdown-btn:hover {
            background: #eef1fe;
        }

        .job-update-btn {
            background-color: #f3f2f1;
            font-size: 15px;
            font-weight: 600;
            padding: 4px 8px;
            border-radius: 5px;
            width: fit-content;
        }

        h4 {
            font-size: 20px !important;
            font-weight: 600;
            // line-height: 1.5;
            color: #2d2d2d;

        }

        p {
            font-size: 14px !important;
            font-weight: 400;
            line-height: 1.5;
        }
    }
}

.box-rounded {
    border-radius: .5rem;
    padding: 15px 12px;
    border: 1px solid #d4d2d0;

    .job-dropdown-btn {
        background: none;
        border: none;
        border-radius: 6px;
        padding: 10px;

    }

    .job-dropdown-btn:hover {
        background: #eef1fe;
    }

    .job-update-btn {
        background-color: #f3f2f1;
        font-size: 15px;
        font-weight: 600;
        padding: 4px 8px;
        border-radius: 5px;
        width: fit-content;
    }

    h4 {
        font-size: 20px !important;
        font-weight: 600;
        // line-height: 1.5;
        color: #2d2d2d;

    }

    p {
        font-size: 14px !important;
        font-weight: 400;
        line-height: 1.5;
    }
}


.card-title,
.card-subtitle {
    font-size: .75rem !important;
    color: #495057;
}

.nested-list {
    margin-left: 1.5rem;
    font-weight: 400;

    /* Indentation for nested list */
}

.list-unstyled li {
    font-size: .75rem !important;
    line-height: 1.5;
}

.list-style {
    li {
        list-style: disc !important;
    }
}

.role-box {
    max-height: 427px;
    overflow-y: auto;
    padding: 12px 28px;
    ;
}

.job-status-div {
    max-height: 585px;
    overflow-y: auto;
}

.relative {
    svg {
        position: absolute;
        top: 8px;
        right: 10px;
        fill: #767676;
    }

    position: relative;
}
@media (max-width:992px){
 .find_jobs_btn {
       
        font-size: 14px !important;
        
        padding: 9px 12px !important;
    }
    .education-level {
        white-space: normal !important; /* Prevents text from wrapping to the next line */
        padding-left: 2px;
        
    }
}
@media (max-width:767px){
    .find_jobs_btn {
          margin-top: 15px;
       }
   }
   .education-level {
    white-space: nowrap; /* Prevents text from wrapping to the next line */
   
}
.customrgp{
    row-gap: 10px !important;
}