@charset "UTF-8";
.review-rate-modal-head-cls .modal-content {
  border-radius: 20px;
}
.review-rate-modal-head-cls .modal-content .modal-review .rating_class {
  position: relative;
}
.review-rate-modal-head-cls .modal-content .modal-review .rating_class .rating-news-p {
  color: #ccc;
  margin-bottom: 0px;
}
.review-rate-modal-head-cls .modal-content .modal-review .rating_class .rating-news-p b {
  color: #6e6e6e;
}
.review-rate-modal-head-cls .modal-content .modal-review .rating_class .rate {
  padding: 0 10px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.review-rate-modal-head-cls .modal-content .modal-review .rating_class .rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.review-rate-modal-head-cls .modal-content .modal-review .rating_class .rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}
.review-rate-modal-head-cls .modal-content .modal-review .rating_class .rate:not(:checked) > label:before {
  content: "★ ";
}
.review-rate-modal-head-cls .modal-content .modal-review .rating_class .rate > input:checked ~ label {
  color: #ffc700;
}
.review-rate-modal-head-cls .modal-content .modal-review .rating_class .rate:not(:checked) > label:hover,
.review-rate-modal-head-cls .modal-content .modal-review .rating_class .rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.review-rate-modal-head-cls .modal-content .modal-review .rating_class .rate > input:checked + label:hover,
.review-rate-modal-head-cls .modal-content .modal-review .rating_class .rate > input:checked + label:hover ~ label,
.review-rate-modal-head-cls .modal-content .modal-review .rating_class .rate > input:checked ~ label:hover,
.review-rate-modal-head-cls .modal-content .modal-review .rating_class .rate > input:checked ~ label:hover ~ label,
.review-rate-modal-head-cls .modal-content .modal-review .rating_class .rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}
.review-rate-modal-head-cls .modal-content .modal-review .rating_textarea .textarea-tag {
  width: 100%;
  border-radius: 2px;
  padding: 5px 10px;
  border-color: #ccc;
}
.review-rate-modal-head-cls .modal-content .modal-header {
  border-radius: 20px;
  padding: 10px 10px;
}
.review-rate-modal-head-cls .modal-content .modal-footer {
  border-top: 0px;
  justify-content: space-between;
}
.review-rate-modal-head-cls .modal-content .modal-footer .later-btn-color {
  background-color: #ccc;
  border-color: #ccc;
  color: #000;
}
.review-rate-modal-head-cls .modal-content .modal-footer .submit-btn-color {
  background-color: #1B375C;
  border-color: #1B375C;
  border-radius: 40px;
}/*# sourceMappingURL=RateAndReview.css.map */