.Questions-section {
    .modal-body {
        p {
            font-size: 14px !important;
            font-weight: 400;
            color: #595959 !important;
        }
    }

    .modal-title {
        font-size: 20px;
        color: rgb(45, 45, 45);
        font-weight: 700;
    }

    .reset-filters {
        font-size: 15px;
        color: #1b375c;
        cursor: pointer;
    }

    .help-full-ans {
        h3 {
            font-size: 18px;
            font-weight: 700;
            color: #2d2d2d;
            line-height: 1.5;
        }

        li {
            p {
                font-size: .875rem !important;
                font-weight: inherit !important;
                color: #2d2d2d !important;
                line-height: 24px !important;
            }
        }
    }

    .ans {
        background-color: #fceef4;
        border-radius: 4px;
        padding: .25rem .5rem;

        p {
            color: #9d2b6b !important;
            font-size: .75rem !important;
            font-weight: 700 !important;
            line-height: 18px !important;
        }
    }

    .font-semibold {
        font-weight: 600;
    }

    .nav-tabs {
        display: flex;
        border-bottom: 1px solid #ddd;
        font-size: 14px;
    }



    .nav-link {
        display: inline-flex;
        align-items: center;
        padding: 0.5rem 1rem;
        cursor: pointer;
        text-decoration: none;
        color: #000;
        border: 1px solid transparent;
        border-radius: 0.25rem 0.25rem 0 0;
        background-color: #f8f9fa;
    }

    .nav-link img {
        margin-right: 0.5rem;
        height: 16px;
    }

    .nav-link.active {
        color: #000;
        background-color: #fff;
        border-color: #ddd;
        border-bottom: 2px solid #007bff;
        font-weight: bold;
    }

    .tab-content {

        border-top: none;
    }

    .tab-pane {
        display: none;
    }

    .tab-pane.active {
        display: block;
    }

    .box-rounded svg {
        fill: #9d2b6b;
    }

    .progress-bar {
        background-color: #9d2b6b;
    }

    .progress {
        height: 8px;

    }

    .border-bottom {
        border-bottom: 1px solid #e4e2e0;
    }



    .border-dotted {
        border-bottom: 1px dashed #949494;
    }

    .share-btn {
        padding: 8px 18px;
        border-radius: 0.5rem;
        font-size: 16px;
        font-weight: 500;
        background: none;
        border: none;

        svg {
            fill: #767676 !important;
            width: 20px !important;
            height: 20px !important;

        }

    }

    .share-btn:hover,
    .rating-btn:hover {
        color: rgb(22, 64, 129);
        background-color: rgb(238, 241, 254);
    }

    .rating-btn {
        padding: 5px 18px;
        border-radius: 0.5rem;
        font-size: 16px;
        font-weight: 500;
        background: #f3f2f1;
        border: none;

    }

    .feedback {
        span {
            font-size: .75rem;
            color: #767676 !important;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: #595959;
            line-height: 24px;
        }


    }

    .list-style-none {
        list-style: none;
    }

    .useful-review {
        background: #eef1fe;
        border-radius: 8px;
        padding: 12px 15px;

        span {
            color: #164081;

        }

        h2 {
            color: #164081;
            font-weight: 600;
            font-size: 17px;
        }
    }

    .review-box-container {
        flex-wrap: wrap;

        .review-box {

            padding: 6px 14px;
            border-radius: 30px;
            font-size: 16px;
            font-weight: 500;
            background: #faf9f8;
            border: 1px solid #949494;
        }



        .review-box:hover {
            background-color: #eef1fe;
            border-color: #6792f0;

        }

        .active-question-type {
            background: #1b375c;
            color: #fff;

            &:hover {
                background-color: #4f525f;
                border-color: #000;
            }
        }
    }

    h3 {
        color: #2d2d2d;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.25;
    }

    h5 {

        font-weight: 400;
        color: #595959;
    }

    h5 {
        font-size: 18px;
    }

    h4 {

        font-size: 14px;
        font-weight: 600;
        color: #2d2d2d;

    }

    .rounded-t {

        border-radius: .5rem .5rem 0px 0px;
    }

    .rounded-b {
        border-radius: 0px 0px .5rem .5rem;
    }

    .form-section,
    .form-section-2 {
        padding: 1.5rem 1rem 1.5rem;
        background-color: #f3f2f1;

        .sort-rating {
            border-left: 1px solid #b6b6b6;
            border-radius: 6px 0 0 6px;
            border-right: 1px solid #b6b6b6;
        }

        .date {
            border-radius: 0px 6px 6px 0px;
            border-right: 1px solid #b6b6b6;
        }

        .sort-rating,
        .date {
            padding: 6px 14px;
            border-top: 1px solid #b6b6b6;
            border-bottom: 1px solid #b6b6b6;
            background-color: #fff;
            font-size: 15px;
            font-weight: 400;
            color: #000;
        }

        label {
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.5;
            margin-bottom: 6px;
        }

        .rating {
            display: flex;
            flex-wrap: wrap;
            gap: 11px;

            .box {
                border: 1px solid #b6b6b6;
                padding: 6px 14px;
                background: #fff;
                border-radius: 50px;
            }

            span {
                font-size: 14px;
                font-weight: 400;
                line-height: 1.5;
            }

            svg {
                width: 20px;

                fill: #9d2b6b;

            }
        }
    }
}

// .w-fit{
//     width: 100% ;
// }
.claimed-profile {
    h4 {
        color: #595959;
        font-weight: 600;
        font-size: 16px;
    }

    svg {
        fill: #1f662c;
        width: 25px;
    }
}

.update-button {
    border: 1px solid #4771c0;
    background: none;
    border-radius: 0.5rem;
    background-color: white;
    padding: 6px 14px;
    font-size: 16px;
    font-weight: 600;
    color: #4771c0;

}

.update-button:hover {
    background-color: #3b7ce312;
}

.accordion {
    h4 {
        font-size: 16px !important;
        font-weight: 600;
        line-height: 1.5;
        color: #2d2d2d;
    }
}

.rate-recent-company {
    .rate-star {
        background: #eef1fe;
        border-radius: 6px;
        padding: 6px 15px;
        width: fit-content;
    }

    background: #eef1fe;
    border-radius: 8px;
    padding: 30px 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        font-size: 14px;
    }

    h4 {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.5rem;
        color: #2d2d2d;
    }

    svg {
        width: 25px !important;
        height: 25px !important;
    }
}

.box-rounded {
    border-radius: .5rem;
    padding: 1.5rem;
    border: 1px solid #d4d2d0;

    h4 {
        font-size: 16px !important;
        font-weight: 600;
        line-height: 1.5;
        color: #2d2d2d;
    }

    p {
        font-size: 14px !important;
        font-weight: 400;
        line-height: 1.5;
    }
}

.Answers-section {
    .review-box {

        padding: 6px 14px;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 500;
        background: #faf9f8;
        border: 1px solid #949494;
    }

    .review-box:hover {
        background-color: #eef1fe;
        border-color: #6792f0;

    }

    .useful-review {
        background: #eef1fe;
        border-radius: 8px;
        padding: 12px 15px;

        span {
            color: #164081;

        }

        h2 {
            color: #164081;
            font-weight: 600;
            font-size: 17px;
        }
    }

    .radio-group {
        display: flex;
        justify-content: start;
        align-items: center;
        // margin-top: 20px;
    }

    .radio-group input[type="radio"] {
        display: none;
    }

    .radio-group label {
        padding: 8px 20px;
        border: 1px solid #ccc;
        margin-right: -1px;
        cursor: pointer;
    }

    .radio-group label:hover {
        background-color: #f0f0f0;
    }

    .radio-group input[type="radio"]:checked+label {
        background-color: #0056b3;
        color: white;
        border-color: #0056b3;
    }

    .radio-group label:first-of-type {
        border-radius: 5px 0 0 5px;
    }

    .radio-group label:last-of-type {
        border-radius: 0 5px 5px 0;
    }

    .radio-group label:not(:first-of-type):not(:last-of-type) {
        border-radius: 0;
    }

    h3 {
        font-size: 15px;
        font-weight: 600;
        color: #595959;
    }

    p {
        font-size: 14px !important;
        font-weight: 400;
        color: #595959;
    }
}