.question-answer-section {
    .question-form-section {
        border-radius: 4px;
        background: #FFF;
        padding: 40px;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .main-section {
        padding: 50px;
    }

    .question-top-head a {
        color: #0A65CC;

        text-decoration: none;
        font-size: 16px;

        font-weight: 500;

    }

    .question-section-head {
        color: #333;
        font-size: 32px;
        font-weight: 700;
        margin-top: 35px;
    }

    .form-label-custom {
        color: #141414;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
    }

    .form-custom {
        border-radius: 10px;
        border: 1px solid #D2D8D6;
        background: #FFF;
        box-shadow: 0px 14.18033px 85.08196px 0px rgba(38, 45, 118, 0.08);
        padding: 10px 18px;
        font-size: 16px;
        height: 65px;
    }

    .view-section a {
        color: #1B375C;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;


    }

    .view-section {
        text-align: center;
        margin-top: 35px;
    }

    .Continue-section .continue-btn {
        border-radius: 40px;
        background: #1B375C;
        color: #FFF;
        padding: 10px 40px;
        width: 300px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
    }

    .Continue-section {
        text-align: center;
        margin-top: 20px;
    }

    .main-section {
        padding: 25px;
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .question-answer-section {
        margin: 10px 0px;

        .main-section {
            padding: 0px;
        }

        .Continue-section .continue-btn {
            width: 100%;
        }

        .form-custom {
            padding: 20px;
            font-size: 15px;
        }

        .form-label-custom {
            font-size: 16px;
        }
    }

    .question-section-head {
        h3 {
            font-size: 20px;
        }
    }

}