.whyJoinUs-section{
    h5{
        font-size: 18px;
        font-weight: 400;
        color: #595959;
    }
    @media screen and (max-width:992px) {
        .Social-icon{
            display: flex;
            justify-content: center;
        }
        .img-resp{
            display: flex;
            justify-content: center;
        }
        
    }
   
}
.cursor-pointer{
    cursor: pointer;
}
.update-button{
    border: 1px solid #4771c0;
  background: none;
  border-radius: 0.5rem;
  background-color: white;
  padding: 6px 14px;
  font-size: 16px;
    font-weight: 600;
    color: #4771c0;
    
}
.update-button:hover{
    background-color: #3b7ce312;
}
// .Social-icon svg{
//     fill: #000;
// }