$color_1: #1B375C;
$color_2: #FFF;
$color_3: #b0a7a7;
$color_4: #393F47;
$color_5: #141414;

.search-candidate-details-section {
    .search-resumes-section {
        padding: 50px;
    }

    .search-resumes-bg {
        border-radius: 4px;
        padding: 20px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .search-resumes-display {
        display: flex;
        justify-content: space-between;
    }

    .search-btn1 {
        border-radius: 10px;
        border: 1px solid #1B375C;
        font-weight: 500;
        font-size: 18px;
        background: #fff;
        color: $color_1;
        padding: 9px 12px;
    }

    .search-btn2 {
        border-radius: 10px;
        border: 1px solid #1B375C;
        font-weight: 500;
        font-size: 18px;
        background: #fff;
        color: $color_1;
        padding: 9px 38px;
    }

    .search-btn3 {
        padding: 9px 29px;
        border-radius: 10px;
        border: 1px solid #1B375C;
        background: #1B375C;
        font-weight: 500;
        font-size: 18px;
        color: $color_2;
    }

    .search-heading {
        h3 {
            color: $color_1;
            font-size: 30px;
            font-weight: 700;
        }
    }

    .custom-form-section {
        display: flex !important;
    }

    .search-custom {
        position: relative;

        .form-custom {
            height: 47px;
            width: 351px;
            padding-left: 40px;
        }

        // input {
        //     &::-moz-placeholder {
        //         padding: 0 0 0 50px;
        //     }

        //     &::placeholder {
        //         padding: 0 0 0 50px;
        //     }
        // }

        img {
            position: absolute;
            top: 12px;
            left: 11px;
            color: $color_3;
        }
        .form-custom.form-cus-one {
            border-radius: 8px 0px 0px 8px;
        }

        .form-custom.form-cus-two {
            border-radius: 0px;
        }
    }

    .form-custom-btn {
        border-radius: 0px 8px 8px 0px;
        background: #1B375C;
        color: $color_2;
        border: none;
        width: 144px;
        height: 47px;
        font-size: 13px;
        font-weight: 500;
    }

    .advance-search-parent {
        margin-left: auto;
    }

    .advance-icon {
        p {
            color: $color_1;
            margin-top: 9px;
            font-size: 18px;
            font-weight: 600;
            color: $color_1;
            margin-top: 9px;
            font-size: 18px;
            font-weight: 600;
        }
    }

    .fitter-icon {
        height: 24px;
        width: 24px;
    }

    .filter-bg {
        border-radius: 4px;
        background: #FFF;
        padding: 30px;
        padding-bottom: 80px;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(29, 95, 181, 0.20) inset;
    }

    .day-btn {
        button {
            border-radius: 2px 0px 0px 2px;
            border: 1px solid rgba(20, 20, 20, 0.10);
            background: #FFF;
            padding: 8px;
            font-weight: 400;
            font-size: 13px;
            color: $color_4;
            width: 70px;
        }
    }

    .filter-heading {
        h5 {
            color: $color_5;
            margin-top: 25px;
            font-size: 20px;
            font-weight: 700;
        }
    }

    .filter-heading-second {
        h5 {
            color: $color_5;
            margin-top: 25px;
            font-size: 20px;
            font-weight: 700;
        }
    }

    .cust_btn1 {
        border: 2px solid #1B375C;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .cust_a1 {
        color: $color_1;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
    }

    .cust_btn2 {
        border: 2px solid #1B375C;
    }

    .cust_main {
        color: $color_5;
        font-size: 20px;
        font-weight: 500;
    }

    .cust_lis {
        padding-left: 0rem;
        margin-right: 2 rem;
    }

    .search-candidate-ul-section {
        margin-top: 5px;
        .cust_lis{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: center;
            justify-content: flex-start;
            align-items: center;
            .list-inline-item{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: center;
                justify-content: flex-start;
                align-items: center;
                margin-right: 10px;
            }
        }
    }

    .cust_row {
        border-radius: 8px;
        border: 1px solid rgba(20, 20, 20, 0.05);
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
        margin-top: 17px;
    }

    .photo-cus {
        img {
            padding: 15px;
        }
    }

    .text-left {
        a {
            text-decoration: none;

            h5 {
                color: #000;
                margin-top: 17px;
                font-size: 18px;
            }
        }
    }

    .cust_up {
        ul {
            margin-top: 17px;
        }
    }

    .candidates-heading {
        h3 {
            color: $color_5;
            margin-top: 20px;
            font-size: 30px;
            font-weight: 700;
        }
    }

    .dropdown-custom-btn {
        border-radius: 4px;
        border: 1px solid var(--black-50, #53545C);
    }

    .dropdown-custom-section {
        display: flex;
    }

}

/*================================ media query start ========================================*/
@media only screen and (min-width:768px) and (max-width:920px) {
    .search-candidate-details-section {
        .custom-form-section {
            display: flex !important;
            flex-direction: column;
        }

        .search-custom .form-custom {
            margin-top: 7px;
        }

        .form-custom-btn {
            margin-top: 18px;
        }

        .search-btn3 {
            margin-top: 15px;
        }

        .dropdown-custom-section {
            display: flex;
            flex-direction: column;
        }

        .dropdown-custom-btn {

            margin-top: 17px;
        }
    }
}

@media only screen and (min-width:568px) and (max-width:992px) {
    .search-candidate-details-section {
        .custom-form-section {
            flex-direction: row;
        }

        .form-search-area {
            flex-wrap: wrap;
        }

        .dropdown-custom-section {
            flex-direction: row;
        }
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .search-candidate-details-section {
        .search-resumes-display {
            display: flex;

            flex-direction: column;
        }

        .search-resumes-section {
            padding: 0px;
        }

        .search-btn2 {
            padding: 9px 23px;
            font-size: 12px;
        }

        .search-btn1 {
            padding: 9px 3px;
            font-size: 12px;
        }

        .search-btn3 {
            padding: 9px 14px;
            font-size: 12px;
            margin-top: 10px;
        }

        .search-heading h3 {

            font-size: 20px;

        }

        .custom-form-section {
            display: flex !important;
            flex-direction: column;
        }

        .advance-icon {
            display: flex !important;
            flex-direction: column;

        }

        .dropdown-custom-section {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }

        .search-custom .form-custom {

            width: 237px;
            margin-top: 8px;

        }

        .advance-icon p {
            display: none;

        }

        .fitter-icon {
            display: none;
        }

    }
}