$color_1: #1B375C;
$color_2: #FFF;
$color_3: #b0a7a7;
$color_4: #fff;
$color_5: #141414;
$color_6: #393F47;
$border-color_1: #dee2e6 #dee2e6 #000;

.searched-resume-section {
    .project-search-section {


        .search-resumes-section {
            padding: 50px;

        }

        .search-form {
            .advance-search-parent {
                margin-left: "auto";

                .advance-icon {
                    cursor: pointer;
                }
            }
        }



        .search-resumes-bg {
            border-radius: 4px;
            padding: 20px;
            background: #FFF;
            box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;

            .tab-area {
                .nav-tabs-custom {
                    li:nth-child(1) button {
                        border-radius: 8px 0 0 8px !important;
                    }

                    li:nth-child(3) button {
                        border-radius: 0 8px 8px 0 !important;
                    }

                    .nav-item {
                        // border: 1px solid #1B375C;

                        .nav-link {
                            border-radius: 0;
                            color: #1B375C;
                            border: 1px solid #1b375c;
                        }

                        .nav-link.active {
                            color: #fff;
                            background-color: #1B375C !important;
                        }
                    }
                }
            }
        }

        .search-resumes-display {
            display: flex;
            justify-content: space-between;
        }

        .search-btn1 {
            border-radius: 10px;
            border: 1px solid #1B375C;
            font-size: 16px;
            background: #fff;
            color: $color_1;
            padding: 10px 30px;
            text-decoration: none;
        }

        .search-btn2 {
            border-radius: 10px;
            border: 1px solid #1B375C;
            font-size: 16px;
            background: #fff;
            color: $color_1;
            padding: 10px 20px;
            text-decoration: none;
        }

        .search-btn3 {
            padding: 10px 20px;
            border-radius: 10px;
            border: 1px solid #1B375C;
            background: #1B375C;
            font-size: 16px;
            color: $color_2;
            text-decoration: none;
        }

        .search-heading {
            h3 {
                color: $color_1;
                font-size: 30px;
                font-weight: 700;
            }
        }

        .custom-form-section {
            display: flex !important;
        }

        .search-custom {
            position: relative;

            .form-custom {
                height: 47px;
                width: 350px;
                padding-left: 40px;
            }

            // input {
            //     &::-moz-placeholder {
            //         padding: 0 0 0 50px;
            //     }

            //     &::placeholder {
            //         padding: 0 0 0 50px;
            //     }
            // }

            img {
                position: absolute;
                top: 12px;
                left: 11px;
                color: $color_3;
            }
        }

        .form-custom-btn {
            border-radius: 0px 8px 8px 0px;
            background: #1B375C;
            color: $color_2;
            border: none;
            width: 144px;
            height: 47px;
            font-size: 13px;
            font-weight: 500;
        }

        .advance-icon {
            p {
                color: $color_1;
                margin-top: 9px;
                font-size: 18px;
                font-weight: 600;
            }
        }

        .fitter-icon {
            height: 24px;
            width: 24px;
        }

        .filter-bg {
            border-radius: 4px;
            background: #FFF;
            padding: 30px;
            padding-bottom: 80px;
            box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(29, 95, 181, 0.20) inset;
        }

        .dropdown-custom-btn {
            border-radius: 4px;
            border: 1px solid var(--black-50, #53545C);
        }

        .dropdown-custom-section {
            display: flex;
            justify-content: flex-end;
        }

        .nav-tabs {
            // li:nth-child(1) {
            //     border-radius: 8px 0 0 8px;
            // }

            // li:nth-child(3) {
            //     border-radius: 0 8px 8px 0;
            // }

            .nav-item {
                // border: 1px solid #1B375C;

                .nav-link {
                    border-radius: 0;
                    color: #1B375C;
                    border: none;
                }

                .nav-link.active {
                    color: #fff;
                    background-color: #1B375C !important;
                }
            }
        }


        .nav-tabs-custom {
            border-bottom: none !important;
        }

        .candidates-heading {
            h3 {
                color: $color_5;
                margin-top: 20px;
                font-size: 30px;
                font-weight: 700;
            }
        }

        .cust_row {
            border-radius: 8px;
            border: 1px solid rgba(20, 20, 20, 0.05);
            background: #FFF;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
            margin-top: 17px;
        }

        .photo-cus {

            // img {
            //     padding: 15px;
            // }
            .searched-resume-page-profile-img {
                img.createimagefrominitials {
                    width: 65px;
                    height: 65px;
                    border: 1px solid black;
                    border-radius: 5px;
                    padding: 0;
                }

            }
        }

        .text-left {
            a {
                text-decoration: none;
                color: #1d375c;

                h5 {
                    margin-top: 0px;
                    font-size: 16px;
                }
            }

        }

        .cust_up {
            ul {
                margin-top: 17px;

                li {
                    .shareprofilebtn {
                        border: none;
                        background: none;
                    }
                }
            }
        }

        .filter-heading {
            h5 {
                color: $color_5;
                margin-top: 25px;
                font-size: 20px;
                font-weight: 700;
            }
        }

        .cust_lis {
            padding-left: 0rem;
        }

        .day-btn {
            button {
                border-radius: 2px 0px 0px 2px;
                border: 1px solid rgba(20, 20, 20, 0.10);
                background: #FFF;
                padding: 8px;
                font-weight: 400;
                font-size: 13px;
                color: $color_6;
                width: 70px;
            }
        }

        .tab-view-custom {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            align-items: center;
        }

        .craete-btn {
            padding: 10px 30px;
            border-radius: 10px;
            border: 1px solid #1B375C;
            color: $color_1;
            font-size: 16px;
            background: #fff;
        }

        .card-display-section {
            display: flex !important;
            justify-content: space-between;
        }

        .project-list {
            li {
                list-style: none;
                padding: 2px 60px 2px 1px;
            }

            ul {
                display: flex;
                padding: 0px;
            }
        }

        .card-custom {
            padding: 20px;
        }

        .cust_main {
            color: $color_5;
            font-size: 24px;
            font-weight: 500;
        }

        .create-modal-dv {
            .btn-close {
                background: #F5F6F7 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
                border-radius: 50%;
            }

            .modal-body {
                .form-group {
                    select {
                        -webkit-appearance: auto;
                        -moz-appearance: auto;
                        appearance: auto;
                    }
                }
            }

            .footer-btn {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-content: center;
                align-items: center;

                .footer-modal-btn {
                    background: #F5F6F7;
                    border: none;
                    outline: none;
                    padding: 5px 15px;
                    border-radius: 20px;
                }

                .footer-modal-btn.cancel {}

                .footer-modal-btn.create {
                    background: #005CE8;
                    color: #fff;
                }
            }
        }
    }
}


/*================================ media query start ========================================*/

@media only screen and (min-width:200px) and (max-width:767px) {
    .searched-resume-section {
        .project-search-section {
            .search-resumes-display {
                display: flex;

                flex-direction: column;
            }

            .search-resumes-section {
                padding: 0px;
            }

            .search-btn2 {
                padding: 9px 23px;
                font-size: 12px;

            }

            .search-btn1 {
                margin-top: 10px;
                font-size: 12px;
            }

            .search-btn3 {
                padding: 9px 14px;
                font-size: 12px;
                margin-top: 10px;
            }

            .search-heading h3 {

                font-size: 20px;

            }

            .custom-form-section {
                display: flex !important;
                flex-direction: column;
            }

            .advance-icon {
                display: flex !important;
                flex-direction: column;

            }

            .dropdown-custom-section {
                display: flex;
                flex-direction: column;
            }

            .search-custom .form-custom {

                width: 237px;
                margin-top: 8px;

            }

            .advance-icon p {
                display: none;

            }

            .fitter-icon {
                display: none;
            }

            .craete-btn {

                padding: 0px;
                font-size: 14px;

            }

            .nav-link-custom-second {

                margin-top: 12px;
                padding: 5px;
            }

            .mx-2 {
                margin-right: 0rem !important;
                margin-left: 0rem !important;
            }

            .mt-4 {
                margin-top: 0.5rem !important;
            }

        }
    }
}