.signin-section-seeker .user-envlop-cls {
  position: relative;
}
.signin-section-seeker .user-envlop-cls span {
  position: absolute;
  left: 15px;
  top: 8px;
}
.signin-section-seeker .user-envlop-cls input {
  padding-left: 40px;
}
.signin-section-seeker .user-envlop-cls2 {
  position: relative;
}
.signin-section-seeker .user-envlop-cls2 .img {
  position: absolute;
  left: 27px;
  top: 12px;
}
.signin-section-seeker .user-envlop-cls2 input {
  padding-left: 40px;
}
.signin-section-seeker li {
  list-style: none;
  padding: 5px 10px 10px;
}
.signin-section-seeker a {
  text-decoration: none;
}
.signin-section-seeker .input-text {
  width: 100%;
  height: 44px;
  border: 1px solid #a59b9b;
  border-radius: 10px;
  padding: 10px;
}
.signin-section-seeker .pt-2 {
  color: #a59b9b;
}
.signin-section-seeker ul {
  display: flex;
  justify-content: center;
  padding-left: 0px;
}
.signin-section-seeker .para {
  margin-bottom: 0px;
  margin-top: 30px;
  color: #a59b9b;
  text-align: left;
}
.signin-section-seeker .row-top {
  margin-top: 15px;
}
.signin-section-seeker form {
  margin: 30px;
}
.signin-section-seeker .pt-3 {
  text-align: center;
  /* margin: 12px; */
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  color: #a59b9b;
}
.signin-section-seeker .second-section {
  border-radius: 13px;
  margin-top: 80px;
  margin-bottom: 80px;
  background: #FFF;
  box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.signin-section-seeker .para-3 {
  margin-bottom: 1px;
  margin-top: 22px;
}
.signin-section-seeker .password-custom {
  position: relative;
}
.signin-section-seeker .password-custom span {
  position: absolute;
  right: 25px;
  top: 14px;
  color: #939191;
}
.signin-section-seeker .login-btn {
  width: 100%;
}
.signin-section-seeker .login-btn a {
  border-radius: 30px;
  background: rgb(27, 55, 92);
  color: white;
  font-size: 17px;
  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 145px;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .signin-section-seeker .input-text {
    font-size: 10px;
  }
  .signin-section-seeker .login-btn a {
    border-radius: 30px;
    background: rgb(27, 55, 92);
    color: white;
    font-size: 17px;
    box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.25);
    padding: 10px 54px;
  }
  .signin-section-seeker .pt-2 {
    color: #a59b9b;
    text-align: left;
  }
  .signin-section-seeker .pt-2 a {
    color: #a59b9b;
  }
}/*# sourceMappingURL=SignIn.css.map */