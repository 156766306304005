// =============Navbar==============
.career-guide-nav {
    background: #FE6108;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);

    .custom-toggler {
        &:focus {
            box-shadow: none;
        }
    }

    .navbar {
        background: #FE6108;
        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);
        height: 70px;

        .navbar-toggler-icon {
            color: #fff;
        }

        .navbar-collapse {
            background-color: #FE6108;
        }

        a {
            color: #fff;
        }

        .navbar-nav {
            a {
                padding: 10px 20px;
            }
        }
    }
}

// =============Career Search==============
.career-search {
    background-image: url(../../../../../public/assets/images/career-guide/career.png);
    background-position: 100%;
    background-size: cover;
    min-height: 440px;

    h1 {
        color: #141414;
        text-align: center;
        font-family: Barlow;
        font-size: 75px;
        font-weight: 400;
    }

    h6 {
        text-align: center;
        color: rgba(0, 0, 0, 0.70);
        font-family: Barlow;
        font-size: 18px;
        font-weight: 500;
        padding: 15px 0 0 0;
    }

    .serach-input {
        position: relative;

        img {
            position: absolute;
            left: 12px;
            top: 13px;
        }

        input {
            padding: 14px 0;
            border-radius: 5px 0px 0px 5px;

            &::placeholder {
                padding: 0 0 0 40px;
                color: rgba(20, 20, 20, 0.50);
                font-size: 16px;
                font-weight: 400;
            }
        }

    }

    a {
        border-radius: 0px 8px 8px 0px;
        background: #1B375C;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        padding: 12px 30px;
        text-decoration: none;
    }

    .search-career-guide-btn {
        border-radius: 0px 8px 8px 0px;
        background: #1B375C;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        padding: 12px 30px;
        text-decoration: none;
    }

    .search-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;

        li {
            list-style: none;
            position: relative;
            margin: 10px 10px;
        }

        li:nth-child(1) {
            img {
                position: absolute;
                right: 10px;
                top: 2px;
            }
        }

        li:nth-child(2) {
            img {
                position: absolute;
                right: 10px;
                top: 2px;
            }
        }

        li:nth-child(3) {
            img {
                position: absolute;
                right: 10px;
                top: 2px;
            }
        }

        li:nth-child(4) {
            img {
                position: absolute;
                right: 10px;
                top: 2px;
            }
        }

        li:nth-child(5) {
            img {
                position: absolute;
                right: 10px;
                top: 2px;
            }
        }

        li:nth-child(6) {
            img {
                position: absolute;
                right: 10px;
                top: 2px;
            }
        }

        a {
            border: unset;
            border-radius: 7px;
            background: #FFF;
            color: #1B375C;
            font-size: 18px;
            font-weight: 500;
            padding: 12px 40px 12px 10px;
        }
    }


}

// =============Career Advice==============
.career-advice {

    // .row {
    //     border-radius: 4px;
    //     background: #FFF;
    //     box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    // }

    h1 {
        text-align: left;
        color: #141414;
        font-family: Barlow;
        font-size: 32px;
        font-weight: 700;
    }

    .custm-career-box {
        background: #F6F6F6;
        cursor: pointer;

        img {
            height: 200px;
            width: 100%;
            object-fit: cover;
        }

        .textarea {
            height: 220px;
            padding: 10px 20px;

            h3 {
                text-align: left;
                color: #1B375C;
                font-family: Poppins;
                font-size: 18px;
                font-weight: 600;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            div.blah-blah {
                color: #777;
                text-align: justify;
                font-size: 13px;
                font-weight: 400;
                line-height: 16px;
                max-height: 155px;
                overflow-y: scroll;
                padding: 0px 8px;
                word-wrap: break-word;

                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: unset;
                    background-color: transparent;
                }

                &::-webkit-scrollbar {
                    width: 5px;
                    background-color: #F5F5F5;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #000000;
                }
            }

            a {
                display: flex;
                text-decoration: none;
                color: #0A65CC;
                font-family: Poppins;
                font-size: 13px;
                font-weight: 600;
            }
        }


    }

}

// =============Career Test==============
.career-test {
    .container {
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }


    .title {
        h1 {
            color: #141414;
            font-family: Barlow;
            font-size: 32px;
            font-weight: 700;
        }

        .font-size-inc {
            font-size: 25px
        }

        a {
            text-decoration: none;
            padding: 8px 50px;
            border-radius: 8px;
            border: 1px solid rgba(27, 55, 92, 0.64);
            background: var(--light-background-transparent, rgba(255, 255, 255, 0.00));
            color: #1B375C;
            font-size: 18px;
            font-weight: 600;
        }
    }

    .custm-info-box {
        background: #F6F6F6;
        border-radius: 0 5px 10px 10px;

        .textarea {
            padding: 20px 20px;

            h3 {
                text-align: left;
                color: var(--Main, #073E87);
                font-size: 25px;
                font-weight: 600;
                margin: 15px 0;
            }

            p {
                text-align: left;
                color: var(--Sub-ink, #747D8C);
                font-size: 15px;
                font-weight: 400;
            }

            ul {
                padding: unset;

                li {
                    list-style: none;

                    img {
                        padding: 0 10px 4px 0;
                        object-fit: none;
                    }

                }
            }
        }
    }
}

// =============Feature Blog==============
.feature-blog {
    .container {
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .custm-sec-lft {
        h1 {
            text-align: left;
            color: #141414;
            font-family: Barlow;
            font-size: 32px;
            font-weight: 700;
            padding: 0 0 15px 0;
        }

        .new-job-info {
            h5 {
                text-align: left;
                color: #666;
                font-size: 12px;
                font-weight: 400;
            }

            h2 {
                text-align: left;
                color: #222;
                font-size: 24px;
                font-weight: 600;
            }

            ul {
                padding: unset;

                li {
                    list-style: none;
                    color: #777;
                    font-size: 12px;
                    font-weight: 400;

                    img {
                        padding: 0 5px 0 0;
                    }
                }

                li:nth-child(2) {
                    padding: 0 20px;
                }
            }

            h4 {
                text-align: left;
                color: #555;
                font-size: 15px;
                font-weight: 400;
                line-height: 150%;
            }
        }
    }

    .custm-sec-rit {
        .recent-post {
            padding: 0 5px;

            h3 {
                color: #1B375C;
                font-family: Barlow;
                font-size: 32px;
                font-weight: 600;
            }

            h4 {
                text-align: left;
                color: #1B375C;
                font-family: Barlow;
                font-size: 22px;
                font-weight: 600;
            }

            .custm-posts {
                .post-detail {
                    .post-text {
                        h4 {
                            text-align: left;
                            color: var(--sale-tage-coloe, #3F509E);
                            font-size: 14px;
                            font-weight: 400;
                        }

                        h5 {
                            text-align: left;
                            color: var(--sub-text-color, #8A8FB9);
                            font-size: 11px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

    }

    .view-btn {
        a {
            padding: 10px 50px;
            text-decoration: none;
            border-radius: 8px;
            border: 1px solid rgba(27, 55, 92, 0.64);
            background: var(--light-background-transparent, rgba(255, 255, 255, 0.00));
            color: #1B375C;
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
        }
    }
}

// =============Interview Question==============

.interview-question {
    .container {
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .custm-ques-sec {
        cursor: pointer;

        .question-logo {
            min-width: 50px;
            min-height: 50px;
            max-width: 50px;
            max-height: 50px;
            // border:1px solid #ccc;
            border-radius: 5px
        }
    }

    h1 {
        color: #141414;
        font-family: Barlow;
        font-size: 32px;
        font-weight: 700;
    }

    h3 {
        color: #000;
        font-family: Poppins;
        font-size: 24px;
        font-weight: 500;
    }

    p {
        color: #000;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
    }

    .col-lg-4 {
        padding: 30px;

        img {
            padding: 0 0 15px 0;
        }
    }

    .col-lg-4:nth-child(2) {
        border-bottom: 1px solid #DDD;
    }

    .col-lg-4:nth-child(3) {
        border: 1px solid #DDD;
        border-top: unset;
    }

    .col-lg-4:nth-child(4) {
        border-bottom: 1px solid #DDD;
    }

    .col-lg-4:nth-child(6) {
        border: 1px solid #DDD;
        border-bottom: unset;
        border-top: unset;
    }

    .view-btn {
        a {
            padding: 10px 50px;
            text-decoration: none;
            border-radius: 8px;
            border: 1px solid rgba(27, 55, 92, 0.64);
            background: var(--light-background-transparent, rgba(255, 255, 255, 0.00));
            color: #1B375C;
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
        }
    }
}

// =============Resumes==============

.resumes {
    .container {
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .resume-info {
        h5 {
            text-align: left;
            color: #666;
            font-size: 12px;
            font-weight: 400;
        }

        h2 {
            text-align: left;
            font-size: 22px;
            font-weight: 600;
        }

        ul {
            padding: unset;

            li {
                list-style: none;
                color: #777;
                font-size: 12px;
                font-weight: 400;

                img {
                    padding: 0 5px 0 0;
                }
            }

            li:nth-child(2) {
                padding: 0 20px;
            }
        }

        h4 {
            text-align: left;
            color: #555;
            font-size: 15px;
            font-weight: 400;
            line-height: 150%;
        }
    }

    .view-btn {
        a {
            padding: 10px 50px;
            text-decoration: none;
            border-radius: 8px;
            border: 1px solid rgba(27, 55, 92, 0.64);
            background: var(--light-background-transparent, rgba(255, 255, 255, 0.00));
            color: #1B375C;
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
        }
    }
}



@media only screen and (min-width:200px) and (max-width:767px) {
    .career-search {
        height: auto;

        .search-career-guide-btn {
            border-radius: 8px 8px 8px 8px;
        }

        .career-title {
            h1 {
                font-size: 45px;
            }
        }

        .custm-serch {
            a {
                font-size: 12px;
                padding: 16px 7px;
            }

            display: flex !important;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;

            a.my-5 {
                margin: 0px !important;
                margin-bottom: 20px !important;
                width: 100%;
                border-radius: 0px;
            }

            .serach-input {
                margin: 0px !important;
                width: 100%;



                input {
                    border-radius: 0px;
                }
            }

        }

        .search-list {
            display: block !important;

            li {
                a {
                    padding: 0;

                }

                margin-bottom: 20px;
                // width: 100%;
                background: #fff;
                padding: 10px 40px 12px 10px;

                img {

                    top: 11px !important;
                }
            }
        }

    }


    .career-advice {
        .col-lg-3 {
            margin: 0 0 10px 0;

            .custm-career-box {
                .textarea {
                    height: 180px;
                    padding: 15px 20px;
                }

                img {
                    width: 100% !important;
                }
            }
        }

        .custm-career-box {
            margin: 5px 0px;

            .textarea {
                height: 100%;
                padding: 35px 20px;
            }


        }

    }

    .career-test {
        .title {
            display: block !important;

            h1 {
                font-size: 25px;
            }

            a {
                font-size: 14px;
                padding: 8px 22px;
            }
        }

        .custm-info-box {
            .textarea {
                h3 {
                    font-size: 18px;
                }

                ul {
                    display: block !important;
                }
            }
        }
    }

    .feature-blog {
        .custm-sec-lft {
            .title {
                h1 {
                    font-size: 25px;
                }
            }

            .new-job-info {
                h2 {
                    font-size: 16px;
                }

                ul {
                    display: block !important;

                    li {
                        margin: 0 0 10px 0;
                    }

                    li:nth-child(2) {
                        padding: unset;
                    }
                }
            }

            .new-job-img {
                padding: unset !important;
            }
        }

        .custm-sec-rit {
            .recent-post {
                h3 {
                    font-size: 25px;
                }
            }
        }

        .view-btn {
            a {
                padding: 4px 22px;
                font-size: 14px;
            }
        }

    }

    .interview-question {
        h1 {
            font-size: 25px;
        }

        h3 {
            font-size: 18px;
        }

        .view-btn {
            a {
                padding: 4px 22px;
                font-size: 14px;
            }
        }
    }

    .resumes {
        padding: unset !important;

        .resume-info {

            ul {
                display: block !important;

                li {
                    margin: 0 0 10px 0;
                }

                li:nth-child(2) {
                    padding: unset;
                }
            }
        }

        .resume-img {
            padding: unset !important;
        }

        .view-btn {
            a {
                padding: 4px 22px;
                font-size: 14px;
            }
        }
    }
}

@media only screen and (min-width:768px) and (max-width:992px) {
    .text-md-start {
        text-align: left;
    }

    .career-search {
        height: auto;

        .search-career-guide-btn {
            border-radius: 8px 8px 8px 8px;
        }

        .career-title {
            h1 {
                font-size: 45px;
            }
        }

        .custm-serch {
            a {
                font-size: 12px;
                padding: 16px 7px;
            }

            display: flex !important;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;

            a.my-5 {
                margin: 0px !important;
                margin-bottom: 20px !important;
                width: 100%;
                border-radius: 0px;
            }

            .serach-input {
                margin: 0px !important;
                width: 100%;



                input {
                    border-radius: 0px;
                }
            }

        }

        .search-list {
            display: block !important;

            li {
                a {
                    padding: 0;

                }

                margin-bottom: 20px;
                width: 100%;
                background: #fff;
                padding: 10px 40px 12px 10px;

                img {

                    top: 11px !important;
                }
            }
        }

    }

    .career-advice {
        .col-lg-3 {
            margin: 0 0 10px 0;
        }

        .custm-career-box {
            img {
                width: 100% !important;
            }
        }

    }

    .feature-blog {
        .custm-sec-lft {
            .new-job-info {
                ul {
                    display: block !important;

                    li {
                        margin: 0 0 10px 0;
                    }

                    li:nth-child(2) {
                        padding: unset;
                    }
                }
            }
        }

    }
}