.choose-your-subscription-plan-sec .main-subs-plan .Subscrip-card {
  flex-shrink: 0;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  padding: 30px;
}
.choose-your-subscription-plan-sec .main-subs-plan .Subscrip-card {
  padding: 30px;
}
.choose-your-subscription-plan-sec .main-subs-plan .Subscrip-card h1 {
  color: #1B375C;
  text-align: center;
  font-family: Barlow;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
}
.choose-your-subscription-plan-sec .main-subs-plan .Subscrip-card p {
  color: #1B375C;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec {
  display: flex;
  padding: 20px 25px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
  border-radius: 20px;
  border: 2px solid #1B375C;
  background: #FFF;
  position: relative;
}
.choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .card .basic h3 p {
  color: #1B375C;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec .basic h3 {
  color: #1B375C;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 15px;
}
.choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec .basic p {
  color: #1B375C;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: justify;
}
.choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec .basic .cus-gap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 38px;
  margin-top: 35px;
}
.choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec .basic .cus-price {
  color: #1B375C;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
}
.choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec .basic .cus-mon {
  color: #AEAEAE;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec .basic .new-p {
  color: #1B375C;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec .basic .cus-bottom {
  height: 1.5px;
  background-color: rgba(174, 174, 174, 0.4);
  width: 100%;
  margin-bottom: 10px;
}
.choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec .basic .cus-inner-p {
  color: #1B375C;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}
.choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec .basic .cus-btn {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec .basic .btn-inner {
  border-radius: 8px;
  background: #1B375C;
  color: #fff;
}
.choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec .basic .free-plan {
  border-radius: 8px;
  border: 2px solid #1B375C;
  background: #fff !important;
  color: #1B375C !important;
}
.choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec .basic .free-plan:hover {
  border-radius: 8px;
  background: #1B375C;
  color: #fff;
}
.choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec .basic .cus-list .cus-head h2 {
  color: #1B375C;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec .basic .cus-inner-list li {
  color: #1B375C;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec .basic .cus-inner-list li i.fa-check-circle {
  color: green;
}
.choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec .basic .cus-inner-list li i.fa-circle-xmark {
  color: red;
}
.choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec .basic .cus-inner-list .cus-tab {
  color: #FFF;
  width: 18px;
  height: 12px;
  margin-left: 8px;
  border-radius: 4px;
  background: #1B375C;
  padding: 3px 3px 3px 3px;
  font-size: 9px;
  text-align: center;
}
.choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec .cus-ribbon {
  position: absolute;
  right: 0px;
  top: 0px;
  margin-right: -3px;
  border-radius: 0px 20px;
  background: #1B375C;
  color: #fff;
  font-size: 13px;
  padding: 6px 18px;
  color: #FFF;
  text-align: right;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media only screen and (min-width: 992px) and (max-width: 1080px) {
  .choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec {
    display: flex;
    padding: 20px 25px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
    border-radius: 20px;
    border: 2px solid #1B375C;
    background: #FFF;
    position: relative;
    min-height: 665px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec {
    display: flex;
    padding: 20px 25px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
    border-radius: 20px;
    border: 2px solid #1B375C;
    background: #FFF;
    position: relative;
    min-height: 665px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .choose-your-subscription-plan-sec .main-subs-plan .cus-subs-2 .cus-sec {
    display: flex;
    padding: 20px 25px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
    border-radius: 20px;
    border: 2px solid #1B375C;
    background: #FFF;
    position: relative;
    min-height: 665px;
  }
}/*# sourceMappingURL=ChooseYourSubscriptionPlan.css.map */