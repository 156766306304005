.custm-chart .container {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.custm-chart .total-counts-dv {
  margin: 20px 0px;
}
.custm-chart .total-counts-dv .total-view-count .total-cv-downloads a {
  text-decoration: none;
  color: #000;
}
.custm-chart .total-counts-dv .total-view-count .total-cv-downloads a p {
  text-align: center;
  border-radius: 20px;
  border: 1px solid #ccc;
  margin-bottom: 0;
  padding: 2px 5px;
}
.custm-chart .heading-freq h2 {
  color: var(--light-text-primary, #1C1C1E);
  font-size: 24px;
}
.custm-chart .heading-freq .cursor-pointer-profile-performance {
  cursor: pointer;
}
.custm-chart .canvasjs-chart-credit {
  display: none !important;
}
.custm-chart .chart-data h1 {
  color: var(--light-text-primary, #1C1C1E);
  font-size: 32px;
  font-weight: 700;
}
.custm-chart .chart-data h2 {
  color: var(--light-text-primary, #1C1C1E);
  font-size: 24px;
}
.custm-chart .chart-data p {
  color: var(--light-text-secondary, rgba(28, 28, 30, 0.72));
  font-size: 14px;
}

.profile-title .title {
  border-radius: 4px;
  background: #F4FAFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(29, 95, 181, 0.2) inset;
}
.profile-title .title h1 {
  color: #1B375C;
  font-family: Barlow;
  font-size: 22px;
  font-weight: 700;
}

.profile-viewer img.logo-img {
  border: 1px solid black;
  border-radius: 50%;
  height: 65px;
  width: 65px;
}
.profile-viewer .slick-slider .slick-prev {
  right: 90px !important;
  left: unset;
}
.profile-viewer .slick-slider .slick-next,
.profile-viewer .slick-slider .slick-prev {
  font-size: 0;
  line-height: 0;
  top: -20%;
  width: 20px;
  height: 20px;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
}
.profile-viewer .slick-slider .slick-prev:before {
  position: absolute;
  content: "\f053";
  color: #ffffff;
  font-size: 12px;
  font-family: fontawesome;
  background: rgb(27, 55, 92);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 24px;
  border: 1px solid rgb(27, 55, 92);
}
.profile-viewer .slick-slider .slick-next {
  right: 40px !important;
}
.profile-viewer .slick-slider .slick-next,
.profile-viewer .slick-slider .slick-prev {
  font-size: 0;
  line-height: 0;
  top: -50px;
  width: 20px;
  height: 20px;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
}
.profile-viewer .slick-slider .slick-next:before {
  position: absolute;
  content: "\f054";
  color: #ffffff;
  font-size: 12px;
  font-family: fontawesome;
  background: rgb(27, 55, 92);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 24px;
  border: 1px solid rgb(27, 55, 92);
}
.profile-viewer .container {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.profile-viewer .title h3 {
  color: var(--light-text-primary, #1C1C1E);
  font-size: 24px;
}
.profile-viewer .profile .profile-text h3 {
  color: var(--light-text-primary, #1C1C1E);
  font-size: 16px;
}
.profile-viewer .profile .profile-text h4 {
  color: var(--light-text-secondary, rgba(28, 28, 30, 0.72));
  font-size: 12px;
}
.profile-viewer .profile .profile-text h4 span {
  color: var(--light-text-secondary, rgba(28, 28, 30, 0.72));
  font-size: 12px;
}
.profile-viewer .profile .profile-text a {
  text-decoration: none;
  color: #0A65CC;
  font-size: 16px;
  font-weight: 600;
}/*# sourceMappingURL=profile.css.map */