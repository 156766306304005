.add-job-details-sec {
    margin: 40px 0px;

    .create-an-emp-inner {
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
        box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
        padding: 40px;

        .heading {
            h2 {
                font-weight: 600;
                margin-bottom: 8px;
            }
        }
        .heading-inner{
            h3{
                font-size: 22px;
                font-weight: 600;
            }
        }
        .card-outer-dv{
            border: 1px solid rgba(0, 0, 0, 0.25);
            box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            padding: 15px 40px;
            margin: 20px 0px;

            .job-type-btn{
                
                .btn-circle{
                    label {
                        border: 1px solid rgba(27, 55, 92, 1);
                        color:  #1B375C;
                        border-radius: 40px;
                        background-color: #fff;
                        padding: 10px 0px;
                        width:100%;
                        font-size: 15px;
                        text-align: center;
                        &:hover{
                            background-color: #1B375C;
                            color: #fff;
                        }
                    }
    
                    input[type="checkbox"] {
                        display: none;
                    }
    
                    input[type="checkbox"]:checked+label {
                        border: solid 1px transparent;
                        background-color: #1B375C;
                        color: #fff;
                    }
                    
                }
                
            }
            label{
                font-weight: 600;
            }
            .inner-input{
                .radio-inpt{
                    label{
                        color: rgb(102 102 102 / 93%);
                        font-weight: 400;
                    }
                }
                select{
                    -webkit-appearance: auto;
                     -moz-appearance: auto;
                      appearance: auto;
                }
            }
            .left-sd-content{
                p{
                    margin-bottom: 0px;
                }
            }
            .right-sd-btn{
                .continue-btn{
                    background: rgba(27, 55, 92, 1);
                    color: #fff;
                    border-radius: 40px;
                    padding: 10px 40px;
                    text-decoration: none;
                    span{
                        margin-left: 10px;
                    }
                }
            }
        }
        .card-outer-dv1{
            padding: 30px 40px;
        }
        .bottom-button-dv {
            margin: 40px 0px;

            .bottom-btn {
                .a-tag-btn1 {
                    border: 1px solid rgba(27, 55, 92, 1);
                    text-decoration: none;
                    border-radius: 40px;
                    padding: 10px 50px;
                    color: #1b375c;
                }

                .a-tag-btn2 {
                    border: 1px solid rgba(27, 55, 92, 1);
                    text-decoration: none;
                    border-radius: 40px;
                    background: rgba(27, 55, 92, 1);
                    color: #fff;
                    padding: 10px 50px;
                }

                
                    .next-btn {
                        // box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.5);
                        background: rgb(27, 55, 92);
                        color: #fff;
                        border-radius: 32px;
                        padding: 10px 70px;
                        margin: 4rem 10px 0px 10px;
                        border: none;
                        text-decoration: none;
                    }
                
            }
        }

    }
}
@media only screen and (min-width:768px) and (max-width:992px) { 

}

@media only screen and (min-width:200px) and (max-width:767px) { 
    .add-job-details-sec {
        .create-an-emp-inner{
            padding: 10px;
            .card-outer-dv{
                padding: 10px 20px;
                .right-sd-btn{
                    margin: 30px 0px;
                }
            }
            .bottom-button-dv {
                text-align: center;
                margin: 40px 0px;
                .bottom-btn {
                        margin: 10px 0px;
                        text-align: center;
                }
            }
        }

    }
}
