@media only screen and (min-width:768px) and (max-width:992px) {

    // ====================Banner=======================
    .transparent-pricing-banner {
        .banner-area {
            height: 320px;
            background-size: cover;

            .banner-text {
                left: auto !important;
                transform: none !important;
                width: auto !important;
                top: 15% !important;

                h1 {
                    font-size: 30px !important;
                }

                p {
                    font-size: 16px !important;
                    margin: 10px 0 15px 0 !important;
                }

                a {
                    padding: 7px 25px !important;
                    font-size: 16px !important;
                }
            }

            .banner-sec-lft {
                &::before {
                    clip-path: unset;
                }
            }

            .banner-sec-lft+.col-lg-6 {
                display: none;
            }

            .banner-sec-rt {
                display: none;
            }
        }
    }


    // -----------------------End-----------------------

    // ====================How pricing Works=======================
    .how-pricing-work {
        .custm-card {
            height: 380px;
            padding: 25px 30px;

            h3 {
                font-size: 20px;
            }

            ul {
                li {
                    font-size: 15px;
                }
            }
        }
    }

    // -----------------------End-----------------------


    // =======================See Budget Action====================
    .see-budget-action {
        .custm-bud-text {
            p {
                font-size: 18px;
            }

            li {
                font-size: 18px;
            }
        }
    }

    // -----------------------End-----------------------
}


@media only screen and (min-width:200px) and (max-width:767px) {

    // ====================Banner=======================
    .transparent-pricing-banner {
        .banner-area {
            height: 280px;

            .banner-text {
                left: auto !important;
                transform: none !important;
                width: auto !important;
                top: 15% !important;

                h1 {
                    font-size: 20px !important;
                }

                p {
                    font-size: 15px !important;
                    margin: 0 0 15px 0 !important;
                }

                a {
                    padding: 7px 25px !important;
                    font-size: 16px !important;
                }
            }

            .banner-sec-lft {
                &::before {
                    clip-path: unset;
                    width: 100%;
                }
            }

            .banner-sec-lft+.col-lg-6 {
                display: none;
            }

            .banner-sec-rt {
                display: none;
            }
        }
    }

    // -----------------------End-----------------------

    // ====================How pricing Works=======================
    .how-pricing-work {
        padding-bottom: 0px !important;

        h1 {
            color: #1B375C;
            text-align: center;
            font-size: 30px;
            font-weight: 700;
            text-transform: capitalize;
        }

        .custm-card {
            height: auto;
            padding: 30px 24px;
            margin: 10px 0px;
            border-radius: 0px;

            h3 {
                font-size: 18px;
                font-weight: 600;
            }

            ul {
                li {
                    font-size: 16px;
                }
            }
        }
    }

    .pay-sponsor {
        h1 {
            color: #1B375C;
            text-align: center;
            font-size: 30px;
            font-weight: 700;
            text-transform: capitalize;
        }
    }

    .see-budget-action {
        padding-top: 10px !important;
        padding-bottom: 10px !important;

        .head-area {
            margin-bottom: 10px !important;

            h1 {
                color: #1B375C;
                text-align: center;
                font-size: 30px;
                font-weight: 700;
                text-transform: capitalize;
            }
        }

        .custm-bud-text {
            li {
                color: #1E1E1E;
                text-align: justify;
                font-size: 14px;
            }

            h4 {
                text-align: left;
                color: #1B375C;
                font-size: 19px;
                font-weight: 700;
                padding: 0px 0 0 0;
            }
        }
    }

    .more-solution {
        .ux {
            h3 {
                color: #1B375C;
                font-size: 22px;
                font-weight: 600;
                margin: 0 0 30px 0;
                padding: 0 25px 0 0;
            }
        }

        h1 {
            color: #1B375C;
            text-align: center;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
        }
    }

    // -----------------------End-----------------------
}