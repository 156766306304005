.modal-chat {
  width: 350px;
  height: 500px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  max-width: 100%;
  margin: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
}
.modal-chat .modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  border: none;
}
.modal-chat .modal-header {
  flex-shrink: 0;
  background-color: #00D0EA;
  color: white;
  padding: 10px;
  border-bottom: none;
}
.modal-chat .modal-header .btn-close {
  color: white;
  opacity: 1;
}
.modal-chat .modal-header .btn-close:hover {
  color: #1b375c;
}
.modal-chat .modal-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: #f9f9f9;
}
.modal-chat .modal-body .chat-progress {
  font-family: helvetica;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-chat .modal-body .chat-progress .chat {
  width: 100%;
  border: solid 1px #EEE;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.modal-chat .modal-body .chat-progress .messages {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.modal-chat .modal-body .chat-progress .message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
  word-break: break-all;
}
.modal-chat .modal-body .chat-progress .yours {
  align-items: flex-start;
}
.modal-chat .modal-body .chat-progress .yours .message {
  margin-right: 25%;
  background-color: #eee;
  position: relative;
  word-break: break-all;
}
.modal-chat .modal-body .chat-progress .yours .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #eee;
  border-bottom-right-radius: 15px;
}
.modal-chat .modal-body .chat-progress .yours .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}
.modal-chat .modal-body .chat-progress .mine {
  align-items: flex-end;
}
.modal-chat .modal-body .chat-progress .mine .message {
  color: white;
  margin-left: 25%;
  background: linear-gradient(to bottom, #00D0EA 0%, #1b375c 100%);
  background-attachment: fixed;
  position: relative;
}
.modal-chat .modal-body .chat-progress .mine .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: linear-gradient(to bottom, hsl(187, 100%, 46%) 0%, #1b375c 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}
.modal-chat .modal-body .chat-progress .mine .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}
.modal-chat .custom-footer {
  flex-shrink: 0;
  padding: 10px;
  background-color: white;
  border-top: 1px solid #e5e5e5;
}
.modal-chat .custom-footer .chat-input {
  border-color: #2557a7;
  box-shadow: inset 0 -3px 0 0 #2557a7;
}
.modal-chat .custom-footer .form-control {
  width: calc(100% - 50px);
  display: inline-block;
}
.modal-chat .custom-footer .btn {
  padding: 5px 10px;
  margin-left: 5px;
}

@media (max-width: 767px) {
  .modal-chat {
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    border-radius: 0;
  }
}/*# sourceMappingURL=Chat.css.map */