.before-footer-play-sec {
    .second-bg-section {
        background: #1DB5BE;
    }

    .second-bg-head {
        h2 {
            color: #FFF;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            text-align: left;
        }

        p {
            color: #111827;
            text-align: justify;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            word-spacing: -1px;
        }

    }

    .mark-top {

        // margin-top: 120px;
        .get-the-app-heading {
            h4 {
                text-align: left;
            }
        }

        .play-app-stor-btns {
            float: left;

            .goole-play-img {
                img {
                    cursor: pointer;
                }
            }
            .app-store-dv {
                img {
                    cursor: pointer;
                }
            }
        }
    }
}

@media only screen and (min-width:768px) and (max-width:992px) {}

@media only screen and (min-width:200px) and (max-width:767px) {
    .before-footer-play-sec {
        .second-bg-section {
            background: #1DB5BE;
        }

        .second-bg-head {
            h2 {
                color: #FFF;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                text-align: left;
            }

            p {
                color: #111827;
                text-align: justify;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                word-break: break-all;
                word-spacing: -2px;
            }

        }

    }
}