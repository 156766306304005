.particular-company-homepage {

    /*===================================== banner-section-start-css========================= */
    .banner-section {
        .banner-img-custom {
            position: relative;
            height: 100vh;

            img.img-fluid.banner-img-media {
                height: 100%;
                width: 100%;
            }

            .banner-heading-text {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
            }
        }

    }

    .check-dv {
        p {
            margin-bottom: 0px;
        }
    }

    .round-input {
        label {
            margin-left: 10px;
        }
    }

    .hear-about-us {
        p {
            margin-bottom: 0px;
        }
    }

    .banner-heading h2 {
        color: #FFF;
        text-align: center;
        word-spacing: 6px;
        font-size: 45px;

        font-weight: 700;

    }

    .banner-heading p {
        color: #FFF;
        text-align: center;
        font-size: 28px;

        font-weight: 400;


    }

    .banner-btn-custom {
        border-radius: 10px;
        background: #0093FF;
        color: #FFF;
        font-size: 18px;
        font-weight: 700;
        padding: 19px 40px;
        border: none;


    }

    .banner-btn-second-custom {
        border-radius: 10px;
        border: 1px solid #FFF;
        padding: 19px 40px;
        color: #FFF;
        font-size: 18px;
        font-weight: 700;
        background: #443f3f14;

    }

    .banner-btn {
        text-align: center;

        .custm-scroll {
            p {
                color: #FFF;
                font-size: 18px;
                font-weight: 600;
                margin: 0 0 5px 0;
            }
        }
    }

    // .banner-img-custom:after {
    //     content: '';
    //     position: absolute;
    //     background-color: rgba(0, 0, 0, 0.35);
    //     width: 100%;
    //     height: 100%;
    //     top: 0;
    //     left: 0;
    // }

    /*======================================== About-section-start-Css============================ */
    .about-first-heading h3 {
        color: #0093FF;
        font-size: 30px;
        font-weight: 400;
    }


    .about-second-heading h2 {
        color: #303A42;
        font-size: 35px;
        font-weight: 700;
    }

    .about-second-heading p {
        color: #303A42;
        font-size: 17px;
        font-weight: 400;
        margin-top: 26px;
    }

    .about-section {
        .abt-ratng {
            position: relative;

            img:nth-child(1) {
                position: absolute;
                top: 108px;
                left: -65px;
            }
        }
    }

    /*=====================================Featured-section-start-css====================================*/
    .Featured-first-heading h2 {
        color: #303A42;
        text-align: center;
        font-size: 35px;
        font-weight: 700;
    }

    .featured-cient-logo-section {
        display: flex;
        justify-content: space-evenly;
        border: 1px solid rgb(203, 204, 204);
        padding: 16px 0px;
        margin-top: 20px;


    }

    /*=================================== card-section-start-css==================================== */
    .card-first-heading h3 {
        color: #0093FF;
        text-align: center;
        font-size: 30px;
        font-weight: 500;
        margin-top: 80px;
    }

    .card-second-heading h2 {
        color: #303A42;
        text-align: center;
        font-size: 35px;
        font-weight: 700;
    }

    .card-second-heading p {
        color: rgba(48, 58, 66, 0.80);
        // text-align: center;
        font-size: 18px;
        font-weight: 400;
        text-align: justify;
    }

    .card-third-heading h5 {
        color: #303A42;
        text-align: center;
        font-size: 22px;
        font-weight: 600;
        margin-top: 14px;
    }

    .card-para p {
        color: rgba(48, 58, 66, 0.80);
        font-size: 18px;
        text-align: center;
        font-weight: 400;
        margin-top: 14px;
    }

    .card-image-section {
        border: 1px solid #e5e5e5;
        border-radius: 27px;
        width: 55px;
        height: 55px;
        background: #daf1ff;
        margin: 0 auto;
        margin-top: -31px;
        line-height: 50px;
        text-align: center;
    }

    .card-image-section img {
        margin: 8px;
    }

    .card-custom {
        padding: 6px;
        background: #FFF;
        border: none;
        -webkit-box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.51);
        -moz-box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.51);
        box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.51);

    }

    .card-background {
        background: rgb(210, 236, 255);
        background: radial-gradient(circle, rgba(210, 236, 255, 0.8323704481792717) 17%, rgba(255, 255, 255, 1) 48%, rgba(255, 255, 255, 1) 100%);
    }

    .card-btn-custom {
        display: flex;
        justify-content: center;
    }

    .card-first-btn button {
        border-radius: 10px;
        background: #0093FF;
        color: #FFF;
        padding: 19px 40px;
        font-size: 16px;
        border: none;
        font-weight: 700;
    }

    .card-btn-second button {
        border-radius: 10px;
        border: 1px solid #0093FF;
        padding: 19px 40px;
        color: #0093FF;
        font-size: 16px;
        font-weight: 700;
        background-color: #fff;
    }

    /* ======================================form-section-start-css ===============================================================*/
    .form-first-heading h2 {
        color: #303A42;
        font-size: 30px;
        font-weight: 700;
    }

    .form-para p {
        color: #303A42;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .form-label-custom {
        color: #303A42;
        font-size: 16px;
        font-weight: 400;
    }

    .form-custom {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.04);
        padding: 15px;
        border: none;
    }

    .form-btn-custom button {
        border-radius: 10px;
        background: #0093FF;
        color: #FFF;
        border: none;
        font-size: 18px;
        padding: 12px;
        width: 100%;
        font-weight: 700;
        text-transform: capitalize;
    }


    .footer-section-image {
        position: relative;
    }

    .footer-section-heading {
        position: absolute;
        top: 37%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
    }

    .footer-section-heading h2 {
        color: #FFF;
        font-size: 40px;
        font-weight: 700;
    }

    .footer-section-heading button {
        border-radius: 10px;
        border: 1px solid #FFF;
        padding: 15px 30px;
        color: #FFF;
        background-color: #0093FF;
        font-size: 18px;
        font-weight: 700;
    }

}




/*================================================ media query=================================== */
@media only screen and (min-width:200px) and (max-width:767px) {
    .particular-company-homepage {
        .card-btn-custom {
            display: flex;
            justify-content: center;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: center;
            align-items: center;

            .card-first-btn,
            .card-btn-second {
                width: 100%;
            }

            .card-first-btn button,
            .card-btn-second button {
                width: 100%;
                padding: 15px 0px;
                margin: 10px 0px;
            }
        }

        /*============ banner-Qmedia================ */
        .banner-heading h2 {
            font-size: 20px !important;
        }

        .banner-heading p {
            font-size: 16px;
        }

        .banner-img-media {
            max-height: 727px !important;
        }

        .banner-btn-second-custom {
            font-size: 10px;

        }

        .banner-btn-custom {
            font-size: 13px;
            margin: auto;
        }

        .featured-cient-logo-section {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
        }

        .Featured-icon {
            padding: 10px;
        }

        // .banner-heading-text {
        //     height: 100%;
        // }

        .banner-btn {
            text-align: center;
            display: flex;
            margin-top: unset !important;
        }

        .banner-btn-second-custom {


            padding: 1px;


            font-weight: 400;

        }

        .banner-btn-custom {

            font-weight: 400;
            padding: 6px;

        }

        .card-first-btn button {
            font-size: 11px;
        }


        .card-custom {
            margin-top: 35px;
        }

        .card-btn-second button {


            padding: 11px 22px;
            font-size: 11px;
            font-weight: 600;
        }

        .form-first-heading h2 {

            font-size: 20px;

        }

        .card-second-heading h2 {
            font-size: 24px;
            text-align: left;
        }

        .about-second-heading h2 {
            font-size: 22px;
        }

        .footer-section-heading button {
            font-size: 11px;
            padding: 8px 22px;
        }

        .footer-section-heading h2 {

            font-size: 0px;

        }
    }

}

@media only screen and (min-width:768px) and (max-width:992px) {
    .particular-company-homepage {
        .banner-heading {
            h2 {
                color: #FFF;
                text-align: center;
                word-spacing: 6px;
                font-size: 32px;
                font-weight: 700;
            }
        }

        .banner-heading {
            p {
                font-size: 22px;
            }
        }

        .card-custom {
            margin-top: 40px;
        }

        .banner-btn-custom {
            border-radius: 10px;
            background: #0093FF;
            color: #FFF;
            font-size: 15px;
            font-weight: 700;
            padding: 16px 40px;
            border: 1px solid transparent;
        }

        .banner-btn-second-custom {
            border-radius: 10px;
            border: 1px solid #FFF;
            padding: 15px 40px;
            color: #FFF;
            font-size: 15px;
            font-weight: 700;
            background: rgba(68, 63, 63, 0.0784313725);
        }
    }
}