.rec-my-subscription-plan .main-subs-plan-2 .cus-head {
  width: 100%;
  height: 65px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #F4FAFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(29, 95, 181, 0.2) inset;
}
.rec-my-subscription-plan .main-subs-plan-2 .cus-head h5 {
  color: #1B375C;
  font-family: Barlow;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  text-transform: capitalize;
  padding: 20px 12px 12px 12px;
}
.rec-my-subscription-plan .main-subs-plan-2 .subscrip-card {
  border-radius: 4px;
  padding: 20px 20px 30px 20px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .cus-btn {
  display: flex;
  padding: 27px 29px;
  justify-content: end;
  align-items: end;
}
.rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .cus-btn button {
  border-radius: 10px;
  background: #1B375C;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  line-height: normal;
  padding: 10px 40px;
}
.rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .sub-inner-sec {
  border-radius: 10px;
  margin: 0 20px;
  border: 1px solid #B7D6FF;
  background: #FFF;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.08);
  padding: 33px 44px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
  flex: 1 0 0;
}
.rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .cus-par-1 {
  color: #1B375C;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  /* 150% */
}
.rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .cus-spa-2 {
  color: #1B375C;
  text-align: right;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  /* 150% */
}
.rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .cus-spa-2 span {
  color: #FE6108;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .cus-p {
  color: #1B375C;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  /* 127.778% */
  margin-bottom: 0px;
}
.rec-my-subscription-plan .main-subs-plan-2 .subscrip-card h4 {
  color: #1B375C;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
  margin-top: 3px;
}
.rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .inner-p {
  color: #1B375C;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.36px;
  margin-bottom: 0px;
}
.rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .cus-list li {
  color: #1B375C;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.36px;
}
.rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .cus-list li.right:before {
  padding-right: 8px;
  content: url("../../../../../public/assets/images_another-ak/Icon-4.png");
}
.rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .cus-list li.cross:before {
  padding-right: 8px;
  content: url("../../../../../public/assets/images_another-ak/cross.png");
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .rec-my-subscription-plan .main-subs-plan-2 .subscrip-card {
    padding: 20px 8px 10px 0px;
  }
  .rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .cus-p {
    font-size: 14px;
  }
  .rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .sub-inner-sec {
    padding: 1px 11px;
  }
  .rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .sub-inner-sec .d-flex {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start !important;
    text-align: left;
  }
  .rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .sub-inner-sec .d-flex p {
    font-weight: 700;
    font-size: 20px;
  }
  .rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .cus-btn {
    padding: 12px 25px;
  }
  .rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .cus-btn button {
    border-radius: 10px;
    background: #1B375C;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    line-height: normal;
    padding: 5px 15px;
  }
  .rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .cus-par-1 {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 0px;
  }
  .rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .cus-spa-2 {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 0px;
  }
  .rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .cus-spa-2 span {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 0px;
  }
  .rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .inner-p {
    font-size: 16px;
  }
  .rec-my-subscription-plan .main-subs-plan-2 .subscrip-card .cus-list li {
    font-size: 14px;
  }
}/*# sourceMappingURL=RecMySubsCriptionPlan.css.map */