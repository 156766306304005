@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .impaneled-companies .company-slider {
    width: 225px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-banner .carousel .carousel-inner .carousel-item img {
    width: 100%;
    filter: brightness(0.7);
    height: 400px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .common-header-sec .header-second nav {
    z-index: 1;
  }
  .header-banner .banner-text h1 {
    font-size: 30px;
  }
  .header-banner .banner-text {
    transform: translate(-50%, -55%);
  }
  .header-banner .banner-text button {
    padding: 10px 15px;
    font-size: 13px;
  }
  .bannerafter-header .achievements-lft h1 {
    font-size: 30px;
    text-align: center;
  }
  .bannerafter-header .achievements-lft h1 br {
    display: none;
  }
  .bannerafter-header .achievements-lft p {
    text-align: center;
  }
  .achievements-rit .counter-txt h2 {
    font-size: 22px;
  }
  .job-demand .job-box2 {
    margin-bottom: 10px;
  }
  .job-demand .job-box .company .comp-logo-65p {
    max-width: 65px;
  }
  .job-demand .job-box .company .company-txt .line-clamp-comp-name {
    max-width: 150px;
  }
  .work-location h1 {
    font-size: 30px;
  }
  .job-opening h1 {
    font-size: 30px;
  }
  .job-opening .MuiTabs-flexContainer .MuiButtonBase-root {
    font-size: 15px;
  }
  .benifits .key-benifits .heading-txt h1 {
    font-size: 30px;
  }
  .job-demand h1 {
    font-size: 30px;
  }
  .impaneled-companies h1 {
    font-size: 30px;
  }
  .impaneled-companies .company-slider {
    width: 210px !important;
    border-radius: 20px;
    background: #FFF;
    padding: 0 10px;
    box-shadow: 5px 5px 13px 0px rgba(224, 224, 224, 0.5);
    margin: 0px 7px;
  }
  .job-fair h1 {
    font-size: 30px;
  }
  .testimonial h1 {
    font-size: 30px;
  }
  .heading-txt {
    margin: 10px 0 !important;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .job-opening .latest-job-card-two {
    height: 300px;
  }
  .job-opening .latest-job-card-two .company-group {
    margin-bottom: 0px;
    flex-direction: column;
    display: flex;
    align-content: center;
    align-items: center;
  }
  .header-banner .carousel .carousel-inner .carousel-item img {
    width: 100%;
    filter: brightness(0.7);
    height: 200px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .job-opening .css-1ujnqem-MuiTabs-root {
    min-height: 48px;
    -webkit-overflow-scrolling: touch;
  }
  .common-header-sec .main-header .row > .col-lg-6 {
    justify-content: flex-end;
    display: flex;
  }
  .common-header-sec .main-header .head-sec-lft p {
    font-size: 12px;
  }
  .common-header-sec .main-header .head-sec-lft {
    align-items: center;
    justify-content: start;
    padding-left: 20px !important;
    padding: 0 0 5px;
  }
  .common-header-sec .header-second nav {
    z-index: 1;
  }
  .header-banner .banner-text {
    transform: translate(-50%, -50%);
    top: 50%;
  }
  .header-banner .banner-text .find-job-btn {
    padding: 5px 15px;
    font-size: 12px;
  }
  .header-banner .banner-text h1 {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .header-banner .banner-text button {
    width: 125px;
    height: 35px;
    font-size: 10px;
    padding: 0;
  }
  .footer-parent-sec .ft-rt-dv .inner-img-dv {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .bannerafter-header .achievements-lft h1 {
    font-size: 22px;
    line-height: 38px;
    text-align: center;
  }
  .bannerafter-header .achievements-lft h1 br {
    display: none;
  }
  .bannerafter-header .achievements-lft p {
    text-align: center;
  }
  .bannerafter-header {
    padding: 20px 5px;
  }
  .achievements-rit {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
  }
  .achievements-rit .counts {
    justify-content: flex-start;
  }
  .achievements-rit .counter-img img {
    padding: 0px;
  }
  .achievements-rit .counter-txt h2 {
    font-size: 16px;
    margin-bottom: 0px;
  }
  .achievements-rit .counter-txt p {
    margin-bottom: 0px;
  }
  .work-location h1 {
    font-size: 30px;
  }
  .work-location .heading-txt {
    margin: 0px 0 !important;
  }
  .work-location .slick-slider .slick-next {
    right: 11px;
  }
  .work-location .slick-slider .slick-prev {
    left: 11px;
  }
  .benifits .key-benifits {
    padding: 20px;
  }
  .benifits .key-benifits .heading-txt h1 {
    font-size: 30px;
  }
  .job-demand h1 {
    font-size: 30px;
  }
  .job-demand .job-box h4 {
    font-size: 18px;
  }
  .job-demand .job-box .company button {
    font-size: 14px;
  }
  .job-demand .job-box .company .company-txt {
    margin: 0 0 0 10px !important;
  }
  .job-demand .job-box .company .comp-logo-65p {
    max-width: 65px;
  }
  .job-demand .job-box .company .company-txt .line-clamp-comp-name {
    max-width: 65px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .job-demand .job-box .job-type p {
    margin: 0 15px 0 5px !important;
  }
  .impaneled-companies h1 {
    font-size: 30px;
  }
  .impaneled-companies .company-slider {
    width: 90% !important;
    border-radius: 20px;
    background: #FFF;
    padding: 0 10px;
    box-shadow: 5px 5px 13px 0px rgba(224, 224, 224, 0.5);
  }
  .job-fair h1 {
    font-size: 30px;
  }
  .job-fair .software .intro h4 {
    font-size: 16px;
  }
  .testimonial h1 {
    font-size: 30px;
  }
  .job-opening h1 {
    font-size: 30px;
  }
  .job-opening .MuiTabs-flexContainer .MuiButtonBase-root {
    margin: 0;
    font-size: 15px;
  }
  .job-fair .software {
    margin-bottom: 15px;
  }
}/*# sourceMappingURL=homeresponsive.css.map */