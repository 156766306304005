.findjoblisting-first-sec {
  margin-top: 30px;
  .pill-tabs-dv {
    background: rgba(244, 250, 255, 1);
    box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
    box-shadow: 0px 4px 20px 0px rgba(29, 95, 181, 0.2) inset;
    padding: 30px;

    ul.nav-pills {
      border: 1px solid rgba(28, 28, 30, 0.12);
      border-radius: 8px;
      padding: 4px;
      .nav-item {
        .nav-link {
          color: rgb(27, 55, 92);
          font-weight: 600;
        }
        .nav-link.active {
          background: rgb(27, 55, 92);
          color: #fff;
        }
      }
    }
  }
}
.findjoblisting-second-sec {
  .after-find-job-listing {
    background: rgba(255, 255, 255, 1);
    padding: 30px;
    box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
    box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  }
}
// .pagination-section{
//     text-align: center;
//     margin: 20px 0px;
//     nav.nav-class{
//         ul.pagination{
//             justify-content: center;
//         }
//     }
// }

.pagination-section {
  .pagination {
    gap: 0;
    .next {
      .page-num {
        border-radius: 0 0.375rem 0.375rem 0;
      }
    }
    .previous {
      .page-num {
        border-radius: 0.375rem 0 0 0.375rem;
      }
    }
    .active {
      .page-num {
        background-color: #1b375c;
        color: white;
      }
    }
    li {
      .page-num {
        border: 1px solid #dee2e6;
      }
      &:hover {
        .page-num {
          background-color: #1b375c;
          color: white;
        }
      }
    }
  }
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .findjoblisting-second-sec {
    .after-find-job-listing {
      background: rgb(255, 255, 255);
      padding: 0px;
      box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
      box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
    }
    .job-heading {
      margin-top: 10px;
    }
    .pagination-section {
      text-align: center;
      margin: 20px 0px;
      padding: 10px 0px;
      nav.nav-class ul.pagination {
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
      }

      
    }
  }
}


