@media only screen and (min-width: 568px) and (max-width: 992px) {
  .blog-list .blog-info .blog-card .date h5 {
    padding: 5px 10px;
  }
  .blog-list .blog-info .blog-card .blog-title h2 {
    font-size: 20px;
  }
  .blog-list .blog-info .blog-card .blog-para p {
    font-size: 14px;
  }
  .blog-list .blog-info .blog-card .blog-para2 p {
    font-size: 14px;
  }
  .blog-list .blog-info .blog-card .blog-para3 p {
    font-size: 15px;
    line-height: 35px;
  }
  .blog-list .blog-info .blog-card .read-btn a {
    font-size: 16px;
  }
  .blog-list .blog-info .blog-media .textarea p {
    font-size: 14px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 567px) {
  .blog-list .blog-info .blog-card .blog-title h2 {
    font-size: 20px;
  }
  .blog-list .blog-info .blog-card .blog-category {
    display: block !important;
  }
  .blog-list .blog-info .blog-card .blog-category .date {
    margin: 10px 0 !important;
  }
  .blog-list .blog-info .blog-card .blog-category .share-btn {
    margin-top: 1rem;
  }
  .blog-list .blog-info .blog-card .blog-para p {
    font-size: 14px;
  }
  .blog-list .blog-info .blog-card .blog-para2 p {
    font-size: 14px;
  }
  .blog-list .blog-info .blog-card .blog-para3 p {
    font-size: 15px;
    line-height: 35px;
  }
  .blog-list .blog-info .blog-card .read-btn a {
    font-size: 16px;
  }
  .blog-list .blog-info .blog-media .textarea p {
    font-size: 14px;
  }
}/*# sourceMappingURL=blog-responsive.css.map */