.interview-experience{
  .h-vh{
    height: 100vh;
  }
    background-color: rgb(243, 242, 241);
    h1{
        color: rgb(45, 45, 45);
        font-weight: 700;
        font-size: 22px;
        line-height: 1.25;
    }
    p{
        font-size: 16px;
        font-weight: 400;
        color: rgb(89, 89, 89);

    }
}