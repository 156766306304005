.common-header-sec {
    .main-header {
        background: #1B375C;
        padding: 10px 0;
        display: flex;
        align-items: center;

        .head-sec-lft {
            display: flex;
            align-items: center;

            .call-info {
                a {
                    text-decoration: none;
                }
            }

            i {
                color: #fff;
            }

            p {
                color: #fff;
                margin-bottom: unset;
            }

            .translator {
                ul {
                    margin-bottom: unset;
                    list-style: none;
                    padding-left: 0;
                    color: #fff;
                }
            }
        }

        .head-sec-rit {
            display: flex;
            align-items: center;
            justify-content: end;

            .login-signup-new-parent-div {
                a {
                    background: #fff;
                    padding: 8px 15px;
                    border-radius: 40px;
                    text-decoration: none;
                    color: #000;
                    margin: 0px 5px;
                    border: 1px solid #fff;
                }

                a.signin-class {
                    background: transparent;
                    border: 1px solid #fff;
                    color: #fff;
                }
            }

            .top-rit-btn {
                display: flex;
                align-items: center;

                h3 {
                    color: #fff;
                    font-size: 16px;
                    margin: 0;
                }
            }

            #dropdownMenuButton1 {
                border: unset;
                position: relative;
            }

            .btn-lft button {
                background-color: unset;
                border: 1px solid #fff;
                border-radius: 50px;
                padding: 5px 20px;
            }

            .btn-rit button {
                background-color: #fff;
                color: #000;
                border-radius: 50px;
                padding: 5px 20px;
            }


            .dropdown-toggle {
                color: #fff;
            }

            .dropdown-menu li {
                display: flex;
                align-items: center;
                padding: 5px;
            }
        }
    }

    .header-second {
        background: #FFF;
        // height: 100px;
        display: flex;
        align-items: center;
        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);

        .navbar-collapse {
            animation: slide-in 0.5s;
            transition-timing-function: ease-in;
        }

        .collapse.navbar-collapse {
            animation: slide-in1 0.5ss;
            transition-timing-function: ease-in;
        }

        @keyframes slide-in {
            from {
                transform: translatey(-100%);
                margin-top: -100%;
            }

            to {
                transform: translatey(0%);
                margin-top: 0%;
            }
        }

        @keyframes slide-in1 {
            from {
                transform: translatey(0%);
                margin-top: 0%;
            }

            to {

                transform: translatey(-100%);
                margin-top: -100%;
            }
        }

        nav {
            background-color: #fff;
        }

        .dropdown-menu li {
            display: flex;
            align-items: center;
            padding: 5px;
        }

        .navbar-nav li {
            margin: 0 0px;

            a {
                font-weight: 600;
                color: #000;
                font-size: 15px;
            }
        }

        .navbar-brand {
            color: #1B375C;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            /* 100% */
            text-transform: uppercase;

            img {
                width: 180px;
                height: 34px;
                object-fit: cover;
            }
        }

        .header-sec-btn1 {
            .last-two-btn1 {
                background-color: unset;
                border: 1px solid #0A65CC;
                color: #0A65CC;
                border-radius: 50px;
                font-size: 14px;
                // padding: 12px 25px;
                padding: 12px 12px; //Done by Kaustubh
                text-decoration: none;

                &:hover {
                    background-color: #0A65CC;
                    color: white;
                }
            }
        }

        .header-sec-btn2 {
            .last-two-btn2 {
                background-color: #0A65CC;
                color: #fff;
                border-radius: 50px;
                font-size: 14px;
                padding: 12px 20px;
                text-decoration: none;
            }
        }

    }
}


// =============Footer Area==============

.footer-parent-sec {
    background: rgba(27, 55, 92, 1);
    padding: 85px 0px 30px 0px;

    .ft-left-dv {
        text-align: left;

        .ft-left-logo {
            width: 180px;
            height: 34px;
            object-fit: cover;
            background-color: #fff;

        }

        .ft-after-logo {

            .ft-a-tag-last {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                // justify-content: space-between;
                align-items: center;
                margin-top: 20px;

                a {
                    margin-right: 15px;
                }
            }

            .ft-a-tag {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                align-items: center;
                padding: 10px 0px;

                a {
                    color: #fff;
                    text-decoration: none;
                    margin-right: 20px;
                }

                border-bottom: 1px solid rgba(255, 255, 255, 1);
            }

            .ft-a-tag-2 {
                padding: 10px 0px;
                text-align: left;

                span {
                    color: #fff;
                }

                a {
                    color: #fff;
                    text-decoration: none;
                }

                border-bottom: 1px solid rgba(255, 255, 255, 1)
            }

            .border-bottom-none {
                border-bottom: none;
            }
        }
    }

    .ft-rt-dv {
        border: 1px solid rgb(255, 255, 255);
        height: 292px;
        border-radius: 8px;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 0px 40px;

        .ft-input {
            width: 100%;
            // padding: 0px 40px;
            position: relative;

            input {
                width: 100%;
                border-radius: 5px;
                outline: none;
                box-shadow: none;
                border: none;
                padding: 0px;
                // padding-bottom: 2px;
                border: 0.5px solid rgba(183, 183, 183, 1);
                height: 100%;

                &::placeholder {
                    padding: 0 12px;
                    color: #9b9b9b;
                    font-size: 12px;
                }
            }

            button {
                position: absolute;
                right: 0;
                top: 0;
                background: linear-gradient(274.05deg, #FF354D -37.86%, #FE6108 125.4%);
                box-shadow: 0px 1px 12px 0px rgba(254, 97, 8, 0);
                border-radius: 5px;
                border: none;
                padding: 6px 18px;
                color: #FFF;
                text-align: center;
                font-size: 9px;
                font-weight: 700;
                text-transform: uppercase;
                height: 100%;
            }
        }

        // .inner-img-dv {
        //     display: flex;
        //     flex-direction: row;
        //     flex-wrap: nowrap;
        //     align-content: center;
        //     justify-content: space-between;
        //     align-items: center;
        // }
    }

    .right-img-dv {
        text-align: left;

        h4 {
            margin-bottom: 0px;
            color: #fff;
            font-size: 12px;
            text-transform: uppercase;
        }

        p {
            margin-bottom: 0px;
            color: #fff;
            font-size: 12px;
        }

        h3 {
            font-size: 28px;
            color: #FE6108;
            text-transform: uppercase;
        }
    }
}

.bottom-st-section {
    .bottom-strip-head {
        .bottom-strip {

            background: rgba(0, 0, 0, 1);
            padding: 15px 0px;
            text-align: center;

            p {
                margin-bottom: 0px;
                color: rgba(209, 213, 219, 1);
            }

            .right-policies {
                a {
                    text-decoration: none;
                    color: #fff;

                    &:nth-child(1) {
                        margin-right: 20px;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:992px) and (max-width:1200px) {
    .common-header-sec {
        .header-second {

            .navbar-nav li {
                margin: 0 0px;

                a {
                    font-weight: 600;
                    color: #000;
                    font-size: 13px;
                }
            }

            .header-sec-btn1 {
                .last-two-btn1 {
                    background-color: unset;
                    border: 1px solid #0A65CC;
                    color: #0A65CC;
                    border-radius: 50px;
                    font-size: 12px;
                    padding: 12px 5px;
                    text-decoration: none;
                }
            }

            .header-sec-btn2 {
                .last-two-btn2 {
                    background-color: #0A65CC;
                    color: #fff;
                    border-radius: 50px;
                    font-size: 12px;
                    padding: 12px 2px;
                    text-decoration: none;
                    font-weight: 500;
                }
            }
        }
    }
}

@media only screen and (min-width:993px) and (max-width:1080px) {
    .common-header-sec {
        .header-second {
            .custom-container {
                padding-left: 0px;
                padding-right: 0px;

                .custom-fluid-container {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
        }
    }
}

@media only screen and (min-width:768px) and (max-width:992px) {
    .common-header-sec {
        .header-second {
            .navbar-nav {
                display: flex;
                flex-direction: row;
                justify-content: center;
                flex-wrap: nowrap;
                align-items: center;
            }

            .custom-container {
                padding-left: 0px;
                padding-right: 0px;

                .custom-fluid-container {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
        }
    }

    .footer-parent-sec {
        .align-items-center-md {
            align-items: center;
        }

        .ft-left-dv {
            .ft-after-logo {
                .ft-a-tag-last {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: center;
                    align-items: center;
                    margin: 10px 0px 20px 0px;
                }
            }
        }

        .right-img-dv {
            text-align: center;
        }

        .ft-rt-dv {
            height: 340px;

            .inner-img-dv {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0px !important;

                .inner-img {
                    width: 120px;

                    img {
                        width: 100%;
                    }
                }
            }

            .ft-input {
                margin-top: 10px;

                input {

                    padding: 5px 100px 5px 5px;
                    height: 100%;
                }

                button {
                    padding: 11px 10px;
                    color: #fff;
                    height: 100%;
                }
            }
        }

    }

    .common-header-sec {
        .header-second {

            .navbar-nav li {
                margin: 0 4px;

                a {
                    font-weight: 600;
                    color: #000;
                    font-size: 13px;
                }

                ul.dropdown-menu {
                    position: absolute;
                }
            }

            .header-sec-btn1 {
                .last-two-btn1 {
                    background-color: unset;
                    border: 1px solid #0A65CC;
                    color: #0A65CC;
                    border-radius: 50px;
                    font-size: 12px;
                    padding: 12px 5px;
                    text-decoration: none;
                }
            }

            .header-sec-btn2 {
                .last-two-btn2 {
                    background-color: #0A65CC;
                    color: #fff;
                    border-radius: 50px;
                    font-size: 12px;
                    padding: 12px 5px;
                    text-decoration: none;
                }
            }

            .navbar-nav {
                li {
                    a {
                        font-size: 12px;
                        font-weight: 500;
                    }
                }

                li.header-sec-btn1 {
                    // width: 120px;

                    a.last-two-btn1 {
                        padding: 12px 10px;
                        font-size: 13px;
                    }
                }

                li.header-sec-btn2 {
                    // width: 200px;

                    a.last-two-btn2 {
                        padding: 12px 10px;
                        font-size: 13px;
                    }
                }
            }
        }
    }

}

@media only screen and (min-width:200px) and (max-width:767px) {

    .common-header-sec {

        .main-header {

            .head-sec-rit {
                .top-rit-btn {
                    display: flex;
                    align-items: center;
                    // margin-right: 100px;
                }

                       margin: 0;
        align-items: center;
        justify-content: space-around;
        display: flex;

                .login-signup-new-parent-div {
                    a {
                        background: #fff;
                        padding: 5px;
                        border-radius: 10px;
                        text-decoration: none;
                        color: #000;
                        margin: 0px 5px;
                        font-size: 8px;

                    }
                }

                .top-rit-btn {
                    h3 {
                        width: 200px;
                        font-size: 13px;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-align: right;
                    }
                }

                .btn-lft {
                    button {
                        padding: 3px 15px;
                        font-size: 15px;
                    }
                }

                .btn-rit {
                    button {
                        padding: 3px 15px;
                        font-size: 15px;
                    }
                }
            }
        }

        .header-second {
            display: block;
        }
    }

    .footer-parent-sec {
        .ft-left-dv {
            .ft-after-logo {
                .ft-a-tag-last {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: center;
                    align-items: center;
                    margin: 10px 0px 20px 0px;
                }
            }
        }

        .right-img-dv {
            text-align: center;
        }

        .ft-rt-dv {
            height: 340px;

            .inner-img-dv {
                margin: unset !important;
            }

            .ft-input {
                margin-top: 10px;

                input {

                    padding: 0px 100px 0px 5px;
                }

                button {
                    padding: 6px 10px;
                    color: #fff;
                }
            }
        }

    }
   
    .common-header-sec .header-second .header-sec-btn1 .last-two-btn1 {
        border-radius: 10px !important;
        padding: 6px 12px !important;
        margin-left: -10px;
    }
    .common-header-sec .header-second .header-sec-btn2 .last-two-btn2{
        border-radius: 10px;
        padding: 5px 20px;
        margin-left: -10px;
    }
    .common-header-sec .header-second .navbar-brand {
        margin-left: 0 !important;
    }
    .common-header-sec .header-second .navbar-collapse ul{
        background: #fff;
    }
    .navbar-toggler:focus {
        outline: transparent !important;
        box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
        box-sizing: border-box;
        border: 1px solid #ccc;
        color: rgba(255, 255, 255, 0.65);
    }
    .register-header-sec .header-second .header-sec-btn2 button{
        border-radius: 10px !important;
        padding: 5px 20px !important;
        margin-left: -10px;
    }
    .register-header-sec .main-header .head-sec-lft{
        justify-content: space-around;
    }
    .after-login-sec .main-header .head-sec-lft {
        display: flex !important;
    }
  
}

@media only screen and (min-width:1081px) and (max-width:1200px) {
    .header-second {
        .custom-container {
            padding: 0px;

            .custom-fluid-container {
                padding: 0px;
            }
        }
    }
}

@media only screen and (min-width: 200px) and (max-width: 575px) {
    .common-header-sec {
        .main-header {
            .head-sec-lft {
                flex-wrap: wrap;
                justify-content: start;

                .translator {
                    margin-bottom: 5px;
                    // flex-wrap: wrap;
                    // justify-content: center;
                }
            }
        }
    }
}
@media (max-width:767px){
    .common-header-sec {
        .main-header {
    .head-sec-rit{
        display: block;
    }
    .icon img {
        width: 16px;
    }
}
    }
}
@media screen and (max-width :992px) {
    .common-header-sec .main-header .head-sec-rit .dropdown-toggle{
        font-size: 14px;
    }
    .profile-tab .banner{
        height: auto !important;
    }
    .profile-tab .banner img{
        height: 100px !important;
    }
    
}
.ytp-cued-thumbnail-overlay-image{
    background-size:contain ;
}