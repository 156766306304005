* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.counter-txt{
    span{
        font-size: 35px;
        font-weight: 700;
        font-family: 'Barlow', sans-serif;
    }
}
.header-banner {
    // height: 621px;
    position: relative;

    .carousel {
        .carousel-inner {
            .carousel-item {
                img {
                    width: 100%;
                    filter: brightness(0.7);
                    height: calc(100vh - 116px);
                    object-fit: cover;
                }
            }
        }
    }

    .banner-text {
        position: absolute;
        left: 50%;
        top: 66%;
        transform: translate(-170%, -50%);
        text-align: center;
        animation: trans-jump;
        animation-duration: 3s;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;

        h1 {
            color: #FFF;
            text-align: center;
            font-family: Barlow;
            font-size: 40px;
            font-weight: 700;
            margin-bottom: 30px;
        }

        .find-job-btn {
            border-radius: 50px;
            background: #0A65CC;
            color: #fff;
            padding: 13px 25px;
            border: unset;
            text-decoration: none;
        }

    }

    @keyframes trans-jump {
        from {
            opacity: 0%;

            transform: translate(-100%, -50%);

        }

        to {
            opacity: 100%;
            transform: translate(-50%, -50%);
        }
    }
}

// ===========Banner-After============

.bannerafter-header {
    background: #E9F4FE;
    padding: 50px;

    .achievements-lft {

        h1 {
            color: #1B375C;
            font-size: 40px;
            font-weight: 700;
            text-align: start;
        }

        p {
            text-align: start;
        }

    }
}

.achievements-rit {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;

    .counter-img {
        img {
            padding: 10px;
        }
    }

    .counts {
        display: flex;
    }

    .counter-txt {

        h2 {
            color: var(--neutral-900, #111827);
            font-size: 35px;
            font-style: normal;
            font-weight: 700;
        }
    }
}

// ===========Work Location============

.work-location {
    padding: 50px 0 0 0;

    h3.no-link {
        a {
            text-decoration: none;
            color: black
        }
    }

    h1 {
        color: #1B375C;
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
    }

    .slick-slider {
        padding: 20px 10px !important;

        .slide {
            padding: 20px 0px;
            box-shadow: 5px 5px 5px 0px rgba(224, 224, 224, 0.50);
            margin: 15px 10px;

            p {
                margin: unset;
            }
        }

        .slick-prev:before {
            content: '←';
            font-size: 30px;
            line-height: 1;
            opacity: .75;
            color: #a19b9b;
            z-index: 9999;
        }

        .slick-next:before {
            content: '→';
            font-size: 30px;
            line-height: 1;
            opacity: .75;
            color: #a19b9b;
        }

        div {
            padding: 0;
            border-radius: 20px;


            h3 {
                text-align: center;
                color: #000;
                font-size: 18px;
                font-weight: 700;
                text-transform: capitalize;
            }

            p {
                text-align: center;
                font-size: 12px;
            }
        }
    }
}


// ===========Job Opening============
.job-opening {
    padding: 50px 0;
    border-radius: 4px;
    background: #F6F6F6;
    box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;

    // .MuiTabs-scroller.MuiTabs-fixed{
    //     overflow-x: scroll !important;
    // }
    a.job-find-card-link {
        text-decoration: none;
    }

    h1 {
        color: #1B375C;
        font-size: 40px;
        font-weight: 700;
    }

    .css-1gsv261 {
        border: unset;
    }

    .css-13xfq8m-MuiTabPanel-root {
        padding: 24px 0;
    }


    .slick-slider {

        .slick-next,
        .slick-prev {
            &:before {
                color: #000;
                z-index: 9999;
            }
        }
    }



    .MuiTabs-root {
        .MuiTabs-scroller {
            padding-bottom: 20px;
            overflow-x: scroll !important;
            text-align: center !important;

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #F5F5F5;
                display: none;
            }

            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
                background-color: #F5F5F5;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #00395c;
                background-image: -webkit-linear-gradient(45deg,
                        rgba(255, 255, 255, .2) 25%,
                        transparent 25%,
                        transparent 50%,
                        rgba(255, 255, 255, .2) 50%,
                        rgba(255, 255, 255, .2) 75%,
                        transparent 75%,
                        transparent)
            }

        }
    }

    .MuiTabs-flexContainer {
        border: unset;
        padding: 20px 0;
        justify-content: center;
        // overflow-x: scroll;
        display: unset;

        .css-1gsv261 {
            border: unset;
        }

        .MuiButtonBase-root {
            // font-family: Barlow;
            padding: 0 25px;
            font-size: 18px;
            font-weight: 600;
            margin: 0 10px;
            border-radius: 60px;
            text-transform: capitalize;
            border: 1px solid rgba(0, 0, 0, 0.15);
            background: rgba(217, 217, 217, 0.06);
            box-shadow: 7px 9px 8px 0px rgba(14, 97, 194, 0.43);

        }
    }

    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
        background-color: #1C385D;
        color: #ffffff;
    }

    .latest-job-opening-tab-parent {
        .latest-job-opening-tab-btn.Mui-selected {
            background-color: #1C385D;
            color: #ffffff;

            span {
                display: none;
            }
        }

        .latest-job-opening-tab-btn1.Mui-selected {
            background-color: #1C385D;
            color: #ffffff;

            span {
                display: none;
            }
        }

        span.MuiTabs-indicator {
            display: none;
        }

        span.css-ttwr4n {
            display: none;
        }
    }

    .latest-job-opening-tab-parent>span {
        background-color: transparent;
    }


    .css-1aquho2-MuiTabs-indicator {
        height: unset;
        background-color: unset;
    }

    .latest-job-card {
        // width: 270px;
        // height: 200px;
        border-radius: 7px;
        background: #1B375C;
        padding: 20px;
        margin: 0 5px;

        .company-group {
            margin-bottom: 0px;

            .comp-logo {
                width: 55px;
                height: 55px;
                flex-shrink: 0;
            }

            img {
                width: 55px;
                height: 25px;
                flex-shrink: 0;
            }

            .job-role {
                h5 {
                    color: #FFF;
                    // font-family: Axiforma;
                    text-align: left;
                    font-size: 20px;
                    font-weight: 600;
                }

                h6 {
                    color: #FFF;
                    // font-family: Axiforma;
                    font-size: 14px;
                    font-weight: 300;
                }
            }

        }

        .job-title {
            margin-top: 15px;

            h5 {
                color: #FFF;
                // font-family: Axiforma;
                text-align: left;
                font-size: 18px;
                font-weight: 600;
            }

            h6 {
                color: #F8F8F8;
                // font-family: Helvetica;
                text-align: left;
                font-size: 14px;
                font-weight: 400;
            }
        }

        .job-loc {
            margin-top: 10px;
            margin-bottom: 10px;

            button {
                display: flex;
                align-items: center;
                width: 80px;
                height: 25px;
                border: unset;
                border-radius: 5px;
                background: rgba(255, 255, 255, 0.10);
                padding: 5px 10px;
                // margin: 0 3px;
                margin-right: 3px;

                a {
                    color: #FFF;
                    font-size: 12px;
                    font-weight: 300;
                    text-decoration: none;
                }
            }
        }


    }

    .latest-job-card-two {
        border-radius: 7px;
        background: #ffffff;
        padding: 20px;
        margin: 0 5px;
        border-radius: 7px;
        height: 260px;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), 0px 4px 20px 0px rgba(27, 55, 92, 0.20) inset;

        .company-group {
            margin-bottom: 0px;

            .comp-logo {
                width: 55px;
                height: 55px;
                flex-shrink: 0;
            }

            img {
                width: 55px;
                height: 25px;
                flex-shrink: 0;
            }

            .job-role {
                .line-clamp-company-name {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                h5 {
                    color: #000000;
                    // font-family: Axiforma;
                    text-align: left;
                    font-size: 20px;
                    font-weight: 600;
                }

                h6 {
                    color: #000000;
                    // font-family: Axiforma;
                    font-size: 14px;
                    font-weight: 300;
                }
            }

        }

        .job-title {
            margin-top: 15px;

            h5 {
                color: #000000;
                // font-family: Axiforma;
                text-align: left;
                font-size: 18px;
                font-weight: 600;
            }

            h6 {
                color: #000000;
                // font-family: Helvetica;
                text-align: left;
                font-size: 14px;
                font-weight: 400;
            }
        }

        .job-loc {
            margin-top: 10px;
            margin-bottom: 10px;

            button {
                display: flex;
                align-items: center;
                width: 80px;
                height: 25px;
                border: unset;
                border-radius: 5px;
                background: rgba(105, 105, 115, 0.10);
                padding: 5px 10px;
                // margin: 0 3px;
                margin-right: 3px;

                a {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    color: #000000;
                    font-size: 12px;
                    font-weight: 300;
                    text-decoration: none;
                }
            }
        }

        .job-times {
            color: black;
            font-size: 13px;
            justify-content: space-between;
        }
    }

    .job-opening-tabs {
        .nav-tabs {
            border: none;

            .nav-item {
                .nav-link {
                    background: hsla(0, 0%, 85%, .06);
                    border: 1px solid rgba(0, 0, 0, .15);
                    border-radius: 60px;
                    box-shadow: 7px 9px 8px 0 rgba(14, 97, 194, .43);
                    font-size: 18px;
                    font-weight: 600;
                    margin: 10px 10px;
                    padding: 12px 25px;
                    text-transform: capitalize;
                    color: rgb(25, 118, 210);
                }

                .nav-link.active {
                    position: relative;

                    &::after {
                        position: absolute;
                        content: "";
                        border: 1px solid rgb(25, 118, 210);
                        bottom: -16px;
                        width: 100%;
                        left: 0;
                    }
                }
            }

        }

        .tab-content {
            margin-top: 20px;
        }
    }

}


// ===========Benifits============

.benifits {
    padding: 50px 0;
    background-color: #ffffff;

    .key-benifits {
        padding: 50px;

        .heading-txt {

            h1 {
                color: #1B375C;
                text-align: left;
                font-size: 40px;
                font-weight: 700;
            }
        }

        p {
            text-align: left;
        }
    }

    .key-points {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        border-radius: 10px;
        margin-bottom: 10px;
        border: 1px solid #E5D7CC;
        background: #FEFEFE;
        box-shadow: 5px 8px 19px 0px rgba(207, 207, 207, 0.28);

        .text {
            p {
                color: #2D2D2D;
                font-size: 15px;
                font-weight: 400;
                letter-spacing: -0.32px;
            }
        }
    }

    .benifit-sec-lft {
        .cust-image {
            width: 50px;
            height: 50px;
            padding: 10px;
            border-radius: 10px;
            background: #1B375C;
        }

        p {
            margin: 0 0 0 10px;
        }
    }

    .benifit-sec-rit {
        .cust-image {
            width: 50px;
            height: 50px;
            padding: 10px;
            border-radius: 10px;
            background: #1B375C;
        }

        p {
            margin: 0 0 0 10px;
        }
    }
}

// ===========Job Demand============

.job-demand {
    padding: 50px 0;
    background: rgba(173, 216, 255, 0.25);
    box-shadow: -4px -4px 10px 0px rgba(0, 144, 255, 0.10) inset;

    h1 {
        color: #1B375C;
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 30px
    }

    .job-box {
        // height: 262px;
        background: #ffffff;
        padding: 20px;
        margin-bottom: 25px;
        transition: all 0.4s ease-in;
        max-height: 250px;
        min-height: 250px;
        cursor: pointer;

        &:hover {
            background: #1B375C;

            h4 {
                color: #fff;
            }

            h5 {
                color: #fff;
            }

            .job-type {
                p {
                    color: #fff;
                }

                i {
                    color: #fff;
                }
            }

            .company {
                .company-txt {
                    p {
                        color: #fff;
                    }

                    h5 {
                        color: #fff;
                    }
                }
            }

        }

        h4 {
            text-align: left;
            color: #000000;
            font-size: 24px;
            font-family: Barlow;
            font-weight: 500;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .job-type {
            margin-bottom: 40px;

            i {
                color: #000000;
                // margin-top: 5px;
            }

            p {
                color: #000000;
                font-size: 14px;
                margin-bottom: unset;
            }
        }

        hr {
            width: 95%;
            color: #000000;
            text-align: center;
            margin-bottom: 25px;
        }

        .company {
            height: 38px;

            .comp-logo-65p {
                max-width: 65px;
            }

            .company-txt {
                .line-clamp-comp-name {
                    max-width: 80px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }

            .company-txt {
                h5 {
                    font-size: 16px;
                    margin-bottom: unset;
                    color: #000000;
                }

                p {
                    margin-bottom: unset;
                    color: #000000;
                    font-size: 10px;
                }
            }

            button {
                border: 1px solid #1c008d;
                background-color: #ffffff;
                color: #000;
                /* border: unset; */
                font-size: 14px;
                border-radius: 0;
                padding: 0px 10px;
                box-shadow: 2px 2px 2px #ccc;
            }
        }
    }

    .job-box2 {
        // height: 262px;
        background: #ffffff;
        padding: 20px;
        border-radius: 4px;
        border-radius: 7px;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), 0px 4px 20px 0px rgba(27, 55, 92, 0.20) inset;

        h4 {
            text-align: left;
            color: #000000;
            font-size: 24px;
            font-family: Barlow;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .job-type {
            margin-bottom: 40px;

            i {
                color: #000000;
                margin-top: 5px;
            }

            p {
                font-size: 14px;
                color: #000000;
                margin-bottom: unset;
            }
        }

        hr {
            width: 95%;
            color: #ffffff;
            text-align: center;
            margin-bottom: 25px;
        }

        .company {
            height: 38px;

            .company-txt {
                h5 {
                    font-size: 16px;
                    margin-bottom: unset;
                }

                p {
                    margin-bottom: unset;
                    font-size: 10px;
                }
            }

            button {
                border: 1px solid #00000093 !important;
                background-color: #fff;
                color: #000;
                border: unset;
                border-radius: 0;
                font-size: 14px;
                padding: 0px 10px;
            }
        }
    }

    .find-job-btn {
        .find-more-jobs-btn {
            border-radius: 10px;
            background: #1B375C;
            padding: 10px 20px;
            color: #fff;
            text-decoration: none;
            border: none;
            outline: none;
        }
    }

}

// ===========Impaneled Companies============

.impaneled-companies {
    padding: 20px 0;

    .slick-list {
        padding: 15px;
    }

    .slick-next:before,
    .slick-prev:before {
        font-size: 30px;
        line-height: 1;
        opacity: .75;
        color: #d1d1d1;
        z-index: 9999
    }

    h1 {
        color: #1B375C;
        text-align: center;
        font-family: Barlow;
        font-size: 40px;
        font-weight: 700;
    }

    .company-slider {
        width: 250px !important;
        border-radius: 20px;
        background: #FFF;
        padding: 0 10px;
        box-shadow: 5px 5px 13px 0px rgba(224, 224, 224, 0.5);
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-direction: row;
        flex-wrap: nowrap;

        .company-logo {
            padding: 8px 0;

            img {
                max-width: 110px;
                object-fit: cover;
            }
        }

        .textarea {
            padding: 15px 2px;

            p {
                color: var(--grey-grey-2, #818181);
                font-family: Helvetica;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
                margin: 5px;
            }

            h3 {
                color: #1D1D1D;
                // font-family: HelveticaNeue;
                font-size: 14px;
                font-weight: 400;
                text-transform: capitalize;
                text-align: left;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                margin-bottom: 0px;
                word-wrap: break-word;
            }
        }
    }
}


// ===========Job Fair============

.job-fair {
    padding: 50px 0;
    border-radius: 4px;
    background: #E9F4FE;
    box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(25, 101, 201, 0.10) inset;

    h1 {
        color: #1B375C;
        text-align: center;
        font-family: Barlow;
        font-size: 40px;
        font-weight: 700;
    }

    .software {
        background: #FFF;
        padding: 25px;
        border-radius: 7px;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), 0px 4px 20px 0px rgba(27, 55, 92, 0.20) inset;
        cursor: pointer;

        .intro {

            img {
                width: 65px;
                height: 65px;
            }

            h4 {
                color: #141414;
                // font-family: DM Sans;
                font-size: 20px;
                font-weight: 500;
                text-align: left;
            }

            h5 {
                color: #565656;
                // font-family: DM Sans;
                font-size: 15px;
                font-weight: 400;
                margin-right: 8px;
            }

            h6 {
                border-radius: 3px;
                background: rgba(43, 133, 255, 0.10);
                color: #2B85FF;
                // font-family: DM Sans;
                font-size: 12px;
                font-weight: 500;
                display: inline-flex;
                padding: 2px 6px;
                align-items: center;
                gap: 4px;
            }
        }

        .location {
            padding: 15px 0;

            img {
                width: 21px;
                height: 21px;
            }

            h5 {
                color: #565656;
                // font-family: DM Sans;
                font-size: 15px;
                font-weight: 400;
                padding: 0 5px;
            }
        }

        .job-time {
            img {
                width: 18px;
                height: 18px;
            }

            h6 {
                color: #565656;
                // font-family: DM Sans;
                font-size: 15px;
                font-weight: 400;
                padding: 0 5px;
            }
        }

        .job-pac {
            img {
                width: 18px;
                height: 18px;
            }

            h6 {
                color: #565656;
                // font-family: DM Sans;
                font-size: 15px;
                font-weight: 400;
                padding: 0 5px;
            }
        }

        .para-text {
            p {
                color: rgba(20, 20, 20, 0.70);
                text-align: justify;
                // font-family: DM Sans;
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 10px;
            }

            p.job-fair-address-line-clamp-2 {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
}


// ===========Testimonial============

.testimonial {
    padding: 50px 0;
    background: rgba(241, 242, 244, 0.75);

    h1 {
        color: #1B375C;
        text-align: center;
        font-family: Barlow;
        font-size: 40px;
        font-weight: 700;
    }

    .slick-list {
        .test-card {
            min-height: 315px;
            background: #fff;
            margin: 0 10px;
            padding: 24px;
            border-radius: 7px;
            border-radius: 4px;
            box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), 0px 4px 20px 0px rgba(27, 55, 92, 0.20) inset;

            p {
                height: 170px;
                text-align: left;
                overflow-y: scroll;

                &::-webkit-scrollbar {
                    background-color: transparent;
                    width: 8px;
                    box-shadow: unset;
                }

                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: unset;
                    background-color: transparent;
                }
            }

            .client {
                justify-content: space-between;

                img {
                    width: 48px;
                    height: 48px;
                }

                .avatar {
                    img {
                        width: 60px;
                        height: 60px;
                        margin-right: 10px;
                    }

                    .client-info {
                        padding-top: 5px;

                        h5 {
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            color: var(--gray-scale-900, #191F33);
                            text-align: left;
                            font-family: Inter;
                            font-size: 16px;
                            font-weight: 500;
                        }

                        h6 {
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            color: var(--gray-scale-500, #767E94);
                            font-family: Inter;
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                }

                .testimonial-home-play-add {
                    .testimonial-home-play-add-img-parent {
                        // position:fixed;
                        // width:60px;
                        // height:60px;
                        // -o-transform: translate(-50%, -50%);
                        // -moz-transform: translate(-50%, -50%);
                        // -webkit-transform: translate(-50%, -50%);
                        // -ms-transform: translate(-50%, -50%);
                        // transform: translate(-50%, -50%);
                        // left:50%;
                        // top:50%;
                        position: relative;

                        .testimonial-a-tag-play-icon {
                            position: absolute;
                            top: 50%;
                            left: 45%;
                            transform: translate(-50%, -50%);

                            i {
                                font-size: 31px;
                                color: #0000009c;
                            }
                        }
                    }

                    .video-add-animation {
                        #one {
                            width: 50px;
                            height: 50px;
                            position: absolute;
                            left: 45%;
                            top: 50%;
                            border-radius: 50%;
                            -o-transform: translate(-50%, -50%);
                            -moz-transform: translate(-50%, -50%);
                            -webkit-transform: translate(-50%, -50%);
                            -ms-transform: translate(-50%, -50%);
                            transform: translate(-50%, -50%);
                            animation: one 2s infinite;
                            -o-animation: one 2s infinite;
                            -webkit-animation: one 2s infinite;
                            -ms-animation: one 2s infinite;
                            -moz-animation: one 2s infinite;
                            animation-delay: 0s;
                            animation-timing-function: ease-in-out;
                            background-color: #CD5C5C;
                        }

                        #two {
                            width: 50px;
                            height: 50px;
                            position: absolute;
                            left: 45%;
                            top: 50%;
                            border-radius: 50%;
                            -o-transform: translate(-50%, -50%);
                            -moz-transform: translate(-50%, -50%);
                            -webkit-transform: translate(-50%, -50%);
                            -ms-transform: translate(-50%, -50%);
                            transform: translate(-50%, -50%);
                            animation: two 2s infinite;
                            -o-animation: two 2s infinite;
                            -moz-animation: two 2s infinite;
                            -webkit-animation: two 2s infinite;
                            -ms-animation: two 2s infinite;
                            animation-delay: 0s;
                            animation-timing-function: ease-in-out;
                            background-color: #CD5C5C;
                        }


                        @keyframes one {
                            0% {
                                width: 0px;
                                height: 0px;
                                opacity: 1;
                            }


                            100% {
                                width: 100%;
                                height: 100%;
                                opacity: 0;
                            }
                        }

                        @keyframes two {
                            0% {
                                width: 0px;
                                height: 0px;
                                opacity: 1;
                            }

                            50% {
                                width: 0px;
                                height: 0px;
                                opacity: 1;
                            }

                            100% {
                                width: 100%;
                                height: 100%;
                                opacity: 0;
                            }
                        }
                    }

                }
            }

        }

    }

    .popup-video-play {
        .player-wrapper {
            video {
                width: 100%;
                height: 100%;
            }
        }
    }

    .personal-page-modal-body {
        padding: 0px;

        .save-btn-cstm {
            border: 1px solid #023250;
            border-radius: 5px;
            background-color: #fff;
            transition: all 0.5s;
            color: #023250;

            &:hover {
                background-color: #023250;
                color: #fff;
                border: 1px solid #ccc;
                border-radius: 5px;
            }
        }

        .upload-with-icon {
            border: 1px solid #023250;
            border-radius: 5px;
            background-color: #fff;
            transition: all 0.5s;
            color: #023250;

            &:hover {
                background-color: #023250;
                color: #fff;
                border: 1px solid #ccc;
                border-radius: 5px;
            }
        }

        .file-name-custom-dv {
            span {
                font-size: 14px;
            }
        }
    }
}

// ===========Smart App============

.smart-app {
    img {
        width: 100%;
    }
}