.create-question-modal-dv {
    .btn-close {
        background: #F5F6F7 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
        border-radius: 50%;
    }
    .modal-body {
        .form-group {
            select {
                -webkit-appearance: auto;
                -moz-appearance: auto;
                appearance: auto;
            }
        }
    }
    .modal-body-custom{
        .form-group{
            label{
                font-size: 14px;
            }
        }
    }
    .footer-btn {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-content: center;
        align-items: center;

        .footer-modal-btn {
            background: #F5F6F7;
            border: none;
            outline: none;
            padding: 5px 15px;
            border-radius: 20px;




        }

        .footer-modal-btn.cancel {}

        .footer-modal-btn.create {
            background: #005CE8;
            color: #fff;
        }
    }
}