@media only screen and (min-width: 768px) and (max-width: 991px) {
  .matching-hiring .banner-area {
    height: 320px;
    background-size: cover;
  }
  .matching-hiring .banner-area .banner-text {
    left: auto !important;
    transform: none !important;
    width: auto !important;
    top: 15% !important;
  }
  .matching-hiring .banner-area .banner-text h1 {
    font-size: 30px !important;
  }
  .matching-hiring .banner-area .banner-text p {
    font-size: 18px !important;
    margin: 10px 0 15px 0 !important;
  }
  .matching-hiring .banner-area .banner-text a {
    padding: 7px 25px !important;
    font-size: 16px !important;
  }
  .matching-hiring .banner-area .banner-sec-lft::before {
    width: 100%;
  }
  .matching-hiring .banner-area .banner-sec-lft + .col-lg-6 {
    display: none;
  }
  .matching-hiring .banner-area .banner-sec-rt {
    display: none;
  }
  .search-candidates .form-area {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    padding: 20px 20px;
    border-radius: 30px;
    background: #1B375C;
    position: relative;
    z-index: 999;
  }
  .search-candidates .form-img img {
    display: none;
    width: 100%;
    height: 100%;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .matching-hiring .banner-area {
    height: 240px;
  }
  .matching-hiring .banner-area .banner-text {
    left: auto !important;
    transform: none !important;
    width: auto !important;
    top: 15% !important;
  }
  .matching-hiring .banner-area .banner-text h1 {
    font-size: 20px !important;
  }
  .matching-hiring .banner-area .banner-text p {
    font-size: 15px !important;
    margin: 0 0 15px 0 !important;
  }
  .matching-hiring .banner-area .banner-text a {
    padding: 7px 25px !important;
    font-size: 16px !important;
  }
  .matching-hiring .banner-area .banner-sec-lft::before {
    -webkit-clip-path: unset;
            clip-path: unset;
    width: 100%;
  }
  .matching-hiring .banner-area .banner-sec-lft + .col-lg-6 {
    display: none;
  }
  .matching-hiring .banner-area .banner-sec-rt {
    display: none;
  }
  .free-job-post {
    padding-top: 20px !important;
  }
  .free-job-post .head-area {
    margin-bottom: 0 !important;
  }
  .free-job-post .head-area h2 {
    font-size: 22px;
    margin-bottom: 0 !important;
  }
  .free-job-post .custm-features {
    padding: 20px;
    height: auto;
  }
  .free-job-post .custm-features h3 {
    font-size: 18px;
  }
  .free-job-post .custm-features h4 {
    font-size: 15px;
  }
  .free-job-post .custm-features2 {
    padding: 20px;
    height: auto;
  }
  .free-job-post .custm-features2 h3 {
    font-size: 18px;
  }
  .free-job-post .custm-features2 h4 {
    font-size: 15px;
  }
  .free-job-post .custm-features3 {
    padding: 20px;
    height: auto;
  }
  .free-job-post .custm-features3 h3 {
    font-size: 18px;
  }
  .free-job-post .custm-features3 h4 {
    font-size: 15px;
  }
  .free-job-post .custm-features4 {
    padding: 20px;
    height: auto;
  }
  .free-job-post .custm-features4 h3 {
    font-size: 18px;
  }
  .free-job-post .custm-features4 h4 {
    font-size: 15px;
  }
  .add-budget {
    padding-top: 20px !important;
  }
  .add-budget .head-area {
    margin-bottom: 0 !important;
  }
  .add-budget .head-area h2 {
    font-size: 22px;
    margin-bottom: 0 !important;
  }
  .add-budget .custm-bud-text h4 {
    padding: 20px 0 0 0;
    font-size: 18px;
  }
  .add-budget .custm-bud-text p {
    padding: 0px;
    font-size: 15px;
  }
  .search-candidates {
    padding-top: 20px !important;
  }
  .search-candidates .head-area {
    margin-bottom: 0 !important;
  }
  .search-candidates .head-area h2 {
    font-size: 22px;
  }
  .search-candidates .form-area {
    width: auto;
    height: auto;
    padding: 20px;
  }
  .search-candidates .form-area form label {
    font-size: 14px;
  }
  .search-candidates .form-area form input {
    height: auto;
  }
  .search-candidates .form-area form input::-moz-placeholder {
    font-size: 14px;
  }
  .search-candidates .form-area form input::placeholder {
    font-size: 14px;
  }
  .search-candidates .form-area form .login-btn {
    padding: 8px 0;
    width: 100%;
  }
  .search-candidates .form-img img {
    display: none;
    width: auto;
    height: auto;
  }
}/*# sourceMappingURL=hiringresponsive.css.map */