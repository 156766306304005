.why-sponsor-onboarding-guide {
    background: rgba(246, 246, 246, 1);
    .why-spon-heading{
        h2{
            color: #1B375C;
            text-align: center;
            font-size: 48px;
            font-weight: 700;
        }
    }
    .img-area {
        h3 {
            color: var(--Dark-blue, #282938);
            font-size: 16px;
            font-weight: 600;
            padding: 15px 0;
            // height: 80px;
        }

        a {
            color: #0A65CC;
            font-size: 16px;
            text-decoration: none;
        }
    }
}

@media only screen and (min-width:200px) and (max-width:567px) {
    .why-sponsor-onboarding-guide {
        .img-area {
            margin: 0 0 20px 0;

            h3 {
                font-size: 16px;
                height: auto;
            }

            a {
                color: #0A65CC;
                font-size: 14px;
                text-decoration: none;
            }
        }
    }
}