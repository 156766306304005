.skill-list,
.add-lang {
    display: grid;

    word-break: break-all;
    align-items: flex-start;
}

.skill-list {
    grid-template-columns: 5fr 1fr;
}

.add-lang {
    grid-template-columns: 4fr 1fr;
}

.custom-resume-stepper {

    display: grid;
    margin: 20px auto;
    grid-template-columns: 1fr 8fr;

    .css-8t49rw-MuiStepConnector-line {
        min-height: 30px;
        height: 100%;
    }

    #changeCVBuilderTemplate {
        .custm-bud-img-cv-builder {
            img {
                width: fit-content;
                height: 600px;
                object-fit: fill;
                border-radius: 10px;
                border-radius: 25px;
                border: 10px solid #fff;
                background: #FFF;
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
            }

            img.selected-cv {
                filter: drop-shadow(2px 4px 6px rgb(43, 40, 40));
            }
        }
    }
}

.custom-container1 {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 800px;
    margin: 20px auto;
    border: 1px solid #e1e1e13d;
    // padding: 20px;
    box-shadow: 0px 0px 6px 0px #0000001f;

    .img-box {
        img {
            object-fit: contain;
            height: 100%;
            width: 100%;
        }
    }

    .header {
        /* background-color: #1b375c; */
        background: #fff;
        color: #333;
        padding: 20px 20px;
        /* text-align: center; */
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header h1 {
        margin: 0;
        font-size: 24px;
        color: #333;
        text-transform: uppercase;
    }

    .contact-info {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin-top: 10px;
        font-size: 14px;

        a {
            // max-width: 200px;
            word-break: break-all;
            margin: 5px 0;
        }
    }

    .contact-info p {
        margin: 0px 0px 4px 8px;
        /* display: flex; */
        color: #333;
        align-items: center;
        word-wrap: break-word;
        text-wrap: wrap;
        width: 300px;
    }

    // .contact-info p::before {
    //     content: attr(data-icon);
    //     margin-right: 5px;
    // }

    .profile {
        display: flex;
        justify-content: start;
        align-items: center;
        /* background-color: #e9e9e9; */

    }

    .profile img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        border: 2px solid #ddd;
        object-fit: cover;
    }

    .section {
        background-color: #fff;
        display: flex;
        justify-content: start;
        padding: 0px 20px 0px 20px;

    }

    .section h2 {
        /* border-bottom: 2px solid #1b375c; */
        padding-bottom: 5px;
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 16px;
        color: #1b375c;
        text-transform: uppercase;
        line-height: 24px;
    }

    .mt-custom {
        margin-bottom: 10px;
        margin-top: 14px;
    }

    .section p,
    .section ul {
        margin-bottom: 10px;
        margin-top: 14px;
        font-size: 14px;
        line-height: 24px;
        text-align: justify;
    }

    .section ul {
        padding-left: 20px;

    }

    .section ul li {
        margin-bottom: 5px;
    }

    .section .sub-section {
        margin-bottom: 10px;
    }

    .section .sub-section h3 {
        margin: 0 0 5px 0;
        font-size: 14px;
        color: #333;
    }

    .section .sub-section p {
        margin: 0 0 5px 0;
        font-size: 12px;
        color: #555;
    }

    .top-section {
        height: 35px;
        width: 100%;
        background: #575757;
        margin: 3px auto;
        position: relative;
    }

    .top-section svg {
        position: absolute;
        bottom: -17px;
        left: 25%;
        fill: #575757;
    }

    .top-section::after {
        content: '';
        position: absolute;
        bottom: -18px;
        left: 25%;
        border-width: 10px;
        border-style: solid;
        z-index: 1;
        border-color: #575757 #0000 #0000 #575757;
    }

    .flex {
        display: flex;
        justify-content: start;
        align-items: start;
        gap: 4px;
        margin-top: 8px;
    }

    .icon-box {
        height: 22px;
        width: 22px;
        border-radius: 2px;
        background: #575757;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon-box i {
        font-size: 12px;
        color: #fff;
    }

    .social-icons {
        display: flex;
        justify-content: end;
        align-items: center;

        gap: 7px;
    }

    .social-icons i {
        font-size: 26px;
    }

    .border-bottom-dashed {
        border-bottom: 1px dashed #000;
        padding-bottom: 15px;
    }

    .border-bottom-undashed {
        // border-bottom: 1px dashed #000;
        padding-bottom: 15px;
    }
}

.custom-container2 {
    width: 800px;
    margin: 40px auto;
    padding: 0;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;


    .sidebar::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 130px;
        width: 100%;
        background: #414040;
    }

    .sidebar {
        position: relative;
        background: #007b96;
        color: white;
        padding: 25px;
        width: 30%;
        height: unset
    }

    .sidebar h2 {
        color: #fff;
    }

    .content h1 {
        color: #3bafc4;
    }

    .sidebar h2 {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .sidebar p {
        font-size: 14px;
        line-height: 25px;
        word-break: break-all;
    }

    .sidebar ul {
        list-style: none;
        padding: 0;
    }

    .sidebar ul li {
        margin-bottom: 10px;
        line-height: 24px;
        font-size: 14px;
    }

    .content {
        padding: 20px 30px;
        height: 100%;
        width: 70%;
        background: #fff;
    }

    .content h1 {
        font-size: 32px;
        margin-bottom: 20px;
    }

    .content h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #007b96;
    }

    .content h5 {
        font-size: 16px;
        color: #696969;
        margin-bottom: 10px;
        margin: 18px 0;

    }

    .content h4 {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .content p,
    .content li {
        font-size: 14px;
        line-height: 25px;
        color: #616161;
        text-align: justify;
    }

    .content ul {
        list-style: disc inside;
        padding: 0;
    }

    .line {
        border-bottom: 2px solid #3bafc4;
        margin: 20px 0;
    }

    .img-box {
        border: 1px solid #3bafc4;
        height: 225px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        position: relative;
        background: #007b963d;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    .social-icons {
        display: flex;
        justify-content: end;
        align-items: center;

        gap: 7px;
    }

    .social-icons i {
        font-size: 26px;
        color: #3bafc4;
    }

    .between {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .sidebar {
        a {
            word-break: break-all;
            margin: 5px 0;
        }
    }
}

.custom-container3 {
    .sidebar {
        a {
            word-break: break-all;
            margin: 5px 0;
        }
    }

    display: flex;
    flex-direction: column;
    width: 800px;
    margin: 20px auto;
    border: 1px solid #e1e1e13d;
    background-color: #fff;
    box-shadow: 0px 0px 6px 0px #0000001f;

    .img-box {
        img {
            object-fit: contain;
            height: 100%;
            width: 100%;
        }
    }

    .sidebar {
        background-color: #fff;
        color: #333;
        padding: 20px;
        width: 30%;

    }

    .sidebar h2 {
        font-size: 18px;
        color: #007b96;
        border-bottom: 2px solid #007b96;
        padding-bottom: 5px;
        margin-bottom: 10px;
    }

    .sidebar p,
    .sidebar li {
        font-size: 14px;
        line-height: 1.5;
        word-break: break-all;
    }

    .sidebar ul {
        list-style: none;
        padding: 0;
    }

    .sidebar ul li {
        margin-bottom: 10px;
    }

    .content {
        border-right: 1px solid #ccc;
        padding: 20px;
        width: 70%;
    }

    .content h1 {
        font-size: 24px;
        color: #007b96;
        /* border-bottom: 2px solid #333; */
        padding-bottom: 5px;
        margin-bottom: 20px;
    }

    .content h5 {
        font-size: 16px;
        color: #999;
        margin: 6px 0;
    }

    .content h3 {
        font-size: 18px;
        color: #007b96;
        margin-bottom: 12px;
        margin-top: 12px;
    }

    .content h4 {
        font-size: 16px;
        color: #333;
        margin-bottom: 10px;
    }

    .content p,
    .content li {
        font-size: 14px;
        line-height: 24px;
        text-align: justify;
    }

    .content ul {
        list-style: disc inside;
        padding: 0;
        margin-bottom: 20px;
    }

    .line {
        border-bottom: 2px solid #007b96;
        margin: 20px 0;
    }

    .profile {
        height: 220px;
        width: 220px;
        border-radius: 50%;
        border: 1px solid #000;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
    }

    .profile img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        border-radius: 50%;

    }

    .top-section {
        height: 35px;
        width: 100%;
        background-color: #007b96;
        margin: 3px auto;
        position: relative;
    }

    .custom-line {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 15px;
    }

    .background {
        height: 15px;
        width: 100%;
        background-color: #007b9621
    }

    .download-btn {
        text-align: center;
        margin: 20px;
    }

    .download-btn button {
        padding: 10px 20px;
        font-size: 16px;
        background-color: #1b375c;
        color: white;
        border: none;
        cursor: pointer;
    }

    .download-btn button:hover {
        background-color: #14314a;
    }

    .social-icons {
        display: flex;
        justify-content: end;
        align-items: center;

        gap: 7px;
    }

    .social-icons i {
        font-size: 26px;
        color: #007b96;
    }

    .between {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}


.create-new-resume-modal {
    .create-new-resume-btn {
        border-radius: 29px;
        padding: 10px 20px;
        background: #1B375C;
        color: #FFF;
        font-weight: 600;
        border: none;
        outline: none;

        &:hover {
            background: #fff;
            color: #1B375C;
            border: 2px solid #1B375C;
        }
    }

    .create-existing-resume-btn {
        border-radius: 29px;
        padding: 10px 20px;
        background: #fff;
        color: #1B375C;
        font-weight: 600;
        border: 2px solid #1B375C;
        outline: none;

        &:hover {
            color: #fff;
            background: #1B375C;
        }
    }
}



.ckeditor-custom-height .ck-editor__editable_inline {
    min-height: 400px;
}

.css-18st3fs-MuiButtonBase-root-MuiButton-root.Mui-disabled {
    background: #00000014;
}

@media screen and (max-width: 1024px) {

    .skill-list {
        grid-template-columns: 2fr 1fr;
    }
}


@media screen and (max-width: 991px) {

    .skill-list,
    .add-lang {
        grid-template-columns: 5fr 2fr;
        gap: 20px;
        font-size: 14px;

        .btn {
            width: fit-content;
            margin-left: 0px !important;
        }
    }

}

@media screen and (max-width: 767px) {

    .skill-list,
    .add-lang {
        grid-template-columns: auto;
        gap: 20px;
        font-size: 14px;
    }

    .custom-resume-stepper {

        .card-text {
            font-size: 14px;
        }

        .MuiStepper-vertical {
            width: 30px;
        }

        .MuiStepLabel-label {
            display: none;
        }

        grid-template-columns: 0fr 6fr;
    }
}

@media screen and (max-width: 575px) {
    .education-details div.d-grid-education-details {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .custom-resume-stepper {
        .btn-outline-primary {
            padding: 5px 12px !important;
            font-size: 14px !important;
        }

        .font-semibold {
            font-size: 18px;
        }

    }

    .download-btn {
        display: grid;
        gap: 10px;
    }
}

@media screen and (max-width: 420px) {
    .custom-stepper-button {
        display: block !important;
    }

    .custom-stepper-button .prev-btn-resume,
    .custom-stepper-button .next-btn-resume,
    .custom-stepper-button .work-details .prev-btn-resume,
    .work-details .next-btn-resume,
    .project-details .prev-btn-resume,
    .project-details .next-btn-resume,
    .education-details .prev-btn-resume,
    .education-details .next-btn-resume,
    .certificate-details .prev-btn-resume,
    .certificate-details .next-btn-resume,
    .accomplishments-cv-builder .prev-btn-resume,
    .accomplishments-cv-builder .next-btn-resume {
        margin: 10px 0px;
        width: 100%;
    }

    .custom-stepper-1 {
        flex-direction: column;
        justify-content: strat !important;
        align-items: start !important;
        gap: 10px;
    }
}

.overflow-auto {
    overflow-x: auto;
}


.personal-details-cv-builder {
    .next-btn-resume {
        padding: 5px 20px;
        border-radius: 40px;
        background: #1B375C;
        text-decoration: none;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        border: 1px solid #1B375C;
    }

    .prev-btn-resume {
        padding: 5px 20px;
        border-radius: 40px;
        border: 1px solid #1B375C;
        background: #FFF;
        text-decoration: none;
        color: #1B375C;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
    }
}

.additional-details-cv-builder {
    .next-btn-resume {
        padding: 5px 20px;
        border-radius: 40px;
        background: #1B375C;
        text-decoration: none;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        border: 1px solid #1B375C;
    }

    .prev-btn-resume {
        padding: 5px 20px;
        border-radius: 40px;
        border: 1px solid #1B375C;
        background: #FFF;
        text-decoration: none;
        color: #1B375C;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
    }

    .accordion-border{
        border: 2px solid #1b375c;

        h6.title-heading{
            color: #1b375c;
        }
    }
}

.accomplishments-cv-builder {
    .next-btn-resume {
        padding: 5px 20px;
        border-radius: 40px;
        background: #1B375C;
        text-decoration: none;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        border: 1px solid #1B375C;
    }

    .prev-btn-resume {
        padding: 5px 20px;
        border-radius: 40px;
        border: 1px solid #1B375C;
        background: #FFF;
        text-decoration: none;
        color: #1B375C;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
    }
}

//css for modals
.work-details {
    .next-btn-resume {
        padding: 5px 20px;
        border-radius: 40px;
        background: #1B375C;
        text-decoration: none;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        border: 1px solid #1B375C;
    }

    .prev-btn-resume {
        padding: 5px 20px;
        border-radius: 40px;
        border: 1px solid #1B375C;
        background: #FFF;
        text-decoration: none;
        color: #1B375C;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
    }

    .width-fit-content {
        width: fit-content;
    }

    div.d-grid-work-details {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }

    div.d-grid-project-details {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }

    /* Mobile devices (portrait and landscape) */
    @media only screen and (max-width: 767px) {
        div.d-grid-work-details {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 10px;
        }

        div.d-grid-project-details {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 10px;
        }
    }
}

.project-details {
    .next-btn-resume {
        padding: 5px 20px;
        border-radius: 40px;
        background: #1B375C;
        text-decoration: none;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        border: 1px solid #1B375C;
    }

    .prev-btn-resume {
        padding: 5px 20px;
        border-radius: 40px;
        border: 1px solid #1B375C;
        background: #FFF;
        text-decoration: none;
        color: #1B375C;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
    }

    .width-fit-content {
        width: fit-content;
    }

    div.d-grid-project-details {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }

    /* Mobile devices (portrait and landscape) */
    @media only screen and (max-width: 767px) {

        div.d-grid-project-details {
            // display: grid;
            grid-template-columns: repeat(1, 1fr);
            // grid-gap: 10px;
        }
    }
}

.education-details {
    .next-btn-resume {
        padding: 5px 20px;
        border-radius: 40px;
        background: #1B375C;
        text-decoration: none;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        border: 1px solid #1B375C;
    }

    .prev-btn-resume {
        padding: 5px 20px;
        border-radius: 40px;
        border: 1px solid #1B375C;
        background: #FFF;
        text-decoration: none;
        color: #1B375C;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
    }

    .width-fit-content {
        width: fit-content;
    }

    div.d-grid-education-details {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }

    /* Mobile devices (portrait and landscape) */
    @media only screen and (max-width: 767px) {

        div.d-grid-education-details {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

.certificate-details {
    .next-btn-resume {
        padding: 5px 20px;
        border-radius: 40px;
        background: #1B375C;
        text-decoration: none;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        border: 1px solid #1B375C;
    }

    .prev-btn-resume {
        padding: 5px 20px;
        border-radius: 40px;
        border: 1px solid #1B375C;
        background: #FFF;
        text-decoration: none;
        color: #1B375C;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
    }

    .width-fit-content {
        width: fit-content;
    }

    div.d-grid-certificate-details {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }

    /* Mobile devices (portrait and landscape) */
    @media only screen and (max-width: 767px) {

        div.d-grid-certificate-details {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

// Stepper veritcal labels
.MuiStepLabel-label {
    font-size: large !important;
    font-weight: bold !important;
}

/* ===========Cv ========= */
.cv_detail_new_modul {
    max-width: 950px;
    margin: 0 auto;
    border-radius: 20px;
}

.main_cv_detail_section {
    background: #efefef;
    border-radius: 10px;
}

.main_cv_detail_section_2 {
    background: #fff;
    border-radius: 10px;
    border: 1px solid #efefef;

    .bg_none_main_new {
        padding: 20px 20px 10px;

        .main_profile_detail {
            text-align: right;

            h2 {
                color: #000;
                font-weight: 600;
                font-family: "Poppins", sans-serif;
                font-size: 33px;
                margin: 0;
                border-bottom: 2px solid #000;
                padding-bottom: 5px;
                margin-bottom: 5px;
            }

            p {
                font-size: 14px;
                color: #000;
                text-decoration: none;
                border-top: 2px solid #000;
                padding-bottom: 5px;
                margin-bottom: 5px;
            }
        }

        .main_detail_sosal {
            h6 {
                font-weight: 600;
                font-size: 17px;
                color: #fff;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                line-height: 1.2;

                li {
                    a {
                        font-size: 14px;
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.bg_none_main {
    background-color: #fff !important;
}

.name_detail_listing {
    background-color: #404d54;
    border-radius: 10px 10px 0 0;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .main_detail_sosal {
        h6 {
            font-weight: 600;
            font-size: 17px;
            color: #fff;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            line-height: 1.2;

            li {
                a {
                    font-size: 14px;
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
    }

    .profile_main_resum {
        display: flex;
        flex-wrap: wrap;

        .main_profile_img {
            img {
                width: 85px;
                border-radius: 100%;
                margin-right: 10px;
                border: 1px solid #fff;
            }
        }

        .main_profile_detail {
            h2 {
                color: #fff;
                font-weight: 600;
                font-family: "Poppins", sans-serif;
                font-size: 33px;
                margin: 0;
            }

            p {
                color: #fff;
                font-size: 16px;
                line-height: 25px;
                padding: 0;
                margin: 0;
            }

            p span {
                color: #fff;
                font-size: 16px;
                padding: 0 10px;
            }

        }
    }


}

.detail_resum_main {
    padding: 10px 20px;

    .main_detail_skill {
        border-bottom: 1px solid #ccc;
        padding-bottom: 15px;
        margin-bottom: 10px;

        h6 {
            font-weight: 600;
            font-size: 17px;
        }

        p {
            font-size: 14px;
            color: #333;
            margin-bottom: 0;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                display: inline-block;
                padding: 0 8px;
                font-size: 14px;
                color: #000;
                border-right: 1px solid #3c4d54;
            }
        }

    }

    .main_detail_experiencs {
        border-bottom: 1px solid #ccc;
        padding-bottom: 15px;
        margin-bottom: 10px;

        div {
            ul {
                li {
                    font-size: 14px;
                    color: #333;
                    margin-bottom: 0;
                }
            }

            ol {
                li {
                    font-size: 14px;
                    color: #333;
                    margin-bottom: 0;
                }
            }
        }

        .main_link_sosal {
            margin-bottom: 0;
        }

        .exp_detail_text {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            h6 {
                font-weight: 600;
                font-size: 17px;
            }

            h5 {
                font-weight: normal;
                font-size: 16px;

                span {
                    padding: 0 5px;
                }
            }
        }

        p {
            font-size: 14px;
            color: #333;
            margin-bottom: 0;
        }
    }
}

/* ==========Cv========= */