.rec-impact-technology-content-section {

    margin: 30px 0px 30px 0;

    .main-section {
        // padding: 70px;
    }

    .technology-section {
        border-radius: 4px;
        background: #FFF;
        padding: 35px;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .technology-back-btn a {
        text-decoration: none;
        font-weight: 500;

    }

    .tech-btn {
        .techno-btn {
            padding: 6px 12px;
            border-radius: 6px;
            background: #4B6BFB;
            color: #FFF;
            border: none;
            font-size: 14px;
            font-weight: 500;
        }
    }

    .tech-head h2 {
        color: #181A2A;
        margin-top: 15px;
        font-weight: 600;
        font-size: 28px;
    }

    .tech-para img {
        margin-top: 15px;
    }

    .tech-para p {
        color: #696A75;
        margin-top: 15px;
        font-size: 14px;
        font-weight: 400;
    }

    .para-technology p {
        color: #3B3C4A;
        margin-top: 20px;
        text-align: justify;
        font-size: 17px;
        font-weight: 400;
    }

    .technology-center-section {
        .technology-heading {
            p {
                text-align: justify;
            }
        }
    }
}

@media only screen and (min-width:768px) and (max-width:992px) {
    .rec-impact-technology-content-section {
        .main-section {
            padding: 0px;
        }

        .technology-section {
            padding: 15px;
        }

        .tech-btn {

            // text-align: right;
            .techno-btn {
                margin-top: 15px;
            }
        }

        .tech-head h2 {
            color: #181A2A;
            margin-top: 15px;
            font-weight: 600;
            font-size: 22px;
        }
    }

}

@media only screen and (min-width:200px) and (max-width:767px) {
    .rec-impact-technology-content-section {
        .main-section {
            padding: 0px;
        }

        .technology-section {
            padding: 15px;
        }

        .tech-btn {
            .techno-btn {
                margin-top: 15px;
            }
        }

        .tech-head {
            h2 {
                color: #181A2A;
                margin-top: 15px;
                font-weight: 600;
                font-size: 18px;
            }
        }
    }

}