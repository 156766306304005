.job-status-content-section .candidate-pro-section-inner-dv {
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5019607843);
  box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  padding: 40px 20px;
  margin: 30px 0px;
}
.job-status-content-section .candidate-pro-section-inner-dv .candidate-pro-btn .post-btn {
  color: #fff;
  background-color: #1B375C;
  border-radius: 5px;
  padding: 10px 20px;
  text-decoration: none;
}
.job-status-content-section .candidate-pro-section-inner-dv .open-closed-btn .a-opn-cls-btn {
  margin-right: 30px;
  text-decoration: none;
  color: #000;
  text-align: center;
  padding: 8px 4px;
}
.job-status-content-section .candidate-pro-section-inner-dv .open-closed-btn .a-opn-cls-btn:hover {
  text-decoration: none;
  color: #0090FF;
}
.job-status-content-section .candidate-pro-section-inner-dv .open-closed-btn .a-opn-cls-btn.active {
  text-decoration: none;
  color: #000;
  background-color: #1B375C;
  color: white;
  padding: 8px 4px;
  border-radius: 5px;
  text-align: center;
}
.job-status-content-section .candidate-pro-section-inner-dv .right-side-search-filter-btns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  margin: 30px 0px;
}
.job-status-content-section .candidate-pro-section-inner-dv .right-side-search-filter-btns .inpt-search {
  position: relative;
}
.job-status-content-section .candidate-pro-section-inner-dv .right-side-search-filter-btns .inpt-search input {
  padding-left: 30px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.job-status-content-section .candidate-pro-section-inner-dv .right-side-search-filter-btns .inpt-search .search-icon {
  position: absolute;
  top: 0;
  left: 5px;
}
.job-status-content-section .candidate-pro-section-inner-dv .right-side-search-filter-btns .margin-rgt-lft {
  margin-left: 20px;
}
.job-status-content-section .candidate-pro-section-inner-dv .right-side-search-filter-btns .margin-rgt-lft .dropdown-toggle {
  background: transparent;
  padding: 2px 10px;
  font-size: 14px;
  border-radius: 5px;
}
.job-status-content-section .candidate-pro-section-inner-dv .right-side-search-filter-btns #sort {
  padding: 0px 10px;
  border: 1px solid #ccc;
  margin: 10px 10px;
  border-radius: 5px;
}
.job-status-content-section .candidate-pro-section-inner-dv .postwise-active-inactive-card-parent {
  padding: 0px 10px;
  border: 1px solid #ccc;
  margin: 10px 0px;
  background: linear-gradient(0deg, #F8F8F8, #F8F8F8);
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5019607843);
  border-radius: 5px;
}
.job-status-content-section .candidate-pro-section-inner-dv .postwise-active-inactive-card-parent .inpt-checkbox {
  margin-top: 3px;
}
.job-status-content-section .candidate-pro-section-inner-dv .postwise-active-inactive-card-parent .inpt-checkbox input.inpt-checkbox {
  width: 20px;
  height: 20px;
}
.job-status-content-section .candidate-pro-section-inner-dv .postwise-active-inactive-card-parent .make-curosr-pointer {
  cursor: pointer;
}
.job-status-content-section .candidate-pro-section-inner-dv .postwise-active-inactive-card-parent .rght-post-des h5 {
  margin-bottom: 0px;
}
.job-status-content-section .candidate-pro-section-inner-dv .postwise-active-inactive-card-parent .rght-post-des .small-font {
  font-size: 12px;
}
.job-status-content-section .candidate-pro-section-inner-dv .postwise-active-inactive-card-parent .rght-post-des .post-a-tag {
  text-decoration: none;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  margin-right: 10px;
}
.job-status-content-section .candidate-pro-section-inner-dv .postwise-active-inactive-card-parent .rght-post-des .post-description-para {
  font-size: 13px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.job-status-content-section .candidate-pro-section-inner-dv .post-job-four-small-card {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.job-status-content-section .candidate-pro-section-inner-dv .post-job-four-small-card .count-card {
  background: #fff;
  padding: 18px 0px;
  border: 1px solid #ccc;
  text-align: center;
  width: 120px;
  margin: 4px 4px;
}
.job-status-content-section .candidate-pro-section-inner-dv .post-job-four-small-card .count-card p {
  text-align: center;
}
.job-status-content-section .candidate-pro-section-inner-dv .post-description-start-set .post-select-dropdown {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.job-status-content-section .candidate-pro-section-inner-dv .post-description-start-set .post-select-dropdown .select-paused {
  border: 1px solid #d15107;
  background-color: #fff;
  color: #d15107;
  border-radius: 10px;
  padding: 3px 30px;
}
.job-status-content-section .candidate-pro-section-inner-dv .post-description-start-set .post-select-dropdown .select-active {
  border: 1px solid #32A071;
  background-color: #fff;
  color: #32A071;
  border-radius: 10px;
  padding: 3px 30px;
}
.job-status-content-section .candidate-pro-section-inner-dv .post-description-start-set .post-select-dropdown .select-closed {
  border: 1px solid #FF354D;
  background-color: #fff;
  color: #FF354D;
  border-radius: 10px;
  padding: 3px 30px;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .job-status-content-section .candidate-pro-section-inner-dv .post-job-four-small-card .count-card {
    background: #fff;
    padding: 18px 18px;
    border: 1px solid #ccc;
    text-align: center;
    width: 25%;
  }
  .job-status-content-section .candidate-pro-section-inner-dv .postwise-active-inactive-card-parent {
    padding: 12px 10px;
    background: linear-gradient(0deg, #F8F8F8, #F8F8F8);
    box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5019607843);
  }
  .job-status-content-section .candidate-pro-section-inner-dv .postwise-active-inactive-card-parent .make-curosr-pointer {
    cursor: pointer;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .job-status-content-section .candidate-pro-section-inner-dv .post-description-start-set .post-select-dropdown {
    padding: 7px;
  }
  .job-status-content-section .candidate-pro-section-inner-dv .post-job-four-small-card .count-card {
    background: #fff;
    padding: 0px 0px;
    border: 1px solid #ccc;
    text-align: center;
    width: 50%;
  }
  .job-status-content-section .candidate-pro-section-inner-dv .post-job-four-small-card .count-card p:first-child {
    font-size: 14px;
  }
  .job-status-content-section .candidate-pro-section-inner-dv .right-side-search-filter-btns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin: 30px 0px;
  }
  .job-status-content-section .candidate-pro-section-inner-dv .right-side-search-filter-btns .margin-rgt-lft {
    margin-left: 0px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .a-opn-cls-btn {
    margin-right: 0;
  }
}
@media only screen and (min-width: 200px) and (max-width: 567px) {
  .job-status-content-section .open-closed-btn {
    display: flex;
    justify-content: space-between;
  }
  .job-status-content-section .candidate-pro-section-inner-dv .post-job-four-small-card {
    flex-direction: column;
  }
  .job-status-content-section .candidate-pro-section-inner-dv .candidate-pro-btn .post-btn {
    padding: 10px 16px;
  }
}/*# sourceMappingURL=JobsStatus.css.map */