.career-preferences-section {
    padding: 50px 0px;

    .heading-prefe {
        h2 {}
    }

    .job-title-strip-parent {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ccc;
        padding: 25px 0px;
        cursor: pointer;
        &:nth-child(1) {
            border-top: 1px solid #ccc;
        }

        .icon-heading-name {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            // justify-content: space-between;
            align-items: center;

            svg {
                inline-size: 1.5rem;
                block-size: 1.5rem;
                color: rgb(118, 118, 118);
            }

            h5 {
                margin-bottom: 0px;
                padding-left: 20px;
            }
        }

        .plus-icon-dv {
            svg {
                inline-size: 1.5rem;
                block-size: 1.5rem;
                color: rgb(45, 45, 45);
            }
        }
    }
}