.resume-builder {
    .back-btn {
        a {
            color: #0A65CC;
            font-size: 16px;
            text-decoration: none;
        }
    }

    .avatar {
        img {
            width: 68px;
            height: 68px;
            border-radius: 100px;
        }

        h4 {
            color: #0D0D12;
            font-size: 16px;
            margin: unset;
        }

        h5 {
            color: #36394A;
            font-size: 15px;
            margin: unset;
        }
    }

    .edit-btn {
        a {
            text-decoration: none;
            padding: 10px 40px;
            border-radius: 50px;
            border: 1px solid #0A65CC;
            color: #0A65CC;
            font-size: 14px;
        }
    }

    .profile-head {
        ul {
            border-radius: 40px;
            background: #F6F8FA;
            padding: 10px;

            li {
                list-style: none;
                color: #36394A;
                font-size: 16px;

                img {
                    width: 20px;
                    height: 20px;
                    margin: 0 10px 0 0;
                }
            }
        }
    }

    .cv-info {
        .summary {
            border-radius: 10px;
            border: 1px solid #ECEFF3;
            background: #FFF;
            padding: 15px;

            .heading {
                border-bottom: 1px dashed #ECEFF3;
            }

            h4 {
                color: #36394A;
                font-size: 14px;
            }

            h5 {
                color: #666D80;
                font-size: 14px;
                text-transform: uppercase;
                border-radius: 999px;
                border: 1px solid #ECEFF3;
                background: #F6F8FA;
                padding: 8px 15px;
            }

            ul {
                li {
                    color: #36394A;
                    font-size: 14px;
                }
            }
        }

        .experience {
            .exp-info {
                img {
                    width: 50px;
                    height: 50px;
                    object-fit: cover;
                }

                h3 {
                    color: #36394A;
                    font-size: 16px;
                    margin: 0 0 6px 0;
                }

                h4 {
                    color: #666D80;
                    font-size: 14px;
                }

                .textarea {
                    ul {
                        padding: unset;
                        margin: 0 0 10px 0;

                        li {
                            list-style: none;
                            padding: 0 15px 0 0;
                            color: #36394A;
                            font-size: 14px;
                        }

                        li:nth-child(4) {
                            color: #818898;
                            font-size: 16px;
                        }

                    }

                    .list {
                        ul {
                            padding: 0 0 0 15px;

                            li {
                                color: #36394A;
                                font-size: 14px;
                                list-style: disc;
                            }
                        }
                    }

                    .list2 {
                        padding: 0 0 0 15px;

                        li {
                            list-style: disc;
                        }
                    }
                }

            }

        }

        .certification {

            .exp-info {
                img {
                    width: 50px;
                    height: 50px;
                    object-fit: cover;
                }

                h3 {
                    color: #36394A;
                    font-size: 16px;
                    margin: 0 0 6px 0;
                }

                h4 {
                    color: #666D80;
                    font-size: 16px;
                }

                .textarea {
                    ul {
                        padding: unset;
                        margin: 0 0 10px 0;

                        li {
                            list-style: none;
                            padding: 0 15px 0 0;
                            color: #36394A;
                            font-size: 14px;
                        }

                        li:nth-child(4) {
                            color: #818898;
                            font-size: 16px;
                        }

                    }

                    .list {
                        ul {
                            padding: 0 0 0 15px;

                            li {
                                color: #36394A;
                                font-size: 16px;
                                list-style: disc;
                            }
                        }
                    }

                    .list2 {
                        padding: 0 0 0 15px;

                        li {
                            list-style: disc;
                        }
                    }
                }

                p {
                    color: #818898;
                    font-size: 14px;
                }
            }
        }

        /* ==============Skills=============== */
        .skills {
            border-radius: 10px;
            border: 1px solid #ECEFF3;
            background: #FFF;
            padding: 15px;

            .heading {
                border-bottom: 1px dashed #ECEFF3;
            }

            h4 {
                color: #36394A;
                font-size: 14px;
            }

            h5 {
                color: #666D80;
                font-size: 14px;
                text-transform: uppercase;
                border-radius: 999px;
                border: 1px solid #ECEFF3;
                background: #F6F8FA;
                padding: 8px 15px;
            }

            ul {
                li {
                    color: #36394A;
                    font-size: 15px;
                    font-weight: 400;
                }
            }
        }

        /* ==============Tools=============== */
        .tools {
            ul {
                padding: unset;

                li {
                    list-style: none;
                    padding: 0 4px;
                    margin: 3px 3px;
                    color: #36394A;
                    font-size: 15px;
                    border-radius: 6px;
                    border: 1px solid #ECEFF3;
                }
            }
        }

        /* ==============Portfolio=============== */
        .portfolio {
            h3 {
                margin: 5px 0 5px 0;
                color: #36394A;
                font-size: 16px;
                font-weight: 500;
            }

            p {
                color: #818898;
                font-size: 14px;
            }

            .portfolio-link {
                img {
                    width: 15px;
                    height: 15px;
                }

                p {
                    color: #5479FF;
                    font-size: 14px;
                }

            }

            .custm-port-info {
                padding: 5px;
                border-radius: 8px;
                background: #F6F8FA;
            }
        }

        /* ==============Language=============== */
        .language {
            h3 {
                margin: 5px 0 5px 0;
                color: #36394A;
                font-size: 16px;
                font-weight: 500;
            }

            .custm-lang {
                color: #666D80;
                font-size: 15px;
            }
        }

        /* ==============Education=============== */
        .education {
            .custm-education {
                h6 {
                    color: #36394A;
                    font-size: 15px;
                }

                p {
                    margin: unset;
                    color: #666D80;
                    font-size: 14px;
                }
            }
        }

        /* ==============Social Links=============== */
        .social {
            p {
                color: #666D80;
                font-size: 14px;

                a {
                    color: #36394A;
                    font-size: 14px;
                    text-decoration-line: underline;
                }
            }
        }
    }

    // ===================Continue Button==================
    .cstm-conti {
        a {
            border-radius: 40px;
            background: #1B375C;
            color: #FFF;
            text-align: center;
            text-decoration: none;
            font-size: 18px;
            padding: 10px 60px;
        }
    }
}



@media only screen and (min-width: 200px) and (max-width: 567px) {
    .profile-head {
        ul {
            display: block !important;
        }
    }

    .cv-info {
        .experience {
            .exp-info {
                display: block !important;

                ul {
                    display: block !important;
                }
            }
        }
    }
}