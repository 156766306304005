.learning-video-details-sec {
    .main-subs-plan-lernvido-dtl {

        .cus-head {
            width: 100%;
            height: 65px;
            flex-shrink: 0;
            border-radius: 4px;
            background: #F4FAFF;
            box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), 0px 4px 20px 0px rgba(29, 95, 181, 0.20) inset;

            h5 {
                color: #1B375C;
                font-family: Barlow;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 22px;
                text-transform: capitalize;
                padding: 20px 12px 12px 12px;
            }
        }

        .subscrip-card {
            border-radius: 4px;
            padding: 20px 20px 30px 20px;
            background: #FFF;
            box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;

            .cus-btn {

                button {
                    color: #0A65CC;
                    text-align: center;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                    /* 87.5% */
                    border: none;
                    background: none;
                }
            }

            .video-card {
                position: relative;
                overflow: hidden;
                padding-bottom: 56.25%;
                /* 16:9 aspect ratio */
                border-radius: 10px;
                padding-bottom: 33.25%;

                video {
                    position: absolute;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .cus-head-inside {
                h1 {
                    color: #141522;
                    // font-family: Plus Jakarta Sans;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%;
                    letter-spacing: -0.96px;
                }
            }

            .cus-sec {
                p {
                    color: #54577A;
                    // font-family: Plus Jakarta Sans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    letter-spacing: -0.28px;
                }

                .cus-pan {
                    color: #04A4F4;
                    // font-family: Plus Jakarta Sans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    letter-spacing: -0.28px;
                }
            }

            .cus-sec-2 {
                img {
                    // margin-top: 2px;
                }

                span {
                    color: #141522;
                    // font-family: Plus Jakarta Sans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    letter-spacing: -0.28px;
                }

            }

            .cus-inner-c {
                h1 {
                    color: #141522;
                    // font-family: Plus Jakarta Sans;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%;
                    /* 36px */
                    letter-spacing: -0.72px;
                }

                p {
                    color: #141522;
                    // font-family: Plus Jakarta Sans;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 200%;
                    /* 28px */
                }
            }


        }


    }

}



@media only screen and (min-width:200px) and (max-width:767px) {

    .learning-video-details-sec {
        .main-subs-plan-lernvido-dtl {
            .subscrip-card {
                .video-card {
                    padding-bottom: 52.25%;
                    video {
                        height: auto;
                    }
                }
                .cus-head-inside {
                    h1 {
                        font-size: 19px;
                    }
                }
                .cus-sec{
                    p {
                        font-size: 13px;
                    }
                    .cus-pan {
                        font-size: 13px;
                    }
                }
                .cus-sec-2 {
                    span {
                        font-size: 13px;
                    }
                }
            }
                
        }
    }


}