.make-it-deal-breaker-sec {
  margin: 40px 0px;
}
.make-it-deal-breaker-sec .text-center .go-back-to-job-posting {
  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.5);
  background: rgb(27, 55, 92);
  color: #fff;
  border-radius: 32px;
  padding: 10px 20px;
  border: none;
  margin-top: 4rem;
  text-decoration: none;
}
.make-it-deal-breaker-sec .create-an-emp-inner {
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
  box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  padding: 40px;
}
.make-it-deal-breaker-sec .create-an-emp-inner .heading h2 {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 28px;
}
.make-it-deal-breaker-sec .create-an-emp-inner .heading2 .maxi-cls {
  margin-bottom: 0px;
  text-decoration: none;
  font-weight: 600;
}
.make-it-deal-breaker-sec .create-an-emp-inner .after-main-head .after-main-atag {
  font-weight: 600;
  text-decoration: none;
  font-size: 17px;
}
.make-it-deal-breaker-sec .create-an-emp-inner .after-main-head p {
  margin-bottom: 0;
  padding: 10px 0px;
}
.make-it-deal-breaker-sec .create-an-emp-inner .heading-inner h3 {
  font-size: 22px;
  font-weight: 600;
}
.make-it-deal-breaker-sec .create-an-emp-inner .question-parent-dv {
  margin: 20px 0px;
}
.make-it-deal-breaker-sec .create-an-emp-inner .question-parent-dv .question-options {
  box-shadow: 0px 6px 16px 0px #E9E9E9;
  padding: 10px 20px;
}
.make-it-deal-breaker-sec .create-an-emp-inner .question-parent-dv .question-options .question-selection {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.make-it-deal-breaker-sec .create-an-emp-inner2 {
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
  box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  padding: 40px;
  margin: 20px 0px;
}
.make-it-deal-breaker-sec .create-an-emp-inner2 .accordion-item .accordion-header .accordion-button {
  font-size: 18px;
  font-weight: 600;
}
.make-it-deal-breaker-sec .create-an-emp-inner2 .accordion-item .accordion-collapse .accordion-body .accordion-body-small-card .accordion-body-innner-card {
  background: #F6F6F6;
  padding: 20px 15px;
  border-radius: 10px;
}
.make-it-deal-breaker-sec .create-an-emp-inner2 .accordion-item .accordion-collapse .accordion-body .accordion-body-small-card .accordion-body-innner-card .card-heading {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.make-it-deal-breaker-sec .create-an-emp-inner2 .accordion-item .accordion-collapse .accordion-body .accordion-body-small-card .accordion-body-innner-card .card-heading .border-none-btn {
  border: none;
}
.make-it-deal-breaker-sec .create-an-emp-inner2 .accordion-item .accordion-collapse .accordion-body .accordion-body-small-card .accordion-body-innner-card .card-heading .card-a-head {
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  border: none;
  background: transparent;
}
.make-it-deal-breaker-sec .create-an-emp-inner2 .pre-recorded-dv-parent {
  padding: 0px 20px 20px 20px;
}
.make-it-deal-breaker-sec .create-an-emp-inner2 .pre-recorded-dv-parent .pre-rocorded-dv {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.make-it-deal-breaker-sec .create-an-emp-inner2 .pre-recorded-dv-parent .pre-rocorded-dv .pre-inner input {
  margin-right: 5px;
}
.make-it-deal-breaker-sec .create-an-emp-inner2 .pre-recorded-dv-parent .pre-rocorded-dv .see-all-question .pre-inner-a {
  text-decoration: none;
  font-weight: 600;
}
.make-it-deal-breaker-sec .outside-page-submit-btn .bottom-button-dv {
  margin: 40px 0px;
}
.make-it-deal-breaker-sec .outside-page-submit-btn .bottom-button-dv .bottom-btn .a-tag-btn1 {
  border: 1px solid rgb(27, 55, 92);
  text-decoration: none;
  border-radius: 40px;
  color: #1B375C;
  padding: 10px 50px;
}
.make-it-deal-breaker-sec .outside-page-submit-btn .bottom-button-dv .bottom-btn .a-tag-btn2 {
  border: 1px solid rgb(27, 55, 92);
  text-decoration: none;
  border-radius: 40px;
  background: rgb(27, 55, 92);
  color: #fff;
  padding: 10px 40px;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .make-it-deal-breaker-sec .create-an-emp-inner {
    padding: 10px;
  }
  .make-it-deal-breaker-sec .create-an-emp-inner .heading h2 {
    text-align: center;
  }
  .make-it-deal-breaker-sec .create-an-emp-inner .heading2 {
    text-align: center;
  }
  .make-it-deal-breaker-sec .create-an-emp-inner .heading {
    text-align: center !important;
  }
  .make-it-deal-breaker-sec .create-an-emp-inner .bottom-button-dv {
    text-align: center;
    margin: 40px 0px;
  }
  .make-it-deal-breaker-sec .create-an-emp-inner .bottom-button-dv .bottom-btn {
    margin: 10px 0px;
    text-align: center;
  }
  .make-it-deal-breaker-sec .create-an-emp-inner2 {
    box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
    box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
    padding: 10px;
    margin: 20px 0px;
  }
  .make-it-deal-breaker-sec .create-an-emp-inner .question-parent-dv .assement-button-pad {
    padding: 0px 3px;
  }
  .make-it-deal-breaker-sec .create-an-emp-inner .question-parent-dv .assement-button-pad i {
    font-size: 10px;
  }
}/*# sourceMappingURL=Questionnaire.css.map */