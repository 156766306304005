.assessment-question-sec {

    .color {
        background: #1B375C;
        -webkit-appearance: auto;
        -moz-appearance: auto;
        appearance: auto;
    }

    .cust_text {
        color: #1B375C;
        font-size: 25px;
    }

    .cust_box {
        -webkit-box-shadow: inset -1px -4px 5px 7px rgba(240, 231, 240, 0.62);
        -moz-box-shadow: inset -1px -4px 5px 7px rgba(240, 231, 240, 0.62);
        box-shadow: inset -1px -4px 5px 7px rgba(240, 231, 240, 0.62);

    }

    .cust-margin_whole {
        margin: 5px;
    }

    .cust_detail {
        /* -webkit-box-shadow: 0px 3px 2px 7px rgba(240,235,240,0.42);
-moz-box-shadow: 0px 3px 2px 7px rgba(240,235,240,0.42);
box-shadow: 0px 3px 2px 7px rgba(240,235,240,0.42); */
        box-shadow: 0px 6px 16px 0px #DFDFDF6B;

        margin-left: 0px;
        margin-right: 0px;
        padding: 0px;
        border-radius: 10px;
        border: 1px solid #DFDFDF6B;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: center;

        min-width: auto;
        min-height: 60px;
        text-align: center;

        a {
            text-decoration: none;
            color: #000;
            .text{
                margin-left: 10px;
            }
        }
    }

    /* Answered */
    .ans {
        font-size: 16px;
        font-weight: 500;
        padding: 2px;
    }

    /* not answered */
    .notans {
        font-size: 16px;
        font-weight: 500;
        padding: 2px;
    }

    .cust_form {
        -webkit-box-shadow: inset 4px -3px 8px 2px rgba(240, 235, 240, 0.3);
        -moz-box-shadow: inset 4px -3px 8px 2px rgba(240, 235, 240, 0.3);
        box-shadow: inset 4px -3px 8px 2px rgba(240, 235, 240, 0.3);
        margin-left: 10px;
        margin: 5px;
    }

    .cust_flex {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
    }

    /* Timmer test */
    .timer {
        color: #000;
        font-size: 18px;
        font-weight: 600;
        line-height: normal;

    }

    .ques_heading {
        color: #000;
        font-size: 18px;
        font-weight: 600;
        line-height: normal;
    }

    .cust_ques {
        -webkit-box-shadow: -7px 7px 8px 2px rgba(240, 235, 240, 0.58);
        -moz-box-shadow: -7px 7px 8px 2px rgba(240, 235, 240, 0.58);
        box-shadow: -7px 7px 8px 2px rgba(240, 235, 240, 0.58);
        margin: 5px 0px 0px 0px;
        padding: 8px;
        border: 2px solid #1B375C;
        border-radius: 5px;
    }

    .cust_list {
        -webkit-box-shadow: -7px 7px 8px 2px rgba(240, 235, 240, 0.58);
        -moz-box-shadow: -7px 7px 8px 2px rgba(240, 235, 240, 0.58);
        box-shadow: -7px 7px 8px 2px rgba(240, 235, 240, 0.58);
        margin: 5px 0px 0px 0px;
        padding: 8px;
        label{
            width: 100%;
        }
    }

    .cust_button1 {
        width: 140px;
        height: 45px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 5px;
        -webkit-box-shadow: -2px 7px 8px 9px rgba(240, 235, 240, 0.56);
        -moz-box-shadow: -2px 7px 8px 9px rgba(240, 235, 240, 0.56);
        box-shadow: -2px 7px 8px 9px rgba(240, 235, 240, 0.56);
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 25px;
    }

    .cust_button2 {
        width: 140px;
        height: 45px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 5px;
        -webkit-box-shadow: -2px 7px 8px 9px rgba(240, 235, 240, 0.56);
        -moz-box-shadow: -2px 7px 8px 9px rgba(240, 235, 240, 0.56);
        box-shadow: -2px 7px 8px 9px rgba(240, 235, 240, 0.56);
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .cust_buttonsub {
        -webkit-box-shadow: inset -2px 7px 8px 9px rgba(240, 235, 240, 0.65);
        -moz-box-shadow: inset -2px 7px 8px 9px rgba(240, 235, 240, 0.65);
        box-shadow: inset -2px 7px 8px 9px rgba(240, 235, 240, 0.65);
    }

    /*  */
    .cust_head {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-content: center;
        margin-top: 25px;
        margin-bottom: 25px;
        margin-left: 20px;
        margin-right: 25px;
        align-items: center;

    }

    .ques {
        box-shadow: 0px 6px 16px 0px #DFDFDF6B;
        border: 1px solid #DFDFDF6B;
        margin-left: 0px;
        margin-right: 0px;
        padding: 5px;
        margin-top: 20px;
        border-radius: 10px;
    }

    .cust_para {
        color: #1D2026;
        font-weight: 500;
        line-height: 20.5px;
        p{
            margin-bottom: 0px;
        }
    }

    .ques-section {
        line-height: 2.5;
        word-spacing: 5.5px;
        text-align: center;
        button{
            margin: 5px 10px;
            padding: 0px;
            border-radius: 50% !important;
            height: 40px;
            width: 40px;
            line-height: 30px;
            text-align: center;
        }
    }


    .cust_form {
        margin-left: 0;
        margin: 0px;

        /* .cust_ques{
        margin:0px 0px 0px 0px ;
        padding: 0px;

    } */
        .cust_head {
            margin-top: 25px;
            margin-bottom: 25px;
            margin-left: 0px;
            margin-right: 0px;
        }

        .ques {
            margin-left: 0px;
            margin-right: 0px;
            padding: 0px;
            margin-top: 10px;
        }

        .ques-section {
            word-spacing: 2.5;
        }

    }

}

@media screen and ((min-width:320px) and (max-width:576px)) {
    .assessment-question-sec {
        .cust_text {
            font-size: 18px;
        }

        .ques_heading {
            font-size: 18px;
        }

        .timer {
            font-size: 18px;
        }

        .cust_head {
            margin-left: 5px;
            margin-right: 5px;
        }
    }

}

@media screen and (min-width:1440px) {
    .assessment-question-sec {
        .ques-section {
            line-height: 3.5;
            word-spacing: 29.5px;
            text-align: center;
        }
    }
}

@media screen and (max-width:768px) {
    .assessment-question-sec {
        .ques-section {
            line-height: 2.5;
            word-spacing: 4.5px;
            text-align: center;
        }
    }
}