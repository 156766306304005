//  =========================add-job-titles-body-parent start=============================
.add-job-titles-body-parent.show {
    backdrop-filter: blur(1.5rem);
}

.add-job-titles-body-parent {
    .modal-header {
        h1 {
            font-size: 22px;
        }
    }

    .add-job-types-body {
        h4 {
            font-size: 19px;
            font-weight: 600;
        }

        .input-seletion-dv {
            display: flex;
            padding: 6px 0px;

            input {
                width: 22px;
                height: 22px;
            }

            label {
                margin-left: 10px;
                font-size: 16px;
            }
        }
    }

    .cancel-btn {
        padding: 8px 17px;
        font-size: 14px;
        border-radius: 50px;
        border: 1px solid rgba(10, 101, 204, 0.5);
        background-color: unset;
        text-decoration: none;
        transition: all 0.5s ease-in-out;
        color: #000;

        &:hover {
            background-color: #0067cc;
            color: #fff;
        }
    }

    .save-btn {
        padding: 8px 17px;
        font-size: 14px;
        border-radius: 50px;
        border: 1px solid rgba(10, 101, 204, 0.5);
        background-color: #0067cc;
        text-decoration: none;
        transition: all 0.5s ease-in-out;
        color: #fff;

        &:hover {
            background-color: transparent;
            color: #000;
        }
    }
}

//  =========================add-job-titles-body-parent end============================= 
//  =========================add-job-types-body-parent start=============================
.add-job-types-body-parent.show {
    backdrop-filter: blur(1.5rem);
}

.add-job-types-body-parent {
    .modal-header {
        h1 {
            font-size: 22px;
        }
    }

    .add-job-types-body {
        h4 {
            font-size: 19px;
            font-weight: 600;
        }

        .input-seletion-dv {
            display: flex;
            padding: 6px 0px;

            input {
                width: 22px;
                height: 22px;
            }

            label {
                margin-left: 10px;
                font-size: 16px;
            }
        }
    }

    .cancel-btn {
        padding: 8px 17px;
        font-size: 14px;
        border-radius: 50px;
        border: 1px solid rgba(10, 101, 204, 0.5);
        background-color: unset;
        text-decoration: none;
        transition: all 0.5s ease-in-out;
        color: #000;

        &:hover {
            background-color: #0067cc;
            color: #fff;
        }
    }

    .save-btn {
        padding: 8px 17px;
        font-size: 14px;
        border-radius: 50px;
        border: 1px solid rgba(10, 101, 204, 0.5);
        background-color: #0067cc;
        text-decoration: none;
        transition: all 0.5s ease-in-out;
        color: #fff;

        &:hover {
            background-color: transparent;
            color: #000;
        }
    }
}

//  =========================add-job-types-body-parent end============================= 

//  =========================Add work schedule start============================= 
.add-work-schedule-body-parent.show {
    backdrop-filter: blur(1.5rem);
}

.add-work-schedule-body-parent {
    .add-work-schedule-body {
        height: 50vh;
        overflow-y: scroll;
    }

    .modal-header {
        h1 {
            font-size: 22px;
        }
    }

    .add-job-types-body {
        h4 {
            font-size: 19px;
            font-weight: 600;
        }

        h5 {
            font-size: 19px;
            font-weight: 600;
            padding-top: 30px;
        }

        .input-seletion-dv {
            display: flex;
            padding: 6px 0px;

            input {
                width: 22px;
                height: 22px;
            }

            label {
                margin-left: 10px;
                font-size: 16px;
            }
        }
    }

    .cancel-btn {
        padding: 8px 17px;
        font-size: 14px;
        border-radius: 50px;
        border: 1px solid rgba(10, 101, 204, 0.5);
        background-color: unset;
        text-decoration: none;
        transition: all 0.5s ease-in-out;
        color: #000;

        &:hover {
            background-color: #0067cc;
            color: #fff;
        }
    }

    .save-btn {
        padding: 8px 17px;
        font-size: 14px;
        border-radius: 50px;
        border: 1px solid rgba(10, 101, 204, 0.5);
        background-color: #0067cc;
        text-decoration: none;
        transition: all 0.5s ease-in-out;
        color: #fff;

        &:hover {
            background-color: transparent;
            color: #000;
        }
    }
}

//  =========================Add work schedule end============================= 
//  =========================Add Pay Body Parent schedule start============================= 
.add-pay-body-parent.show {
    backdrop-filter: blur(1.5rem);
}

.add-pay-body-parent {
    .add-work-schedule-body {
        height: 50vh;
        overflow-y: scroll;
    }

    .modal-header {
        h1 {
            font-size: 22px;
        }
    }

    .add-job-types-body {
        h4 {
            font-size: 19px;
            font-weight: 600;
        }

        h5 {
            font-size: 19px;
            font-weight: 600;
            padding-top: 30px;
        }

        .eye-notice {
            background-color: rgb(238, 241, 254);
            border: 1px solid rgb(199, 210, 246);
            padding: 20px 10px;
            border-radius: 5px;

            p {
                margin-bottom: 0;

                svg {
                    inline-size: 1.5rem;
                    block-size: 1.5rem;
                    color: rgb(63, 115, 211);
                }

                span {
                    margin-left: 20px;
                }
            }
        }

        .minimum-base-pay-inpt {
            margin-top: 20px;

            label {
                font-weight: 600;
            }
        }

        .input-seletion-dv {
            display: flex;
            padding: 6px 0px;

            input {
                width: 22px;
                height: 22px;
            }

            label {
                margin-left: 10px;
                font-size: 16px;
            }
        }
    }

    .cancel-btn {
        padding: 8px 17px;
        font-size: 14px;
        border-radius: 50px;
        border: 1px solid rgba(10, 101, 204, 0.5);
        background-color: unset;
        text-decoration: none;
        transition: all 0.5s ease-in-out;
        color: #000;

        &:hover {
            background-color: #0067cc;
            color: #fff;
        }
    }

    .save-btn {
        padding: 8px 17px;
        font-size: 14px;
        border-radius: 50px;
        border: 1px solid rgba(10, 101, 204, 0.5);
        background-color: #0067cc;
        text-decoration: none;
        transition: all 0.5s ease-in-out;
        color: #fff;

        &:hover {
            background-color: transparent;
            color: #000;
        }
    }
}

//  =========================Add Pay Body Parent schedule end============================= 
//  =========================add relocation body parent start=============================
.add-relocation-body-parent.show {
    backdrop-filter: blur(1.5rem);
}
.add-relocation-body-parent {
    .modal-header {
        h1 {
            font-size: 22px;
        }
    }

    .add-job-types-body {
        h4 {
            font-size: 19px;
            font-weight: 600;
        }

        .input-seletion-dv {
            display: flex;
            padding: 6px 0px;

            input {
                width: 22px;
                height: 22px;
            }

            label {
                margin-left: 10px;
                font-size: 16px;
            }
        }
    }

    .cancel-btn {
        padding: 8px 17px;
        font-size: 14px;
        border-radius: 50px;
        border: 1px solid rgba(10, 101, 204, 0.5);
        background-color: unset;
        text-decoration: none;
        transition: all 0.5s ease-in-out;
        color: #000;

        &:hover {
            background-color: #0067cc;
            color: #fff;
        }
    }

    .save-btn {
        padding: 8px 17px;
        font-size: 14px;
        border-radius: 50px;
        border: 1px solid rgba(10, 101, 204, 0.5);
        background-color: #0067cc;
        text-decoration: none;
        transition: all 0.5s ease-in-out;
        color: #fff;

        &:hover {
            background-color: transparent;
            color: #000;
        }
    }
}
//  =========================add relocation body parent end============================= 
//  =========================add remote body parent start=============================
.add-remote-body-parent.show {
    backdrop-filter: blur(1.5rem);
}
.add-remote-body-parent {
    .modal-header {
        h1 {
            font-size: 22px;
        }
    }

    .add-job-types-body {
        h4 {
            font-size: 19px;
            font-weight: 600;
        }

        .input-seletion-dv {
            display: flex;
            padding: 6px 0px;

            input {
                width: 22px;
                height: 22px;
            }

            label {
                margin-left: 10px;
                font-size: 16px;
            }
        }
    }

    .cancel-btn {
        padding: 8px 17px;
        font-size: 14px;
        border-radius: 50px;
        border: 1px solid rgba(10, 101, 204, 0.5);
        background-color: unset;
        text-decoration: none;
        transition: all 0.5s ease-in-out;
        color: #000;

        &:hover {
            background-color: #0067cc;
            color: #fff;
        }
    }

    .save-btn {
        padding: 8px 17px;
        font-size: 14px;
        border-radius: 50px;
        border: 1px solid rgba(10, 101, 204, 0.5);
        background-color: #0067cc;
        text-decoration: none;
        transition: all 0.5s ease-in-out;
        color: #fff;

        &:hover {
            background-color: transparent;
            color: #000;
        }
    }
}
//  =========================add remote body parent end============================= 