.onboarding-guide p {
  max-height: 250px;
  overflow-y: auto;
}
.onboarding-guide .img-area h3 {
  color: var(--Dark-blue, #282938);
  font-size: 24px;
  padding: 15px 0;
  height: 80px;
}
.onboarding-guide .img-area a {
  color: #0A65CC;
  font-size: 16px;
  text-decoration: none;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .onboarding-guide {
    padding-top: 0px !important;
  }
  .onboarding-guide .img-area {
    margin: 0 0 20px 0;
  }
  .onboarding-guide .img-area h3 {
    font-size: 18px;
    height: auto;
  }
  .onboarding-guide .img-area a {
    color: #0A65CC;
    font-size: 14px;
    text-decoration: none;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .onboarding-guide {
    padding-top: 0px !important;
  }
  .onboarding-guide .img-area {
    margin: 0 0 20px 0;
  }
  .onboarding-guide .img-area h3 {
    font-size: 16px;
    height: auto;
  }
  .onboarding-guide .img-area a {
    color: #0A65CC;
    font-size: 14px;
    text-decoration: none;
  }
}/*# sourceMappingURL=onboard.css.map */