.choose-your-plan-sec {
    .main-head {
        padding: 1px;
        border-radius: 4px;
        background: #F4FAFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), 0px 4px 20px 0px rgba(29, 95, 181, 0.20) inset;

        h2 {
            color: #1B375C;
            font-size: 22px;
            font-style: normal;
            margin-top: 20px;
            line-height: 54px;
            text-align: center;
            text-transform: capitalize;
        }
    }

    .main-section {
        padding: 40px;
    }

    .first-head {
        h2 {
            color: #1B375C;
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 137.5%;
            margin-top: 152px;
        }

        span {
            color: #0A65CC;
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 137.5%;

        }
    }

    .banner-section {
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
        .card-section{
            padding: 0px 0px 80px 0px;
        }
    }

    .banner-second {
        padding: 55px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .top-head {
        padding: 40px 0px;
        h1 {
            color: #1B375C;
            text-align: center;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.84px;
            margin-top: 20px;
        }

        p {
            color: #1B375C;
            text-align: center;
            font-size: 16px;
            font-weight: 400;

            letter-spacing: -0.36px;
        }
    }

    .search-list {
        li {
            list-style: none;
            margin-top: 20px;
            text-align: left;
            margin-bottom: 15px;
            position: relative;
            margin-left: 35px;
            &::before {
                position: absolute;
                content: url("../../../../../public/assets/images_another/icon-list.png") ;
                left: 0;
                top: 5px;
                color: #f15a24;
                font-size: 12px;
                margin-left: -35px;
                // font-family: fontawesome;
            }
        }

        ul {
            padding: unset;
        }
    }

    .search-list2 {
        li {
            list-style: none;
            margin-top: 20px;
            color: #1B375C;
            text-align: left;
            margin-bottom: 15px;
            position: relative;
            margin-left: 35px;
            &::before {
                position: absolute;
                content: url("../../../../../public/assets/images_another/icon-list.png") ;
                left: 0;
                top: 5px;
                color: #f15a24;
                font-size: 12px;
                margin-left: -35px;
                // font-family: fontawesome;
            }
        }

        ul {
            padding: unset;
        }
    }

    .search-btn {
        button {
            border-radius: 8px;
            border: 1px solid #B7D6FF;
            background: #FFF;
            padding: 16px 40px;
            justify-content: center;
            align-items: center;
            color: #212529;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: -0.32px;
        }

    }

    .search-btn2 {
        button {
            border-radius: 8px;
            border: 1px solid #B7D6FF;
            background: #FFF;
            padding: 16px 40px;
            justify-content: center;
            align-items: center;
            color: #212529;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: -0.32px;
        }
    }

    .card-search {
        padding: 29px;
        border-radius: 10px;
        border: 1px solid #B7D6FF;
        background: #FFF;
        transform: scale(0.9);
        transition: all 0.5s ease-in-out;
        &:hover{
            transform: scale(1);
            z-index: 1;
            box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.08);
            .search-btn button{
                border-radius: 8px;
                border: 1px solid #B7D6FF;
                background: #1B375C;
                padding: 16px 40px;
                justify-content: center;
                align-items: center;
                color: #ffffff;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: -0.32px;
            }
        }
    }

    .card-first-head {
        h4 {
            color: #212529;
            font-size: 24px;
            font-weight: 600;
            align-self: stretch;
            text-align: left;
        }

        h3 {
            color: #212529;
            font-size: 36px;
            font-weight: 700;
            text-align: left;
        }
    }

    .card-first-head2 {
        h4 {
            color: #1B375C;
            font-size: 24px;
            font-weight: 600;
            align-self: stretch;
            text-align: left;
        }

        h3 {
            color: #1B375C;
            font-size: 36px;
            font-weight: 700;
            text-align: left;
        }
    }

    .card-second-head {
        h5 {
            color: #212529;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 23px;
            text-align: left;
        }
    }

    .card-second-head2 {
        h5 {
            color: #1B375C;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: -0.36px;
        }
    }

    .card-scale {
        &:hover{
            transform: scale(1);
            z-index: 1;
            box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.08);
            .search-btn2 button{
                border-radius: 8px;
                border: 1px solid #B7D6FF;
                background: #1B375C;
                padding: 16px 40px;
                justify-content: center;
                align-items: center;
                color: #ffffff;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: -0.32px;
            }
        }
        transition: all 0.5s ease-in-out;
    }




}




@media only screen and (min-width:200px) and (max-width:767px) {
    .choose-your-plan-sec{
        .main-section {
            padding: 10px;
        }
    
        .first-head {
            h2 {
                font-size: 18px;
            }
    
            span {
                font-size: 18px;
            }
        }
        .card-scale {
            transform: scale(0.9);
            z-index: 1;
            padding: 45px;
            box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.08);
        }
    }
}
@media only screen and (min-width:767px) and (max-width:992px) {
    .choose-your-plan-sec{
        .main-section {
            padding: 10px;
        }
    
        .first-head {
            h2 {
                font-size: 18px;
            }
    
            span {
                font-size: 18px;
            }
        }
        .card-scale {
            transform: scale(0.9);
            z-index: 1;
            padding: 45px;
            box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.08);
        }
    }
}