.reviews-section .box-rounded svg {
  fill: #9d2b6b;
}
.reviews-section .progress-bar {
  background-color: #9d2b6b;
}
.reviews-section .progress {
  height: 8px;
}
.reviews-section .border-bottom {
  border-bottom: 1px solid #e4e2e0;
}
.reviews-section span {
  font-size: 13px;
}
.reviews-section .border-dotted {
  border-bottom: 1px dashed #949494;
}
.reviews-section .share-btn {
  padding: 8px 18px;
  border-radius: 0.5rem;
  font-size: 16px;
  font-weight: 500;
  background: none;
  border: none;
}
.reviews-section .share-btn svg {
  fill: #767676 !important;
  width: 20px !important;
  height: 20px !important;
}
.reviews-section .share-btn:hover,
.reviews-section .rating-btn:hover {
  color: rgb(22, 64, 129);
  background-color: rgb(238, 241, 254);
}
.reviews-section .rating-btn {
  padding: 5px 18px;
  border-radius: 0.5rem;
  font-size: 16px;
  font-weight: 500;
  background: #f3f2f1;
  border: none;
}
.reviews-section .feedback p {
  font-size: 14px;
  font-weight: 400;
  color: #595959;
  line-height: 24px;
}
.reviews-section .feedback svg {
  width: 13px;
  height: 15px;
  fill: #9d2b6b;
}
.reviews-section .useful-review {
  background: #eef1fe;
  border-radius: 8px;
  padding: 12px 15px;
}
.reviews-section .useful-review span {
  color: #164081;
}
.reviews-section .useful-review h2 {
  color: #164081;
  font-weight: 600;
  font-size: 17px;
}
.reviews-section .review-box-container {
  flex-wrap: wrap;
}
.reviews-section .review-box-container .review-box {
  padding: 6px 14px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  background: #f3f2f1;
}
.reviews-section h3 {
  color: #2d2d2d;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.25;
}
.reviews-section h5,
.reviews-section h4 {
  font-weight: 400;
  color: #595959;
}
.reviews-section h5 {
  font-size: 18px;
}
.reviews-section h4 {
  font-size: 16px;
}
.reviews-section .rounded-t {
  border-radius: 0.5rem 0.5rem 0px 0px;
}
.reviews-section .rounded-b {
  border-radius: 0px 0px 0.5rem 0.5rem;
}
.reviews-section .form-section,
.reviews-section .form-section-2 {
  padding: 1.5rem 1rem 1.5rem;
  background-color: #f3f2f1;
}
.reviews-section .form-section .sort-rating,
.reviews-section .form-section-2 .sort-rating {
  border-left: 1px solid #b6b6b6;
  border-radius: 6px 0 0 6px;
  border-right: 1px solid #b6b6b6;
}
.reviews-section .form-section .date,
.reviews-section .form-section-2 .date {
  border-radius: 0px 6px 6px 0px;
  border-right: 1px solid #b6b6b6;
}
.reviews-section .form-section .sort-rating,
.reviews-section .form-section .date,
.reviews-section .form-section-2 .sort-rating,
.reviews-section .form-section-2 .date {
  padding: 6px 14px;
  border-top: 1px solid #b6b6b6;
  border-bottom: 1px solid #b6b6b6;
  background-color: #fff;
  font-size: 15px;
  font-weight: 400;
  color: #000;
}
.reviews-section .form-section label,
.reviews-section .form-section-2 label {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 6px;
}
.reviews-section .form-section .rating,
.reviews-section .form-section-2 .rating {
  display: flex;
  flex-wrap: wrap;
  gap: 11px;
}
.reviews-section .form-section .rating .box,
.reviews-section .form-section-2 .rating .box {
  border: 1px solid #b6b6b6;
  padding: 6px 14px;
  background: #fff;
  border-radius: 50px;
}
.reviews-section .form-section .rating span,
.reviews-section .form-section-2 .rating span {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}
.reviews-section .form-section .rating svg,
.reviews-section .form-section-2 .rating svg {
  width: 20px;
  fill: #9d2b6b;
}

.claimed-profile h4 {
  color: #595959;
  font-weight: 600;
  font-size: 16px;
}
.claimed-profile svg {
  fill: #1f662c;
  width: 25px;
}

.update-button {
  border: 1px solid #4771c0;
  background: none;
  border-radius: 0.5rem;
  background-color: white;
  padding: 6px 14px;
  font-size: 16px;
  font-weight: 600;
  color: #4771c0;
}

.update-button:hover {
  background-color: rgba(59, 124, 227, 0.0705882353);
}

.accordion h4 {
  font-size: 16px !important;
  font-weight: 600;
  line-height: 1.5;
  color: #2d2d2d;
}

.rate-recent-company {
  background: #eef1fe;
  border-radius: 8px;
  padding: 30px 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rate-recent-company .rate-star {
  background: #eef1fe;
  border-radius: 6px;
  padding: 6px 15px;
  width: -moz-fit-content;
  width: fit-content;
}
.rate-recent-company p {
  font-size: 14px;
}
.rate-recent-company h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5rem;
  color: #2d2d2d;
}
.rate-recent-company svg {
  width: 25px !important;
  height: 25px !important;
}

.box-rounded {
  border-radius: 0.5rem;
  padding: 1.5rem;
  border: 1px solid #d4d2d0;
}
.box-rounded h4 {
  font-size: 16px !important;
  font-weight: 600;
  line-height: 1.5;
  color: #2d2d2d;
}
.box-rounded p {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.5;
}

#loginPopUpModal button.popup-login-btn {
  padding: 5px 20px;
  border-radius: 40px;
  background: #1B375C;
  text-decoration: none;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #1B375C;
  width: -moz-fit-content;
  width: fit-content;
}

@media screen and (max-width: 992px) {
  .custgaps {
    row-gap: 15px;
    -moz-column-gap: 15px;
         column-gap: 15px;
  }
  .rating {
    display: flex;
    flex-direction: column;
  }
}/*# sourceMappingURL=Reviews.css.map */