@media only screen and (min-width:768px) and (max-width:992px) {
    .job-status-content-section {
        .open-closed-btn {
            .a-opn-cls-btn {
                margin-right: 30px;
                text-decoration: none;
                color: #000;
                // display: block;

                &:hover {
                    text-decoration: revert;
                    color: #0090FF;
                }
            }

            .a-opn-cls-btn.active {
                text-decoration: revert;
                color: #0090FF;
            }
        }

        .candidate-pro-section-inner-dv {
            .post-description-start-set {
                .post-select-dropdown {
                    .dropdown-toggle,#post-select-open,#post-select-closed{
                        padding: 3px 18px;
                    }
                }
            }

            .post-job-four-small-card {
                .count-card {
                    background: #fff;
                    padding: 18px 4px;
                    border: 1px solid #ccc;
                    text-align: center;
                    width: 30%;
                }
            }

            .postwise-active-inactive-card-parent {
                padding: 12px 10px;
                background: linear-gradient(0deg, #F8F8F8, #F8F8F8);
                box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5019607843);
            }
        }
    }

}

@media only screen and (min-width:200px) and (max-width:767px) {
    .job-status-content-section {
        .candidate-pro-section-inner-dv {
            .post-description-start-set .post-select-dropdown {
                padding: 7px;
              
            }

            .post-job-four-small-card {
                .count-card {
                    background: #fff;
                    padding: 0px 0px;
                    border: 1px solid #ccc;
                    text-align: center;
                    width: 50%;
p{
    text-align: center;
}
                    p {
                        &:first-child {
                            font-size: 14px;
                           
                        }
                    }
                }
            }

            .right-side-search-filter-btns {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: center;
                justify-content: flex-start;
                align-items: center;
                margin: 30px 0px;

                .margin-rgt-lft {
                    margin-left: 0px;
                    margin-top: 5px;
                }
            }
        }
    }
}


// =======================Media queries============================


@media only screen and (min-width:200px) and (max-width:567px) {
    .candidate-pro-section-inner-dv {
        .post-description-start-set .post-select-dropdown {
           justify-content: center !important;
          
        }}
.post-select-dropdown{
    justify-content: center !important;
}
    .job-status-content-section {
        .open-closed-btn {
            display: flex;
            justify-content: space-between;
        }

        .candidate-pro-section-inner-dv {
            .post-job-four-small-card {
                flex-direction: column;
            }

            .candidate-pro-btn {
                .post-btn {
                    padding: 10px 16px;
                }
            }
        }
    }
}