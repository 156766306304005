.faq-sec {
    margin: 10px 0px;
    .main-head {
        padding: 1px;
        border-radius: 4px;
        background: #F4FAFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), 0px 4px 20px 0px rgba(29, 95, 181, 0.20) inset;

        h2 {
            color: #1B375C;
            /*  */
            font-size: 22px;
            font-style: normal;
            margin-top: 20px;
            /* font-weight: 700; */
            line-height: 54px;
            text-align: center;
            text-transform: capitalize;

        }
    }

    .main-section {
        padding: 40px;
    }



    .first-head h2 {
        color: #1B375C;
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        // margin-top: 152px;
    }

    .first-head span {
        color: #0A65CC;
        /*  */
        font-size: 38px;
        font-style: normal;
        font-weight: 700;
        line-height: 137.5%;

    }

    .banner-section {
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
        .main-container {
          min-height: 100vh;
          display: flex;
          display: -webkit-flex;
          align-items: center;
          -webkit-align-items: center;
          height: 560px;
          overflow: hidden;
        }
        .main {
          margin: 0px auto;
          width: 440px;
          height: 440px;
          position: relative;
        }
        .big-circle {
          height: 100%;
          width: 100%;
          position: relative;
          border: 1.68px dashed #0B6C81 ;
          border-radius: 50%;
          display: flex;
          display: -webkit-flex;
          align-items: center;
          -webkit-align-items: center;
          justify-content: center;
          -webkit-justify-content: center;
          animation: Rotate 20s linear infinite;
          -webkit-animation: Rotate 20s linear infinite;
        }
        .icon-block {
          width: 64px;
          height: 64px;
          position: absolute;
          border-radius: 50%;
          display: flex;
          display: -webkit-flex;
          align-items: center;
          -webkit-align-items: center;
          justify-content: center;
          -webkit-justify-content: center;
        //   background-image: linear-gradient(180deg, #4967e6 0%, #627bf4 100%);
        //   -webkit-background-image: linear-gradient(180deg, #4967e6 0%, #627bf4 100%);
          box-shadow: 0 2px 4px 0 #3e5ada;
          -webkit-box-shadow: 0 2px 4px 0 #3e5ada;
        }
        .icon-block img {
          margin: 0px auto;
          width: 100%;
          animation: Rotate-reverse 20s linear infinite;
          -webkit-animation: Rotate-reverse 20s linear infinite;
        }
        .icon-block:first-child {
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
        }
        .icon-block:nth-child(2) {
          top: 50%;
          right: 0;
          transform: translate(50%, -50%);
          -webkit-transform: translate(50%, -50%);
        }
        .icon-block:nth-child(3) {
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 50%);
          -webkit-transform: translate(-50%, 50%);
        }
        .icon-block:nth-child(4) {
          top: 50%;
          left: 0;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
        }
        /* circle content */
        .circle {
          animation: circle-rotate 20s linear infinite;
          -webkit-animation: circle-rotate 20s linear infinite;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          -webkit-transform: translate(-50%, -50%) rotate(45deg);
          width: 75%;
          height: 75%;
          border: 1.68px dashed #0B6C81 ;
          border-radius: 50%;
        }
        .circle .icon-block img {
          animation: img-rotate 20s linear infinite;
          -webkit-animation: img-rotate 20s linear infinite;
        }
        /* center logo */
        .center-logo {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
        }
        .center-logo img {
          max-width: 200px;
        }
        .circle-second{
            .icon-block{
                img{
                    transform: rotate(-45deg);
                }
            }
        }
        
        /* keyframe animation */
        
        // @keyframes Rotate {
        //   from {
        //     transform: rotate(0deg);
        //   }
        //   to {
        //     transform: rotate(360deg);
        //   }
        // }
        
        // @-webkit-keyframes Rotate {
        //   from {
        //     -webkit-transform: rotate(0deg);
        //   }
        //   to {
        //     -webkit-transform: rotate(360deg);
        //   }
        // }
        
        // @keyframes Rotate-reverse {
        //   from {
        //     transform: rotate(360deg);
        //   }
        //   to {
        //     transform: rotate(0deg);
        //   }
        // }
        
        // @-webkit-keyframes Rotate-reverse {
        //   from {
        //     -webkit-transform: rotate(360deg);
        //   }
        //   to {
        //     -webkit-transform: rotate(0deg);
        //   }
        // }
        
        // @keyframes circle-rotate {
        //   from {
        //     transform: translate(-50%, -50%) rotate(45deg);
        //   }
        //   to {
        //     transform: translate(-50%, -50%) rotate(405deg);
        //   }
        // }
        
        // @-webkit-keyframes circle-rotate {
        //   from {
        //     -webkit-transform: translate(-50%, -50%) rotate(45deg);
        //   }
        //   to {
        //     -webkit-transform: translate(-50%, -50%) rotate(405deg);
        //   }
        // }
        
        // @keyframes img-rotate {
        //   from {
        //     transform: rotate(-45deg);
        //   }
        //   to {
        //     transform: rotate(-405deg);
        //   }
        // }
        
        // @-webkit-keyframes img-rotate {
        //   from {
        //     -webkit-transform: rotate(-45deg);
        //   }
        //   to {
        //     -webkit-transform: rotate(-405deg);
        //   }
        // }
        






    }

    .banner-second {
        padding: 55px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .accordion-body {
        color: #303A42;
        font-size: 15.29px;
        font-style: normal;
        font-weight: 400;
        line-height: 25.483px;
    }

    .accordion {
        --bs-accordion-btn-icon: url("../../../../../public/assets/images_another/plus-cus.png");
        --bs-accordion-btn-active-icon: url("../../../../../public/assets/images_another/plus-cus.png");
        .accordion-button:not(.collapsed)::after{
            --bs-accordion-btn-icon: url("../../../../../public/assets/images_another/minus-cus.png");
            --bs-accordion-btn-active-icon: url("../../../../../public/assets/images_another/minus-cus.png");
        }
        .accordion-item {
            border: none;
            border-bottom: 1px solid #ccc;

            .accordion-header {

                .accordion-button {
                    &:focus {
                        outline: none;
                        border: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }

    .plus-icon::after {
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: auto;
        content: "";
        background-image: url("../../../../../public/assets/images_another/plus.png");

        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform .2s ease-in-out;
    }

    .plus-icon:not(.collapsed)::after {
        background-image: url("../../../../../public/assets/images_another/plus2.png");

        transform: rotate(-180deg);
    }

}

@media only screen and (min-width:768px) and (max-width:992px) {
    .faq-sec {
        .banner-section {
            .big-circle {
                height: 90%;
                width: 90%;
                margin: 0 auto;
            }
            .main {
                margin: 0px auto;
                height: 490px;
                width: 490px;
                position: relative;
            }
            .circle {
                animation: circle-rotate 20s linear infinite;
                -webkit-animation: circle-rotate 20s linear infinite;
                position: absolute;
                top: 46%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
                -webkit-transform: translate(-51%, -50%) rotate(45deg);
                width: 57%;
                height: 60%;
                border: 1.68px dashed #0B6C81;
                border-radius: 50%;
            }
            .center-logo {
                position: absolute;
                top: 46%;
                left: 50%;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
            }
            .main-container {
                min-height: 100vh;
                margin-top: 15px;
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                 height: unset;
                overflow: hidden;
            }





        }
        .first-head h2 {
            font-size: 18px;
        }

        .first-head span {
            font-size: 18px;
        }

        .first-head {
            margin: 10px 0px;
            text-align: center;
            h2 {
                color: #1B375C;
                font-size: 32px;
                font-weight: 700;
                margin-top: 0px;
                margin: 0px;
            }
        }

        .first-head span {
            font-size: 32px;
        }

        .main-section {
            padding: 0px;
        }

        .banner-second {
            margin-top: 20px;
            padding: 18px;
        }
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .faq-sec {
        .banner-section {
            .big-circle {
                height: 90%;
                width: 78%;
                margin: 0 auto;
            }
            .main {
                margin: 0px auto;
                // width: 471px;
                height: 370px;
                width: 370px;
                position: relative;
            }
            .circle {
                animation: circle-rotate 20s linear infinite;
                -webkit-animation: circle-rotate 20s linear infinite;
                border: 1.68px dashed #0b6c81;
                border-radius: 50%;
                height: 49%;
                left: 50%;
                position: absolute;
                top: 46%;
                transform: translate(-50%, -50%) rotate(45deg);
                -webkit-transform: translate(-51%, -50%) rotate(45deg);
                width: 51%;
            }
            .center-logo {
                position: absolute;
                top: 46%;
                left: 50%;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
            }
            .main-container {
                min-height: 85vh;
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                 height: unset;
                overflow: hidden;
            }





        }
        .first-head h2 {
            font-size: 18px;
        }

        .first-head span {
            font-size: 18px;
        }

        .first-head {
            margin: 10px 0px;
            text-align: center;
            h2 {
                color: #1B375C;
                font-size: 24px;
                font-weight: 700;
                margin-top: 0px;
                margin: 0px;
            }
        }

        .first-head span {
            font-size: 22px;
        }

        .main-section {
            padding: 0px;
        }

        .banner-second {
            margin-top: 20px;
            padding: 18px;
        }
    }

}