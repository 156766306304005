.cursor-pointer {
    cursor: pointer;
}

.make-it-deal-breaker-sec {
    margin: 40px 0px;

    .text-center {
        .go-back-to-job-posting {
            box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.5);
            background: rgb(27, 55, 92);
            color: #fff;
            border-radius: 32px;
            padding: 10px 20px;
            border: none;
            margin-top: 4rem;
            text-decoration: none;
        }
    }

    .create-an-emp-inner {
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
        box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
        padding: 40px;

        .heading {
            h2 {
                font-weight: 600;
                margin-bottom: 8px;
                font-size: 28px;
            }
        }

        .heading2 {
            .maxi-cls {
                margin-bottom: 0px;
                text-decoration: none;
                font-weight: 600;
            }
        }

        .after-main-head {
            .after-main-atag {
                font-weight: 600;
                text-decoration: none;
                font-size: 17px;

            }

            p {
                margin-bottom: 0;
                padding: 10px 0px;
            }
        }

        .heading-inner {
            h3 {
                font-size: 22px;
                font-weight: 600;
            }
        }

        // .card-outer-parent-dv {
        //     border: 1px solid rgba(0, 0, 0, 0.25);
        //     box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.05);
        //     border-radius: 10px;

        //     margin: 20px 0px;

        //     .emp-have-closed-parent {
        //         .emp-have-closed {
        //             border: 0px solid rgba(0, 0, 0, 0.25);
        //             background: #F2F2F2;
        //             padding: 15px 40px;

        //             p {
        //                 margin-bottom: 0px;
        //             }
        //         }
        //     }

        //     .card-outer-dv {
        //         padding: 15px 40px;

        //         label {
        //             font-weight: 600;
        //         }

        //         .inner-input {
        //             .inner-input-dv {
        //                 select {
        //                     -webkit-appearance: auto;
        //                     -moz-appearance: auto;
        //                     appearance: auto;
        //                 }

        //                 .mob-para {
        //                     margin: 6px 0px 0px 0px;
        //                     color: #66666699;
        //                 }

        //                 a.mob-para {
        //                     margin: 15px 0px 0px 0px;
        //                     color: #0A65CC;
        //                     text-decoration: none;
        //                     font-size: 16px;
        //                     font-weight: 600;
        //                 }

        //                 span {
        //                     margin-right: 20px;
        //                     color: #66666699;
        //                 }
        //             }
        //         }

        //         .checkbox-size-dv {
        //             .inner-input-dv {
        //                 display: flex;
        //                 flex-direction: row;
        //                 flex-wrap: nowrap;
        //                 align-content: center;
        //                 justify-content: flex-start;
        //                 align-items: center;

        //                 input {
        //                     margin-right: 10px;
        //                     width: 18px;
        //                     height: 18px;
        //                 }

        //             }
        //         }

        //         .check-y-n-parent {
        //             .check-yes-no {
        //                 display: flex;
        //                 flex-direction: row;
        //                 flex-wrap: nowrap;
        //                 align-content: center;
        //                 justify-content: flex-start;
        //                 align-items: center;

        //                 .check-yes {
        //                     display: flex;
        //                     flex-direction: row;
        //                     flex-wrap: nowrap;
        //                     align-content: center;
        //                     justify-content: center;
        //                     align-items: center;
        //                     margin-right: 40px;

        //                     input {
        //                         margin-right: 10px;
        //                         width: 18px;
        //                         height: 18px;
        //                     }
        //                 }

        //                 .check-no {
        //                     display: flex;
        //                     flex-direction: row;
        //                     flex-wrap: nowrap;
        //                     align-content: center;
        //                     justify-content: center;
        //                     align-items: center;

        //                     input {
        //                         margin-right: 10px;
        //                         width: 18px;
        //                         height: 18px;
        //                     }
        //                 }
        //             }
        //         }

        //         .right-sd-btn {
        //             .continue-btn {
        //                 background: rgba(27, 55, 92, 1);
        //                 color: #fff;
        //                 border-radius: 40px;
        //                 padding: 10px 40px;
        //                 text-decoration: none;

        //                 span {
        //                     margin-left: 10px;
        //                 }
        //             }
        //         }
        //     }
        // }
        .question-parent-dv {
            margin: 20px 0px;

            .question-heading {}

            .question-options {
                box-shadow: 0px 6px 16px 0px #E9E9E9;
                padding: 10px 20px;

                .question-selection {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: center;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }

    }

    .create-an-emp-inner2 {
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
        box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
        padding: 40px;
        margin: 20px 0px;

        .accordion-item {
            .accordion-header {
                .accordion-button {
                    font-size: 18px;
                    font-weight: 600;
                }
            }

            .accordion-collapse {
                .accordion-body {
                    .accordion-body-small-card {
                        .accordion-body-innner-card {
                            background: #F6F6F6;
                            padding: 20px 15px;
                            border-radius: 10px;

                            .card-heading {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                align-content: center;
                                justify-content: space-between;
                                align-items: center;

                                .border-none-btn {
                                    border: none;
                                }

                                .card-a-head {
                                    text-decoration: none;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: #000;
                                    border: none;
                                    background: transparent;
                                }
                            }
                        }
                    }
                }
            }
        }

        .pre-recorded-dv-parent {
            padding: 0px 20px 20px 20px;

            .pre-rocorded-dv {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                padding: 10px 20px;

                .pre-inner {
                    input {
                        margin-right: 5px;
                    }
                }

                .see-all-question {
                    .pre-inner-a {
                        text-decoration: none;
                        font-weight: 600;
                    }
                }
            }
        }


    }

    .outside-page-submit-btn {
        .bottom-button-dv {
            margin: 40px 0px;

            .bottom-btn {
                .a-tag-btn1 {
                    border: 1px solid rgba(27, 55, 92, 1);
                    text-decoration: none;
                    border-radius: 40px;
                    color: #1B375C;
                    padding: 10px 50px;
                }

                .a-tag-btn2 {
                    border: 1px solid rgba(27, 55, 92, 1);
                    text-decoration: none;
                    border-radius: 40px;
                    background: rgba(27, 55, 92, 1);
                    color: #fff;
                    padding: 10px 40px;
                }
            }
        }
    }
}

@media only screen and (min-width:768px) and (max-width:992px) {}

@media only screen and (min-width:200px) and (max-width:767px) {
    .make-it-deal-breaker-sec {
        .create-an-emp-inner {
            padding: 10px;

            // .card-outer-parent-dv {
            //     .emp-have-closed-parent {
            //         .emp-have-closed {
            //             border: 0px solid rgba(0, 0, 0, 0.25);
            //             background: #F2F2F2;
            //             padding: 15px 10px;
            //             font-size: 15px;
            //         }
            //     }

            //     .card-outer-dv {

            //         .inner-input {
            //             .inner-input-dv {
            //                 .mob-para {
            //                     margin: 6px 0px 0px 0px;
            //                     color: rgba(102, 102, 102, 0.6);
            //                     display: flex;
            //                     flex-direction: column;
            //                 }
            //             }
            //         }

            //         .checkbox-size-dv {
            //             .inner-input-dv {
            //                 input {
            //                     margin-right: 10px;
            //                     width: 22px;
            //                     height: 22px;
            //                 }
            //             }
            //         }

            //         padding: 10px 10px !important;
            //     }

            // }

            .bottom-button-dv {
                text-align: center;
                margin: 40px 0px;

                .bottom-btn {
                    margin: 10px 0px;
                    text-align: center;
                }
            }
        }

        .create-an-emp-inner2 {
            box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
            box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
            padding: 10px;
            margin: 20px 0px;
        }

        .create-an-emp-inner {
            .question-parent-dv {
                .assement-button-pad {
                    padding: 0px 3px;

                    i {
                        font-size: 10px;
                    }
                }
            }
        }

    }



}


.password-cus-modal {
    .ps-inpt {
        position: relative;
    }

    i {
        position: absolute;
        top: 10px;
        right: 14px;
    }
}