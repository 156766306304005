.assessment-all-question-time-sec {

    .boxshadow_outer {
        box-shadow: 0px 20px 60px 0px #F1F4F880;

        box-shadow: -4px -4px 20px 0px #1B375C33 inset;
        margin: 10px;
        padding: 20px;

    }

    .cust-link {
        padding-left: 0px;
    }

    .heading {
        font-size: 32px;
        font-weight: 700;
        margin-top: 5px;
        margin-bottom: 20px;
    }

    .cust_flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .cust_outer {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .marging_header {
        margin-top: 100px;
        margin-bottom: 100px;
    }


}

@media screen and ((min-width:320px) and (max-width:576px)) {
    .assessment-all-question-time-sec {
        .heading {
            font-size: 20px;
        }
    }

}