.cv-for-emp-content-sec .bg-form {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  padding: 0px 30px;
  margin: 20px 0px;
}
.cv-for-emp-content-sec .bg-form .arror-icon {
  padding: 20px;
  text-align: left;
}
.cv-for-emp-content-sec .bg-form .arror-icon a {
  text-decoration: none;
}
.cv-for-emp-content-sec .bg-form .first-head {
  padding: 20px;
  text-align: left;
  margin-bottom: 20px;
}
.cv-for-emp-content-sec .bg-form .first-head h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.cv-for-emp-content-sec .bg-form .first-head .upload-btn {
  text-align: center;
  padding: 40px 0px;
}
.cv-for-emp-content-sec .bg-form .first-head .upload-btn .upload-btn-a {
  border-radius: 40px;
  background: #1B375C;
  width: 300px;
  padding: 10px 40px;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
}
.cv-for-emp-content-sec .bg-form .form-banner {
  padding: 0px 20px;
}
.cv-for-emp-content-sec .bg-form .form-banner .cv-for-employer-input {
  visibility: hidden;
}
.cv-for-emp-content-sec .bg-form .form-banner .increase-font-size {
  font-size: 22px;
}
.cv-for-emp-content-sec .bg-form .form-banner .upload-resume-btn {
  border-radius: 40px;
  background: #1B375C;
  width: 300px;
  padding: 10px 40px;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
}
.cv-for-emp-content-sec .bg-form .form-banner .form-select-resume {
  border: none;
}
.cv-for-emp-content-sec .bg-form .form-banner .cv-upload {
  border-radius: 12px;
  border: 1px dashed #0A65CC;
  padding: 15px;
  cursor: pointer;
}
.cv-for-emp-content-sec .bg-form .form-banner .resume p {
  margin: unset;
  color: rgba(102, 102, 102, 0.6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.cv-for-emp-content-sec .bg-form .form-banner .resume h4 {
  color: #0A65CC;
  font-size: 22px;
  font-weight: 700;
  text-align: left;
}
.cv-for-emp-content-sec .bg-form .form-banner .upload-para p {
  text-align: left;
}
.cv-for-emp-content-sec .bg-form .form-banner .upload-para p span {
  margin-left: 5px;
}
.cv-for-emp-content-sec .bg-form .form-banner .upload-para p span a {
  color: #0A65CC;
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
}
.cv-for-emp-content-sec .bg-form .form-banner .upload-btn {
  text-align: center;
  padding: 40px 0px;
}
.cv-for-emp-content-sec .bg-form .form-banner .upload-btn .upload-btn-a {
  border-radius: 40px;
  background: #1B375C;
  width: 300px;
  padding: 10px 40px;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
}

.card-outer-cvbuilder {
  cursor: pointer;
}
.card-outer-cvbuilder:hover {
  border: 2px solid #1b375c;
  background-color: #d8faf8;
}
.card-outer-cvbuilder:hover .add-btn {
  background-color: #1b375c;
  color: #FFF;
}
.card-outer-cvbuilder .add-btn {
  border: 1px solid #1b375c;
}
.card-outer-cvbuilder p.upload-cv-p-13 {
  font-size: 13px;
}

.custom-modal-backdrop-cvupload {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.5);
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .cv-for-emp-content-sec .bg-form {
    padding: 0px 10px;
  }
  .cv-for-emp-content-sec .bg-form .bg-form .first-head h2 {
    font-size: 17px;
  }
  .cv-for-emp-content-sec .bg-form .form-banner .cv-upload {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  .cv-for-emp-content-sec .bg-form .form-banner .cv-upload .upload {
    text-align: center;
  }
  .cv-for-emp-content-sec .bg-form .form-banner .resume h4 {
    font-size: 14px;
  }
  .cv-for-emp-content-sec .bg-form .form-banner .resume p {
    font-size: 10px;
    text-align: left;
  }
  .cv-for-emp-content-sec .bg-form .form-banner .upload-para p {
    text-align: center;
  }
  .cv-for-emp-content-sec .bg-form .form-banner .upload-para p span {
    margin-left: 5px;
    display: block;
  }
  .cv-for-emp-content-sec .bg-form .form-banner .upload-btn {
    text-align: center;
    padding: 0px 0px 30px 0px;
  }
  .cv-for-emp-content-sec .bg-form .form-banner .upload-btn .upload-btn-a {
    width: 190px !important;
  }
  .cv-for-emp-content-sec .upload img {
    width: 18px;
  }
  .cv-for-emp-content-sec .list-section ul {
    flex-direction: column;
  }
  .cv-for-emp-content-sec .heading a {
    margin-left: 50px;
  }
  .cv-for-emp-content-sec .form-btn2 {
    width: 190px !important;
  }
  .cv-for-emp-content-sec .bg-second {
    padding: 0px !important;
  }
  .cv-for-emp-content-sec .ptn {
    margin: 20px;
  }
  .cv-for-emp-content-sec .para span a {
    margin: 37px;
  }
  .cv-for-emp-content-sec .form-banner {
    padding: 0px 9px !important;
  }
  .cv-for-emp-content-sec .top-btn {
    margin-top: 0px !important;
  }
  .cv-for-emp-content-sec .form-btn {
    padding: 2px 24px 6px 28px !important;
  }
  .cv-for-emp-content-sec .form-btn2 {
    margin-top: 10px !important;
  }
}
.first-head-new {
  padding: 0;
}
.first-head-new .upload-resume-btn {
  border-radius: 40px;
  background: #1B375C;
  width: 300px;
  padding: 10px 40px;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
}

.folder_Browse_images {
  background: #fff;
  border: 2px dashed #000;
  border-radius: 5px;
  position: relative;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
}
.folder_Browse_images .file-input > [type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}
.folder_Browse_images .folder_main_bro {
  font-size: 18px;
  font-weight: normal;
}
.folder_Browse_images .folder_main_browse {
  padding: 10px 20px;
  border-radius: 20px;
  color: #000;
  background-color: #ffca74;
  margin: 23px 0;
  display: inline-block;
}
.folder_Browse_images .folder_main_browse i {
  margin-right: 10px;
}

.main_text_table {
  padding: 10px;
}
.main_text_table h6 {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin: 0 0 5px;
}
.main_text_table p {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin: 0 0 5px;
}
.main_text_table span {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin: 0 0 5px;
}

.main_listing_new .resume-listing {
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid #000;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.main_listing_new ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.main_listing_new ul li {
  padding: 0;
  margin: 0 0 10px;
  list-style: none;
}
.main_listing_new ul li a {
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid #000;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.main_listing_new ul li a span i {
  margin: 0 8px;
}

.tp-none {
  padding-top: 0 !important;
}

.select_cv_det {
  padding-top: 15px;
  font-weight: bold;
  margin-bottom: 6px;
}

.main_dis_flex {
  display: flex;
  align-items: center;
}/*# sourceMappingURL=CvForEmployer.css.map */