.careercompany-card-details {
    .banner-section {
        border-radius: 8px;
        border: 1px solid rgba(20, 20, 20, 0.05);
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);

        .career-img {
            margin-top: 20px;
            border: 1px solid #ccc;
            width: 65px;
            height: 65px;
            border-radius: 5px;

            img {
                max-width: 65px;
                max-height: 65px;
            }
        }

        .career-img img[alt] {
            font-size: 0.75em;
        }
    }

    .list-section {
        li {
            list-style: none;
            padding: 0px 60px 0px 0px;
            color: rgba(20, 20, 20, 0.7);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;

            img {
                margin-right: 5px;
            }
        }

    }

    .list-section {
        ul {
            display: flex;
            padding: 0;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: flex-start;
            align-items: center;
        }
    }



    .frame-img {
        /* width: 113px;
        height: 113px; */
        border-radius: 4px;
        margin: 0px;

    }

    .heading {
        a {
            padding: 2px 10px;
            border-radius: 3px;
            background: rgba(125, 90, 226, 0.1);
            color: #7D5AE2;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            text-decoration: none;
        }
    }

    .company-logo-name {
        .company-name {
            margin-bottom: 0px;
        }

        .heading {
            h2 {
                font-size: 24px;

                a {
                    font-size: 12px;
                }
            }
        }

        .para {
            span.full-half-desc {
                cursor: pointer;
            }

            p {
                color: rgba(20, 20, 20, 0.70);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
                // padding: 12px;
                margin-bottom: 0px;
            }

            span {
                color: #0A65CC;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 40px;
                // margin-left: 80px;

                a {
                    text-decoration: none;
                }
            }
        }

    }

    .bg-second {
        border-radius: 4px;
        background: #FFF;
        padding: 40px;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .bg-form {
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .arror-icon {
        a {
            text-decoration: none;
        }
    }

    .form-btn {
        background: #0A65CC;
        color: #FFF;
    }

    .top-btn-continue {
        margin: 50px 0px;
        border: none;
        outline: none;
    }

    .form-btn2 {
        border-radius: 40px;
        background: #1B375C;
        width: 488px;
        padding: 8px 0px 8px 0px;
        color: #FFF;
        font-size: 22px;
        font-weight: 500;
    }

    .top-btn {
        // margin: 80px 0px;
        text-align: center;
    }

    .first-head {
        h2 {
            font-size: 24px;
        }
    }

    .first-head {
        padding: 20px;
    }

    .arror-icon {
        padding: 20px;
    }

    .form-banner {
        padding: 0px 100px;

        .before-input-cls {
            display: flex;
        }
    }
}


@media only screen and (min-width:768px) and (max-width:992px) {
    .careercompany-card-details {
        .form-btn {
            background: #0A65CC;
            color: #FFF;
            margin: 5px 0px 0px 0px;
        }

        .list-section {
            ul {
                flex-direction: row;

                li {
                    list-style: none;
                    padding: 0px 10px 0px 0px;
                    color: rgba(20, 20, 20, 0.7);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .para {
            span {
                line-height: inherit !important;
                margin-left: 0 !important;

                a {

                    display: block;
                }
            }
        }
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {

    .careercompany-card-details {
        .company-logo-name {
            .heading {
                h2 {
                    span {
                        display: block;
                    }
                }
            }
        }

        .banner-section {
            text-align: center;

            .career-img {
                margin-top: 20px;
                border: 1px solid #ccc;
                width: 65px;
                height: 65px;
                border-radius: 5px;
            }

            .career-img img[alt] {
                font-size: 0.75em;
            }
        }

        .form-btn {
            background: #0A65CC;
            color: #FFF;
            margin: 5px 0px 0px 0px;
        }

        .list-section {
            ul {
                flex-direction: row;
                display: flex;
                flex-wrap: wrap;
                align-content: center;
                justify-content: space-between;
                align-items: center;

                li {
                    list-style: none;
                    padding: 0px 10px 0px 0px;
                    color: rgba(20, 20, 20, 0.7);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                }
            }
        }


        .heading {
            a {
                // margin-left: 50px;
            }
        }

        .form-btn2 {

            width: 190px !important;

        }

        .bg-second {
            padding: 0px !important;
        }

        .company-logo-name {
            .heading {
                text-align: center;
            }

            margin: 10px;

            .company-name {
                margin-bottom: 0px;
                text-align: center;
            }
        }

        .para {
            span {
                line-height: inherit !important;
                margin-left: 0 !important;

                a {
                    // margin: 37px;
                    display: block;
                }
            }
        }

        .form-banner {
            padding: 0px 9px !important;
        }

        .top-btn {
            margin-top: 0px !important;
        }

        .form-btn {
            // padding: 2px 24px 6px 28px !important;
        }

        .form-btn2 {
            margin-top: 10px !important;
        }
    }
}