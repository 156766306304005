.blog-category {
    .search-box {
        margin-bottom: 30px;

        h4 {
            text-align: left;
            color: #1B375C;
            font-family: Barlow;
            font-size: 22px;
            font-weight: 600;
        }

        .custm-search {
            position: relative;

            img {
                position: absolute;
                right: 12px;
                top: 12px;
            }
        }
    }

    .category-box {
        margin-bottom: 40px;

        h4 {
            text-align: left;
            color: #1B375C;
            font-family: Barlow;
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 20px;
        }

        h5 {
            text-align: left;
            border-radius: 3px;
            background: #1B375C;
            color: #FFF;
            font-family: Barlow;
            font-size: 14px;
            font-weight: 400;
            padding: 10px 15px;
            margin-bottom: 20px;
        }

        ul {
            padding: unset;
            display: flex;
            justify-content: flex-start;

            li {
                width: 122px;
                list-style: none;
            }
        }
    }

    .recent-post {
        padding: 0 5px;

        h3 {
            text-align: left;
            color: #1B375C;
            font-family: Barlow;
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 30px;
        }

        h4 {
            text-align: left;
            color: #1B375C;
            font-family: Barlow;
            font-size: 22px;
            font-weight: 600;
        }

        .custm-posts {
            .post-detail {
                .post-text {
                    h4 {
                        text-align: left;
                        color: var(--sale-tage-coloe, #3F509E);
                        font-size: 14px;
                        font-weight: 400;
                    }

                    h5 {
                        text-align: left;
                        color: var(--sub-text-color, #8A8FB9);
                        font-size: 11px;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .social {
        h4 {
            text-align: left;
            color: #1B375C;
            font-family: Barlow;
            font-size: 22px;
            font-weight: 600;
        }

        .social-icon {
            img {
                margin-right: 10px;
            }
        }
    }
}