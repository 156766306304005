.terms-condition-sec {
    margin: 50px 0px;

    .term-condi-child-dv {
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
        padding: 0px 20px;

        .top-heading {
            h2 {
                font-size: 22px;
                font-weight: 700;
                padding: 20px 0px;
            }
        }

        .last-updated {}

        .after-last-updated-content {}

        .list-items {
            .list-items-heading {
                h5 {
                    font-size: 20px;
                    font-weight: 700;
                }

                p {
                    text-align: justify;
                }
            }
        }

    }

    .text-center {
        .provide-hyperlink {
            cursor: pointer;
            text-decoration-line: underline;
            color: #007bff;
        }
    }
}

.privacy-policy-sec {
    margin: 50px 0px;

    .term-condi-child-dv {
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
        padding: 0px 20px;

        .top-heading {
            h2 {
                font-size: 22px;
                font-weight: 700;
                padding: 20px 0px;
            }
        }

        .last-updated {}

        .after-last-updated-content {}

        .list-items {
            .list-items-heading {
                h5 {
                    font-size: 20px;
                    font-weight: 700;
                }

                p {
                    text-align: justify;
                }
            }
        }
    }

    .container {
        .text-center {
            .provide-hyperlink {
                cursor: pointer;
                text-decoration-line: underline;
                color: #007bff;
            }
        }
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .terms-condition-sec {
        margin: 50px 0px;

        .term-condi-child-dv {
            box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
            padding: 0px 20px;

            .top-heading {
                h2 {
                    font-size: 22px;
                    font-weight: 700;
                    padding: 20px 0px;
                }
                
            }
            p{
                line-break: anywhere;
                a{
                    line-break: anywhere;
                }
            }
            .last-updated {}

            .after-last-updated-content {
                p {
                    font-size: 14px;
                }
            }

            .list-items {
                .list-items-heading {
                    h5 {
                        font-size: 16px;
                        font-weight: 700;
                    }

                    p {
                        text-align: justify;
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .privacy-policy-sec {
        margin: 50px 0px;

        .term-condi-child-dv {
            box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
            padding: 0px 20px;

            .top-heading {
                h2 {
                    font-size: 22px;
                    font-weight: 700;
                    padding: 20px 0px;
                }
            }

            .last-updated {}

            .after-last-updated-content {
                p {
                    font-size: 14px;
                }
            }

            .list-items {
                .list-items-heading {
                    h5 {
                        font-size: 16px;
                        font-weight: 700;
                    }

                    p {
                        text-align: justify;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}