.cv-for-emp-content-sec {
    .bg-form {
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
        padding: 0px 30px;
        margin: 20px 0px;

        .arror-icon {
            padding: 20px;
            text-align: left;

            a {
                text-decoration: none;

            }
        }

        .first-head {
            padding: 20px;
            text-align: left;
            margin-bottom: 20px;

            h2 {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 10px;
            }

            .upload-btn {
                text-align: center;
                padding: 40px 0px;

                .upload-btn-a {
                    border-radius: 40px;
                    background: #1B375C;
                    width: 300px;
                    padding: 10px 40px;
                    color: #FFF;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 500;
                    border: none;
                    outline: none;
                }
            }
        }

        .form-banner {
            padding: 0px 20px;

            .cv-for-employer-input {
                visibility: hidden;
            }

            .increase-font-size {
                font-size: 22px;
            }

            .upload-resume-btn {
                border-radius: 40px;
                background: #1B375C;
                width: 300px;
                padding: 10px 40px;
                color: #FFF;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                border: none;
                outline: none;
            }

            .form-select-resume {
                border: none;
            }

            .cv-upload {
                border-radius: 12px;
                border: 1px dashed #0A65CC;
                padding: 15px;
                cursor: pointer;
            }

            .resume {
                p {
                    margin: unset;
                    color: rgba(102, 102, 102, 0.60);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                h4 {
                    color: #0A65CC;
                    font-size: 22px;
                    font-weight: 700;
                    text-align: left;
                }
            }

            .upload-para {
                p {
                    text-align: left;

                    span {
                        margin-left: 5px;

                        a {
                            color: #0A65CC;
                            font-size: 22px;
                            font-weight: 700;
                            text-decoration: none;
                        }
                    }
                }

            }

            .upload-btn {
                text-align: center;
                padding: 40px 0px;

                .upload-btn-a {
                    border-radius: 40px;
                    background: #1B375C;
                    width: 300px;
                    padding: 10px 40px;
                    color: #FFF;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 500;
                    border: none;
                    outline: none;
                }
            }

        }


    }
}

.card-outer-cvbuilder {
    cursor: pointer;

    &:hover {
        border: 2px solid #1b375c;
        background-color: #d8faf8;

        .add-btn {
            background-color: #1b375c;
            color: #FFF
        }
    }

    .add-btn {
        border: 1px solid #1b375c;

    }

    p.upload-cv-p-13 {
        font-size: 13px;
    }
}

.custom-modal-backdrop-cvupload {
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.5);
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .cv-for-emp-content-sec {
        .bg-form {
            padding: 0px 10px;

            .bg-form {
                .first-head {
                    h2 {
                        font-size: 17px;
                    }
                }
            }

            .form-banner {
                .cv-upload {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-content: center;
                    align-items: center;
                    justify-content: center;

                    .upload {
                        text-align: center;
                    }
                }

                .resume {
                    h4 {
                        font-size: 14px;
                    }

                    p {
                        font-size: 10px;
                        text-align: left;
                    }
                }

                .upload-para {
                    p {
                        text-align: center;

                        span {
                            margin-left: 5px;
                            display: block;
                        }
                    }
                }

                .upload-btn {
                    text-align: center;
                    padding: 0px 0px 30px 0px;

                    .upload-btn-a {
                        width: 190px !important;
                    }
                }
            }
        }

        .upload {
            img {
                width: 18px;
            }
        }

        .list-section {
            ul {
                flex-direction: column;
            }
        }

        .heading a {
            margin-left: 50px;
        }

        .form-btn2 {
            width: 190px !important;
        }

        .bg-second {
            padding: 0px !important;
        }

        .ptn {
            margin: 20px;
        }

        .para span a {
            margin: 37px;
        }

        .form-banner {
            padding: 0px 9px !important;
        }

        .top-btn {
            margin-top: 0px !important;
        }

        .form-btn {
            padding: 2px 24px 6px 28px !important;
        }

        .form-btn2 {
            margin-top: 10px !important;
        }


    }
}

.first-head-new {
    padding: 0;

    .upload-resume-btn {
        border-radius: 40px;
        background: #1B375C;
        width: 300px;
        padding: 10px 40px;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
    }
}

.folder_Browse_images {
    background: #fff;
    border: 2px dashed #000;
    border-radius: 5px;
    position: relative;
    margin: 0 auto;
    text-align: center;
    padding: 20px;

    .file-input>[type='file'] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 10;
        cursor: pointer;


    }

    .folder_main_bro {
        font-size: 18px;
        font-weight: normal;
    }

    .folder_main_browse {
        padding: 10px 20px;
        border-radius: 20px;
        color: #000;
        background-color: #ffca74;
        margin: 23px 0;
        display: inline-block;

        i {
            margin-right: 10px;
        }
    }
}

.main_text_table {
    padding: 10px;

    h6 {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        margin: 0 0 5px;
    }

    p {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        margin: 0 0 5px;
    }

    span {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        margin: 0 0 5px;
    }
}

.main_listing_new {

    .resume-listing {
        padding: 10px 20px;
        border-radius: 20px;
        border: 1px solid #000;
        display: flex;
        font-size: 16px;
        font-weight: bold;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            padding: 0;
            margin: 0 0 10px;
            list-style: none;

            a {
                padding: 10px 20px;
                border-radius: 20px;
                border: 1px solid #000;
                display: flex;
                font-size: 16px;
                font-weight: bold;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;

                span {
                    i {
                        margin: 0 8px;
                    }
                }
            }
        }
    }
}

.tp-none {
    padding-top: 0 !important;
}

.select_cv_det {
    padding-top: 15px;
    font-weight: bold;
    margin-bottom: 6px;
}
.main_dis_flex{
    display: flex;
    align-items: center;
}