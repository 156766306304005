@media only screen and (min-width:568px) and (max-width:992px) {
    .blog-list {
        .blog-info {
            .blog-card {
                .date {
                    h5 {
                        padding: 5px 10px;
                    }
                }

                .blog-title {
                    h2 {
                        font-size: 20px;
                    }
                }

                .blog-para {
                    p {
                        font-size: 14px;
                    }
                }

                .blog-para2 {
                    p {
                        font-size: 14px;
                    }
                }

                .blog-para3 {
                    p {
                        font-size: 15px;
                        line-height: 35px;
                    }
                }

                .read-btn {
                    a {
                        font-size: 16px;
                    }
                }

            }

            .blog-media {
                .textarea {
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:200px) and (max-width:567px) {
    .blog-list {
        .blog-info {
            .blog-card {
                .blog-title {
                    h2 {
                        font-size: 20px;
                    }
                }

                .blog-category {
                    display: block !important;

                    .date {
                        margin: 10px 0 !important;
                    }
                    .share-btn{
                        margin-top: 1rem;
                    }

                }

                .blog-para {
                    p {
                        font-size: 14px;
                    }
                }

                .blog-para2 {
                    p {
                        font-size: 14px;
                    }
                }

                .blog-para3 {
                    p {
                        font-size: 15px;
                        line-height: 35px;
                    }
                }


                .read-btn {
                    a {
                        font-size: 16px;
                    }
                }
            }

            .blog-media {
                .textarea {
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}