.after-login-sec {
    .profile-image-parent{
        .box {
            .content {
                .percent {
                    .dot.dot-custom1{
                        transform: rotate(calc(1 * 3.6deg));
                    }
                    
                    .profile-com-number.profile-com-number-custom1{
                        transform: rotate(calc(0.2deg));
                    }
                }
            }
        }
    }
}