@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .rec-after-login-sec .header-second .navbar-nav li a {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    padding: 0 1px;
  }
  .rec-after-login-sec .header-second .custom-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .rec-after-login-sec .header-second .custom-container .custom-fluid-container {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .rec-after-login-sec .sidebar-menu-parent .sidebar-menu-toggle {
    height: 100%;
    width: 350px;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    transform: translateX(0%) !important;
    transition: 0.5ss ease-in-out;
    z-index: 999;
  }
  .rec-after-login-sec .main-header .head-sec-lft {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }
  .rec-after-login-sec .main-header .head-sec-rit {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    margin: 10px 0px;
  }
  .rec-after-login-sec .header-second .custom-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .rec-after-login-sec .header-second .custom-container .custom-fluid-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .rec-after-login-sec .header-second .navbar-ul-custom {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .rec-after-login-sec .header-second .navbar-nav li {
    margin: 10px 5px;
  }
  .rec-after-login-sec .header-second .navbar-nav li a {
    font-size: 14px;
    padding: 0px 0px;
  }
  .rec-after-login-sec .sidebar-menu-parent .sidebar-menu .sidebar-link {
    height: 400px;
    overflow-y: scroll;
  }
  .rec-after-login-sec .sidebar-menu-parent .sidebar-menu .sidebar-unlist li:last-child {
    margin-bottom: 130px;
  }
}
@media only screen and (min-width: 361px) and (max-width: 767px) {
  .rec-after-login-sec .sidebar-menu-parent .sidebar-menu-toggle {
    height: 100%;
    width: 100%;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    transform: translateX(0%) !important;
    transition: 0.5ss ease-in-out;
    z-index: 999;
  }
  .rec-after-login-sec .main-header .head-sec-lft {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }
  .rec-after-login-sec .main-header .head-sec-rit {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    margin: 10px 0px;
  }
  .rec-after-login-sec .main-header .head-sec-rit .head-right-user .user-name-add h3 {
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 138px;
  }
  .rec-after-login-sec .main-header .head-sec-rit .head-right-user .user-img {
    width: 40px;
    height: 40px;
    margin-left: 5px;
  }
  .rec-after-login-sec .main-header .head-sec-rit .search-top-area-dv .recent-search-dv {
    display: none;
  }
  .rec-after-login-sec .header-second {
    display: block;
  }
  .rec-after-login-sec .header-second .menu-tab {
    margin-right: 0px;
  }
  .rec-after-login-sec .header-second .navbar-ul-custom {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    line-height: 30px;
    align-items: center;
  }
  .rec-after-login-sec .modal-body-content {
    padding: 9px 0px;
  }
  .rec-after-login-sec .sidebar-menu-parent .sidebar-menu .sidebar-link {
    height: calc(100vh - 300px);
    overflow-y: scroll;
  }
}
@media only screen and (min-width: 200px) and (max-width: 360px) {
  .rec-after-login-sec .sidebar-menu-parent .sidebar-menu-toggle {
    height: 100%;
    width: 100%;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    transform: translateX(0%) !important;
    transition: 0.5ss ease-in-out;
    z-index: 999;
  }
  .rec-after-login-sec .sidebar-menu-parent .sidebar-menu .sidebar-menu-btn {
    width: 60px;
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0px;
    cursor: pointer;
  }
  .rec-after-login-sec .sidebar-menu-parent .sidebar-menu .sidebar-link {
    height: calc(100vh - 300px);
    overflow-y: scroll;
  }
  .rec-after-login-sec .header-second {
    display: block;
  }
  .rec-after-login-sec .header-second .menu-tab {
    margin-right: 0px;
  }
  .rec-after-login-sec .header-second .navbar-ul-custom {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    line-height: 30px;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .rec-after-login-sec .mobile_top.d-flex {
    display: flex !important;
    flex-wrap: wrap;
  }
  .rec-after-login-sec .mobile_top ul li {
    font-size: 12px;
  }
  .rec-after-login-sec .mobile_top img {
    width: 16px;
  }
}/*# sourceMappingURL=RecAfterLoginNavbarResponsive.css.map */