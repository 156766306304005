.settings .mt-custon-1 {
  margin-top: 5rem;
}
.settings a {
  text-decoration: none;
}
.settings .input-text {
  width: 100%;
  height: 44px;
  border: 1px solid #a59b9b;
  border-radius: 10px;
  padding: 10px;
}
.settings .para {
  margin-bottom: 0px;
  margin-top: 30px;
  color: #a59b9b;
  text-align: left;
}
.settings .form {
  margin: 30px;
}
.settings .second-section {
  border-radius: 13px;
  margin-top: 80px;
  margin-bottom: 80px;
  box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.settings .para-3 {
  margin-bottom: 1px;
  margin-top: 22px;
}
.settings .password-custom {
  position: relative;
}
.settings .password-custom .clickable {
  cursor: pointer;
}
.settings .password-custom span {
  position: absolute;
  right: 25px;
  top: 14px;
  color: #939191;
}
.settings .login-btn a {
  border-radius: 30px;
  background: rgb(27, 55, 92);
  color: white;
  font-size: 17px;
  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 145px;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .settings .pt-2 {
    color: #a59b9b;
    text-align: left;
  }
  .settings .pt-2 a {
    color: #a59b9b;
  }
}
@media (max-width: 480px) {
  .settings .input-text {
    font-size: 13px;
  }
  .settings .login-btn button {
    background-color: rgb(27, 55, 92);
    color: white;
    font-size: 8px;
  }
  .settings .mt-5.login-btn {
    margin-top: 20px !important;
  }
  .settings .order-md-1.order-1.mb-5 {
    margin-bottom: 20px !important;
  }
  .settings .para {
    margin-top: 10px;
  }
}/*# sourceMappingURL=Settings.css.map */