// ===================Matchinhh Hiring=================
.rec-mt-hi-banner-sec {
    // .banner-area {
    //     background: url(../../../../../public/assets/images/matching-hiring/ban.png);
    //     background-size: cover;
    //     background-position: 100%;
    //     background-repeat: no-repeat;
    //     height: 620px;

    //     .banner-sec-lft {
    //         position: relative;

    //         &:after {
    //             position: absolute;
    //             content: '';
    //             height: 100%;
    //             width: 100%;
    //             top: 0;
    //             left: 0;
    //             background: #1b375c;
    //             opacity: 0.9;
    //             z-index: -1;
    //         }

    //         &::before {
    //             position: absolute;
    //             content: '';
    //             height: 100%;
    //             width: 100%;
    //             top: 0;
    //             left: 0;
    //             background: #1B375C;
    //             opacity: 0.9;
    //             clip-path: polygon(0 0, 92% 0, 65% 100%, 0% 100%);
    //         }

    //         .banner-text {
    //             position: absolute;
    //             top: 40%;
    //             left: 50%;
    //             transform: translate(-50%, -50%);
    //             width: 600px;

    //             h1 {
    //                 text-align: left;
    //                 color: #FFF;
    //                 // font-family: Barlow;
    //                 font-size: 55px;
    //                 font-weight: 600;
    //                 // margin-bottom: 30px;
    //             }

    //             p {
    //                 text-align: left;
    //                 color: #FFF;
    //                 // font-family: Barlow;
    //                 font-size: 25px;
    //                 font-weight: 500;
    //                 line-height: 35px;
    //                 margin: 30px 0 35px 0;
    //             }

    //             a {
    //                 border-radius: 50px;
    //                 background: #0A65CC;
    //                 color: #FFF;
    //                 border: unset;
    //                 text-decoration: none;
    //                 padding: 10px 40px;
    //                 font-size: 18px;
    //                 font-weight: 400;

    //             }

    //         }
    //     }
    // }

    .banner-area {
        // background: url("../../../../public/assets/images/matching-hiring/h-banner.png");
        background-size: cover;
        background-position: 100%;
        background-repeat: no-repeat;
        height: 500px;
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        .banner-sec-lft {
            
            &:after {
                position: absolute;
                content: '';
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                background: #1b375c;
                opacity: 0.9;
                z-index: -1;
            }

            &::before {
                position: absolute;
                content: '';
                height: 100%;
                width: 70%;
                top: 0;
                left: 0;
                background: #1B375C;
                opacity: 0.9;
                clip-path: polygon(0 0, 100% 0, 64% 100%, 0% 100%);
                z-index: 0;
            }
            .banner-sec-lft-cus{
                position: relative;
                z-index: 2;
            }
            .banner-text {
                // position: absolute;
                // top: 50%;
                // left: 50%;
                // transform: translate(-50%, -50%);
                // width: 600px;
                padding: 10px;
                h1 {
                    text-align: left;
                    color: #FFF;
                    // font-family: Barlow;
                    font-size: 50px;
                    font-weight: 600;
                    // margin-bottom: 30px;
                }

                p {
                    text-align: left;
                    color: #FFF;
                    // font-family: Barlow;
                    font-size: 16px;
                    line-height: normal;
                    margin-bottom: 30px;
                }

                a {
                    border-radius: 50px;
                    background: #0A65CC;
                    color: #FFF;
                    border: unset;
                    text-decoration: none;
                    padding: 10px 40px;
                    font-size: 16px;
                    font-weight: 400;

                }

            }
        }
    }
}

// --------------------End-------------------

// ===================Free Job Post=================
.rec-free-job-post-sec {
    // background: #F6F6F6;
    // position: relative;
    // z-index: -999;

    .head-area {
        h2 {
            color: #1B375C;
            text-align: center;
            font-size: 48px;
            font-weight: 700;
        }

        h3 {
            color: #1B375C;
            text-align: center;
            font-size: 36px;
            font-weight: 700;
        }
    }

    .custm-features {
        h3 {
            text-align: left;
            color: #ffffff;
            font-size: 28x;
            font-weight: 600;
            line-height: 140%;
            letter-spacing: 0.16px;
        }

        h4 {
            text-align: left;
            color: #ffffff;
            font-size: 22px;
            font-weight: 500;
            line-height: 34px;
        }

        p {
            overflow-y: scroll;
            text-align: left;
            color: #ffffff;
            height: 200px;
            padding: 10px;

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: unset;
                background-color: transparent;
            }

            &::-webkit-scrollbar {
                width: 6px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #fff;
            }
        }
    }

    .custm-features {
        background-image: url(../../../../../public/assets/images/matching-hiring/f1.png);
        position: relative;
        background-position: 100%;
        background-size: cover;
        height: 300px;
        padding: 40px;

        &::before {
            position: absolute;
            content: '';
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            opacity: 0.9;
            z-index: -1;
            background: linear-gradient(181deg, #1B375C 19%, #1B5DB5 94.34%), rgba(0, 0, 0, 0.45);
        }
    }






}

// --------------------End-------------------

// ===================Free Job Post=================
.rec-search-candidates-sec {
    background: #F6F6F6;

    .head-area {
        h2 {
            color: #1B375C;
            text-align: center;
            font-size: 36px;
            font-weight: 700;
        }

        h4 {
            color: #1E1E1E;
            font-size: 22px;
        }
    }

    .form-area {
        width: 535px;
        height: 500px;
        flex-shrink: 0;
        padding: 60px 40px 40px 40px;
        border-radius: 30px;
        background: #1B375C;
        position: relative;
        z-index: 9;

        h5 {
            text-align: left;
            color: #FFF;
            font-size: 16px;
            font-weight: 400;
            padding: 0 0 20px 0;
            line-height: 25px;
        }

        label {
            display: flex;
            color: #ffffff;
        }

        input {
            background: #1B375C;
            height: 40px;

            &::placeholder {
                color: #F6F6F6;
                padding: 0 0 0 10px;
            }
        }

        textarea {
            background: #1B375C;
            height: 70px;

            &::placeholder {
                color: #F6F6F6;
                padding: 0 0 0 10px;
            }
        }

        .login-btn {
            width: 90%;
            padding: 10px 10px;
            border-radius: 32px;
            background: #F0F7FF;
            box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.25);
            color: #1B375C;
            font-size: 17px;
            font-weight: 500;
            text-decoration: none;
        }

    }

    .form-img {
        img {
            width: 662px;
            height: 495px;
        }

    }
}

// --------------------End-------------------