.company-profile-details-sec .comp_dtl_page .subscrip-card {
  border-radius: 4px;
  padding: 4px 21px 24px 19px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.company-profile-details-sec .comp_dtl_page .subscrip-card h1 {
  color: #1B375C;
  /* font-family: Barlow; */
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  /* line-height: normal; */
  letter-spacing: -0.84px;
}
.company-profile-details-sec .comp_dtl_page .subscrip-card .cus-btn .btn-1 {
  border-radius: 10px;
  border: 1px solid #1B375C;
  color: #1B375C;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.company-profile-details-sec .comp_dtl_page .subscrip-card .cus-btn .btn-2 {
  color: #FFF;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 10px;
  background: #1B375C;
}
.company-profile-details-sec .comp_dtl_page .subscrip-card .cus-msg h3 {
  color: #2F343A;
  /* Text M/Bold */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
}
.company-profile-details-sec .comp_dtl_page .subscrip-card .cus-msg p {
  color: #2F343A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.company-profile-details-sec .comp_dtl_page .subscrip-card .cus-msg a {
  color: #6913D8;
  /* Text M/Bold */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
}
.company-profile-details-sec .comp_dtl_page .subscrip-card .cus-int {
  display: flex;
  justify-content: start;
  border-radius: 4px;
  align-items: center;
  border: 1px solid #E9EAED;
  background: #FFF;
  padding: 12px 52px 12px 16px;
}
.company-profile-details-sec .comp_dtl_page .subscrip-card .cus-int p {
  color: var(--neutral-90, #2F343A);
  /* Text M/Medium */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0px;
  /* 142.857% */
}
.company-profile-details-sec .comp_dtl_page .subscrip-card .cus-msg-2 h3 {
  color: #2F343A;
  /* Text M/Bold */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
}
.company-profile-details-sec .comp_dtl_page .subscrip-card .cus-team {
  border-radius: 4px;
  border: 1px solid #E9EAED;
  background: #FFF;
  padding: 6px;
  text-decoration: none;
}
.company-profile-details-sec .comp_dtl_page .subscrip-card .cus-team h5 {
  color: #2F343A;
  /* Text M/Bold */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
}
.company-profile-details-sec .comp_dtl_page .subscrip-card .cus-team p {
  color: #6F747A;
  /* Text M/Medium */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  margin-bottom: 0px;
}
.company-profile-details-sec .comp_dtl_page .subscrip-card .cus-team-2 {
  text-decoration: none;
}
.company-profile-details-sec .comp_dtl_page .subscrip-card .cus-team-2 h5 {
  color: #2F343A;
  /* Text M/Bold */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
}
.company-profile-details-sec .comp_dtl_page .subscrip-card .cus-team-2 p {
  color: #6F747A;
  /* Text M/Medium */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  margin-bottom: 0px;
}
.company-profile-details-sec .comp_dtl_page .subscrip-card .cus-team-2 .vl {
  border-left: 1px solid #E9EAED;
  height: 80px;
}
.company-profile-details-sec .comp_dtl_page .subscrip-card .outer-form2 .right-mark-dv {
  position: relative;
}
.company-profile-details-sec .comp_dtl_page .subscrip-card .outer-form2 .right-mark-dv .right-mark-img {
  position: absolute;
  top: 10px;
  right: 10px;
}
.company-profile-details-sec .comp_dtl_page .subscrip-card .outer-form2 label {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.company-profile-details-sec .comp_dtl_page .subscrip-card .outer-form2 span {
  color: #FF3A44;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.company-profile-details-sec .comp_dtl_page .subscrip-card .outer-form2 .outer-form-area2 h6 {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .company-profile-details-sec .comp_dtl_page .subscrip-card .cus-int {
    display: flex;
    justify-content: start;
    border-radius: 4px;
    align-items: center;
    border: 1px solid #E9EAED;
    background: #FFF;
    padding: 12px 0px 12px 10px;
  }
}/*# sourceMappingURL=RecCompanyProfileDetails.css.map */