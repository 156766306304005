.news-table {
    .profile-description {
        max-height: 100px;
        overflow-y: auto;
        overflow: hidden;
        // display: -webkit-box;
        // -webkit-line-clamp: 4;
        // -webkit-box-orient: vertical;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar {
            width: 5px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #1B375C;
            background-image: -webkit-linear-gradient(45deg,
                    rgba(255, 255, 255, .2) 25%,
                    transparent 25%,
                    transparent 50%,
                    rgba(255, 255, 255, .2) 50%,
                    rgba(255, 255, 255, .2) 75%,
                    transparent 75%,
                    transparent);
        }
    }

   

    .capitalize {
        text-transform: capitalize;
    }

    img {
        max-width: 150px;
        max-height: 150px;
        object-fit: contain;
    }
    video {
        max-width: 150px;
        max-height: 150px;
        object-fit: contain;
    }

    .delete-button {
        border: 1px solid #dc3545;
        background: none;
        border-radius: 0.5rem;
        background-color: white;
        padding: 3px 8px;
        font-size: 16px;
        font-weight: 600;
        color: #dc3545;
    }

    .w-fit {
        width: fit-content !important;
    }
}

.center-text {
    text-align: center;
}

.w-fit {
    width: fit-content
}