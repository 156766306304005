$color_1: #0d6efd;

.candidate-table {
  .form-check-input {
    width: 22px;
    height: 22px;
    border: 1px solid #999;
  }

  .text-lg {
    font-size: 18px;
  }

  .text-sm {
    font-size: 14px;
  }

  border: 1px solid #ebe4e4;

  th {
    vertical-align: middle;
  }

  td {
    vertical-align: middle;
  }
}

.crear-btn {
  color: #9b9b9b
}

.waiting-review {
  border-radius: 30px;
  background: #f0f0f0;
  padding: 6px 12px;
  width: fit-content;
}

.badge-checklist {
  span {
    display: inline-block;
    margin-right: 5px;
  }

  .w-fit {
    width: fit-content;
  }
}

.activity {
  font-weight: bold;
}

.send-message {
  color: $color_1;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.job-post-text {
  border-radius: 5px;
  border: 1px solid rgb(127, 168, 149);
  ;
  font-size: 14px;
  font-weight: 600;
  color: rgb(25 135 84);
}

.radio-group {
  display: flex;
  justify-content: start;
  align-items: center;
  // margin-top: 20px;
}

.radio-group input[type="radio"] {
  display: none;
}

.radio-group {
  .shortlist-candidate-btn {
    padding: 8px 13px;
    width: 100%;
    border: 1px solid #ccc;
    margin-right: -1px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px 0 0 5px;
  }

  .shortlist-candidate-btn-selected {
    background-color: green;
    color: white;

    .done-icon {
      color: white !important;
    }
  }

  .rejected-candidate-btn {
    padding: 8px 13px;
    width: 100%;
    border: 1px solid #ccc;
    margin-right: -1px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 5px 5px 0;
  }

  .rejected-candidate-btn-selected {
    background-color: red;
    color: white;

    .done-icon {
      color: white !important;
    }
  }
}

.radio-group label {
  padding: 8px 13px;
  width: 100%;
  border: 1px solid #ccc;
  margin-right: -1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-table-header {
  th {
    background-color: #f2f2f2;
  }

  height: 80px;
}

.radio-group label:hover {
  background-color: #f0f0f0;
}

.radio-group input[type="radio"]:checked+label {
  background-color: #0056b3;
  color: white;
  border-color: #0056b3;
}

.radio-group label:first-of-type {
  border-radius: 5px 0 0 5px;
}

.radio-group label:last-of-type {
  border-radius: 0 5px 5px 0;
}

.radio-group label:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}

@media screen and (max-width:991px) {
  .show-result {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
}

@media screen and (max-width:767px) {
  .show-result {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}

@media screen and (max-width:575px) {
  .show-result {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

.show-result {
  .find-candidate-active-tab-list {
    font-weight: 800 !important;
  }
}