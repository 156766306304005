// .career-start-search-section {
//     margin: 20px 0px;

//     .career-section {
//         background-image: url("../../../../../../public/assets/career-start/career-start-banner.png");
//         background-position: 100%;
//         background-size: cover;
//         padding: 85px;
//     }

//     .career-section .career-top-heading h2 {
//         text-align: center;
//         font-size: 50px;
//         font-weight: 400;
//     }

//     .career-section .career-top-heading span {
//         text-align: center;
//         color: #1B375C;
//         font-size: 50px;
//         font-weight: 700;
//     }

//     .career-section .career-second-heading h6 {
//         color: #1B375C;
//         font-size: 18px;
//         font-weight: 500;
//         background-color: #fff;
//         width: 40%;
//         border-radius: 10px;
//         padding: 5px;
//     }

//     .career-section .career-second-heading span {
//         color: #1B375C;
//         font-size: 18px;
//         font-weight: 700;
//     }

//     .career-section .custom-form-section {
//         display: flex !important;
//     }

//     .search-custom {
//         position: relative;
//         width: 335px;

//         &:nth-child(1) .form-custom {
//             border-radius: 8px 0px 0px 8px;
//         }

//         &:nth-child(2) .form-custom {
//             border-radius: 0px;
//         }

//         &:nth-child(3) .form-custom {
//             border-radius: 0px;
//         }
//     }

//     .search-custom .form-custom {
//         height: 53px;
//         padding: 0 0 0 50px;
//     }

//     // .search-custom input::-moz-placeholder {
//     //     padding: 0 0 0 50px;
//     // }

//     // .search-custom input::placeholder {
//     //     padding: 0 0 0 50px;
//     // }

//     .search-custom img {
//         position: absolute;
//         top: 11px;
//         left: 15px;
//         color: #b0a7a7;
//     }

//     .form-custom-btn {
//         border-radius: 0px 8px 8px 0px;
//         background: #1B375C;
//         color: #FFF;
//         width: 102px;
//         height: 53px;
//         font-size: 15px;
//         font-weight: 500;
//         border: none;
//         outline: none;
//     }

//     /*=============================================== silder-start-css=============================== */
//     .footer-list-card ul li {
//         list-style: none;
//         color: #565656;
//         font-size: 16px;
//         font-weight: 400;
//         margin-top: 16px;
//     }

//     .footer-list-card ul li img {
//         margin-right: 10px;
//     }

//     .footer-list-card ul {
//         padding: unset;

//     }

//     .card-custom {
//         border: none;
//         padding: 20px !important;
//         border-radius: 4px;
//         background: #FFF;
//         box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
//     }

//     .card-first-custom {
//         border: none;
//         padding: 40px;
//         border-radius: 4px;
//         background: #FFF;
//         box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
//     }

//     .card-second-custom {
//         border: none;
//         padding: 50px;
//         border-radius: 4px;
//         background: #FFF;
//         box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
//     }

//     .card-custom-2 {
//         border: none;
//         padding: 35px;
//         border-radius: 4px;
//         background: #FFF;
//         box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
//     }

//     .card-custom-3 {
//         border: none;
//         padding: 30px;
//         border-radius: 4px;
//         background: #FFF;
//         box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
//     }

//     .footer-heading h3 {
//         color: #141414;

//         font-size: 16px;

//         font-weight: 500;


//     }

//     .footer-heading span a {
//         color: #2B85FF;
//         text-decoration: none;

//         font-size: 15px;
//         margin-left: 15px;


//         font-weight: 500;
//         border-radius: 3px;
//         background: rgba(43, 133, 255, 0.10);

//     }

//     .footer-icon span a {
//         color: #FE6108;
//         text-decoration: none;

//         font-size: 15px;
//         margin-left: 15px;


//         font-weight: 500;
//         border-radius: 3px;
//         background: rgba(254, 97, 8, 0.10);

//     }

//     .footer-heading p {
//         color: #565656;

//         font-size: 15px;

//         font-weight: 400;



//     }



//     .second-section {
//         border-radius: 4px;
//         background: #FFF;
//         margin-top: 30px;
//         padding: 30px;
//         box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
//     }

//     .card-heading h3 {


//         color: #141414;

//         font-size: 26px;

//         font-weight: 700;

//     }

//     .card-second-heading h6 {
//         color: #141414;
//         text-align: center;

//         font-size: 20px;

//         font-weight: 500;
//     }


// }

.career-start-search-section {
    // margin: 20px 0px;
    .form-custom-btn {
        background: #1b375c;
        border: none;
        border-radius: 0 8px 8px 0;
        color: #fff;
        font-size: 15px;
        font-weight: 500;
        height: 53px;
        outline: none;
        width: 102px;
    }
    .career-section {
        // background-image: url("../../../../../../public/assets/career-start/career-start-banner.png");
        background-position: 100%;
        background-size: cover;
        padding: 85px;

        .career-top-heading {

            h2 {

                text-align: center;
                font-size: 50px;

                font-weight: 400;

            }

            span {
                text-align: center;
                color: #1B375C;

                font-size: 50px;

                font-weight: 700;

            }



        }

        .career-second-heading {
            h6 {
                color: #1B375C;
                font-size: 18px;
                font-weight: 500;
                background-color: #fff;
                width: 40%;
                border-radius: 10px;
                padding: 5px;
            }

            span {
                color: #1B375C;
                font-size: 18px;
                font-weight: 700;
            }
        }

        .custom-form-section {
            display: flex !important;

        }
    }

    .search-custom {
        position: relative;
        width: 75vw;

        &:nth-child(1) {
            .form-custom {
                border-radius: 5px 0px 0px 5px;
            }
        }

        &:nth-child(2) {
            .form-custom {
                border-radius: 0px;
            }
        }

        &:nth-child(3) {
            .form-custom {
                border-radius: 0px;
            }
        }

        .form-custom {
            height: 53px;
            padding: 2px 0px 0px 50px;
            border-radius: 0px;
        }
        // input.form-custom::placeholder {
        //     padding: none;
        //     padding-left: 30px;

        // }
        // input {
        //     &::placeholder {
        //         padding: 0 0 0 50px;
        //     }
        // }

        img {
            position: absolute;
            top: 50%;
            left: 1%;
            transform: translate(-0%, -50%);
            color: #b0a7a7;
        }


    }

    .form-custom-btn {
        button {
            border-radius: 0px 8px 8px 0px;
            background: #1B375C;
            color: #FFF;
            box-shadow: none;
            width: 102px;
            height: 53px;
            font-size: 15px;
            border: none;
            font-weight: 500;
        }
    }

    .dropdown-custom-btn {
        display: flex !important;
        justify-content: flex-start;
        margin-top: 13px;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;

        #address_city{
            width: 100%;
        }


        .dropdown {
            margin: 10px 5px;
        }
    }

    .dropdown-second-custom-btn {

        display: flex;
        justify-content: space-evenly;
        margin-top: 10px;
    }

    .btn-custom {
        border-radius: 7px;
        background: #FFF;
        color: #1B375C;


        font-size: 18px;

        font-weight: 600;

        border: none;
    }

    .career-second-section {
        .ptn {
            .top-para {
                margin-bottom: 0px;
            }
        }

        .card-section {
            border-radius: 4px;
            background: #FFF;
            box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
            padding: 30px;

            .pagintion-custom {
                .pagination-div {
                    .page-item {
                        .page-link {
                            cursor: pointer !important;
                        }
                    }

                }
            }

            .form-heading {
                h3 {
                    color: #141414;
                    font-size: 30px;
                    font-weight: 600;
                }
            }

            .form-desgin {
                border-radius: 8px;
                border: 1px solid #0A65CC;
                background: #FFF;
                box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
                padding: 19px;

                .search-result-section-img {
                    border: 1px solid #ccc;
                    width: 65px;
                    height: 65px;
                    border-radius: 5px;
                }
            }

            .ptn {
                .top-para {
                    p {
                        color: #141414;
                        font-size: 18px;
                        font-weight: 400;
                    }
                }





                .heading {
                    h2 {
                        color: #141414;

                        font-size: 24px;

                        font-weight: 500;

                        .tile {
                            padding: 2px 6px;
                            border-radius: 3px;
                            background: rgba(125, 90, 226, 0.10);
                            color: #7D5AE2;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            text-decoration: none;
                        }
                    }


                }

                .list-section {
                    ul {
                        display: flex;
                        justify-content: space-between;
                        padding: unset;

                        li {
                            list-style: none;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            align-content: center;
                            justify-content: center;
                            align-items: center;

                            img {
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }

        .info-section {
            border-radius: 4px;
            background: #FFF;
            padding: 25px;
            box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;

            .top-header {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: center;

                .top-header-child {
                    display: flex;

                    .top-head {
                        margin-left: 10px;

                        h3 {
                            font-size: 21px;
                            margin-bottom: 0px;
                        }

                        p {
                            margin-top: 10px;

                            span {
                                &:nth-child(2) {
                                    background: rgb(11, 160, 44);
                                    color: #fff;
                                    border-radius: 3px;
                                    padding: 2px 10px;
                                    margin: 0px 5px;
                                }

                                &:nth-child(3) {
                                    background: rgba(255, 237, 237, 1);
                                    color: rgba(224, 81, 81, 1);
                                    border-radius: 20px;
                                    padding: 2px 10px;
                                    margin: 0px 5px;
                                }
                            }
                        }
                    }

                    .company_image {
                        border: 1px solid #ccc;
                        width: 65px;
                        height: 65px;
                        border-radius: 5px;
                    }

                    .company_image img[alt] {
                        font-size: 0.75em;
                    }
                }

                .top-btn {
                    a {
                        text-decoration: none;
                    }
                }

            }

            .bookmark-apply-dv {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: flex-start;
                align-items: center;

                // .bookmark-icon {}

                .apply-btn-bookmark {
                    padding: 15px 32px 15px 32px;
                    background: rgba(10, 101, 204, 1);
                    color: #fff;
                    border-radius: 5px;
                    margin-left: 10px;

                    .apply-tag {
                        text-decoration: none;
                        color: #fff;
                        background-color: transparent;
                        border: none;
                        box-shadow: none;
                    }
                }

                .apply-btn-bookmark-applied {
                    padding: 15px 32px 15px 32px;
                    background: rgb(2, 175, 31);
                    color: #fff;
                    border-radius: 5px;
                    margin-left: 10px;

                    .apply-tag {
                        text-decoration: none;
                        color: #fff;
                        background-color: transparent;
                        border: none;
                        box-shadow: none;
                    }
                }
            }

            .salary-location-dv {
                margin: 20px 0px;
                border: 2px solid rgba(231, 240, 250, 1);
                padding: 15px;
                border-radius: 8px;

                p {
                    margin-bottom: 0px;
                }

                .border-right {
                    border-right: 2px solid #ccc;
                }

                .left-section {
                    h5 {
                        margin-bottom: 0px;
                        color: rgba(11, 160, 44, 1);
                    }
                }

                .left-section2 {
                    h5 {
                        margin-bottom: 0px;
                    }
                }
            }

            .left-card-second {
                border: 2px solid rgba(231, 240, 250, 1);
                border-radius: 8px;

                .border-bottom-cus {
                    border-bottom: 2px solid rgba(231, 240, 250, 1);
                }

                .list-head {
                    margin-bottom: 10px;

                    h3 {
                        font-size: 20px;
                    }
                }

                .list-top-heading {
                    padding: 20px;

                    .card-list {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-content: center;
                        justify-content: flex-start;
                        align-items: center;

                        .social-media-parent {
                            margin-right: 10px;
                        }
                    }
                }

                .card-pad {
                    margin-bottom: 20px;

                    img {
                        margin-bottom: 10px;
                    }

                    p {
                        margin-bottom: 0px;
                        color: #7c7b7b;
                    }

                    h6 {
                        font-size: 15px;
                    }
                }
            }

            .info-section-heading {
                padding: 30px;

                // .job-description-heading {}

                .info-dv-para-scroll {
                    height: 600px;
                    overflow-y: scroll;
                }
            }
        }
    }



}

@media only screen and (min-width:768px) and (max-width:991px) {
    .career-start-search-section {
        .btn-custom {
            font-size: 15px;
        }

        .career-section {
            padding: 15px 0px;

            .custom-form-section {
                display: flex !important;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: center;
                justify-content: flex-start;
                align-items: center;
            }

            .search-custom{
                width: 100% !important;
            }

            .search-custom .form-custom {
                // height: 41px;
                width: 100%;
                margin-top: 6px;
                border-radius: 5px 5px 5px 5px;
            }

            .form-custom-btn {
                // width: 100%;
                height: 41px;
                font-size: 15px;
                margin-top: 11px;
                margin-bottom: 11px;
                border-radius: 5px;
                border: none;
                padding: 10px 20px;
                width: 100%;
                border-radius: 5px 5px 5px 5px;

                button {
                    border-radius: 8px 0px 0px 8px;
                    background: transparent;
                    border: none;
                    color: #FFF;
                    width: 100%;
                    height: unset;
                    font-size: 15px;
                    font-weight: 500;
                    box-shadow: none;
                }
            }

            .career-top-heading {
                h2 {
                    font-size: 28px;
                }

                span {
                    font-size: 28px;
                }
            }

            .career-second-heading {
                h6 {
                    color: #1B375C;
                    font-size: 24px;
                    font-weight: 500;
                    background-color: #fff;
                    width: 100%;
                    border-radius: 10px;
                    padding: 5px;
                }
            }
        }
    }

}

@media only screen and (min-width:200px) and (max-width:767px) {
    .career-start-search-section {
        .btn-custom {
            font-size: 14px;
        }

        .career-section {
            padding: 15px 0px;

            .custom-form-section {
                display: flex !important;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
                align-items: center;

            }

            .search-custom{
                width: 100%  !important;
            }

            .search-custom input.form-custom {
                // height: 41px;
                width: 100%;
                margin-top: 6px;
                // border-radius: 0px;
                border-radius: 5px 5px 5px 5px;
                padding-right: 0px;
                padding-top: 0px;
                padding-bottom: 0px;
                padding-left: 30px;

                &::placeholder {
                    padding: none;
                    padding-left: 0px;
                }

                &:hover {
                    box-shadow: none;
                }
            }
            .search-custom select.form-custom {
                // height: 41px;
                width: 100%;
                margin-top: 6px;
                // border-radius: 0px;
                border-radius: 5px 5px 5px 5px;
                padding-right: 0px;
                padding-top: 0px;
                padding-bottom: 0px;
                padding-left: 30px;

                &::placeholder {
                    padding: none;
                    padding-left: 0px;
                }

                &:hover {
                    box-shadow: none;
                }
            }

            .search-custom input.form-custom::placeholder {
                padding: none;
                padding-left: 0px;

            }

            .search-custom input.form-custom:hover {
                box-shadow: none;
            }

            .form-custom-btn {
                // width: 100%;
                height: 41px;
                font-size: 15px;
                margin-top: 11px;
                margin-bottom: 7px;
                border-radius: 5px;
                border: none;
                padding: 10px 20px;
                width: 100%;
                border-radius: 5px 5px 5px 5px;

                button {
                    border-radius: 8px 0px 0px 8px;
                    background: transparent;
                    border: none;
                    color: #FFF;
                    width: 100%;
                    height: unset;
                    font-size: 15px;
                    font-weight: 500;
                    box-shadow: none;
                }
            }

            .career-top-heading {
                h2 {
                    font-size: 28px;
                }

                span {
                    font-size: 28px;
                }
            }

            .career-second-heading {
                h6 {
                    color: #1B375C;
                    font-size: 16px;
                    font-weight: 500;
                    background-color: #fff;
                    width: 100%;
                    border-radius: 10px;
                    padding: 5px;
                }
            }
        }
    }

}