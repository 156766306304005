@media only screen and (max-width:1201px) {}

@media only screen and (min-width:993px) and (max-width:1200px) {
    .after-login-sec {
        .header-second {
            .navbar-nav {
                li.nav-item-custom-space {
                    margin: 0 20px;
                }
            }
        }
    }
}

@media only screen and (min-width:993px) and (max-width:1080px) {
    .after-login-sec {
        .header-second {
            .navbar-nav {
                li.nav-item-custom-space {
                    margin: 0 20px;
                }
            }
        }
    }
}
@media only screen and (min-width:768px) and (max-width:992px) {

    .sidebar-menu-parent {
        .sidebar-menu {
            .sidebar-link {
                height: 340px;
                overflow-y: scroll;
            }

            .sidebar-unlist {
                li {
                    &:last-child {
                        margin-bottom: 130px;
                    }
                }
            }
        }
    }

    .after-login-sec {
        .sidebar-menu-parent{
            .sidebar-menu-toggle {
                height: 100%;
                width: 350px;
                background-color: #fff;
                position: fixed;
                left: 0;
                top: 0;
                z-index: 999999;
                transform: translateX(0%) !important;
                transition: 0.5ss ease-in-out;
            }
        }
        .main-header {
            .head-sec-rit {
                .head-right-user {
                    .user-name-add {
                        h3{
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                        p {
                            color: #fff;
                            margin-bottom: 0;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
        .header-second{
            
            .navbar-nav {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-content: center;
                flex-wrap: wrap;
                align-items: center;
                li{
                    a {
                        color: #000;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
                li.nav-item-custom-space {
                    margin: 0 0px;
                }
            }
        }
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .after-login-sec {
        .sidebar-menu-parent{
            .sidebar-menu-toggle {
                height: 100%;
                width: 100%;
                background-color: #fff;
                position: fixed;
                left: 0;
                top: 0;
                z-index: 10;
                transform: translateX(0%) !important;
                transition: 0.5ss ease-in-out;
            }
        }
        .main-header {
            .head-sec-rit {
                .head-right-user {
                    .user-name-add {
                        p {
                            color: #fff;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .header-second {
            display: block;

            .menu-tab {
                margin-right: 0px;
            }

            .navbar-ul-custom {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;
            }
        }

        .modal-body-content {
            padding: 9px 0px;
        }

        .sidebar-menu-parent {
            .sidebar-menu {
                .sidebar-link {
                    // height: 340px;
                    overflow-y: scroll;
                }
            }
        }
    }
}




@media only screen and (min-width:200px) and (max-width:360px)
{
    .after-login-sec {
        .sidebar-menu-parent {
            .sidebar-menu {

                .sidebar-menu-btn {
                    width: 60px;
                    background-color: #fff;
                    position: absolute;
                    top: 0;
                    right: 0;
                    cursor: pointer;
                }

            }
            .sidebar-menu-toggle{
                height: 100%;
                width: 100%;
            }
        }
    }
}