.interview-question-listing {
    .container {
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    h1 {
        color: #141414;
        font-family: Barlow;
        font-size: 32px;
        font-weight: 700;
    }

    h3 {
        color: #000;
        font-family: Poppins;
        font-size: 24px;
        font-weight: 500;
    }

    p {
        color: #000;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
    }

    .col-lg-4 {
        padding: 30px;

        img {
            padding: 0 0 15px 0;
        }
    }

    // .col-lg-4:nth-child(2) {
    //     border-bottom: 1px solid #DDD;
    // }

    // .col-lg-4:nth-child(3) {
    //     border: 1px solid #DDD;
    //     border-top: unset;
    // }
    .custm-ques-sec {
        cursor: pointer;

        .question-logo{
            min-width: 50px;
            min-height: 50px;
            max-width: 50px;
            max-height: 50px;
            // border:1px solid #ccc;
            border-radius:5px
        }
    }
    .custm-ques-sec:nth-child(3n+0) {
        border: 1px solid #DDD;
        border-top: unset;
    }
    .custm-ques-sec:nth-child(1n+1) {
        border-bottom: 1px solid #DDD;
    }

    // .col-lg-4:nth-child(4) {
    //     border-bottom: 1px solid #DDD;
    // }

    // .col-lg-4:nth-child(6) {
    //     border: 1px solid #DDD;
    //     border-bottom: unset;
    //     border-top: unset;
    // }
    // .custm-ques-sec {
    //     border: 1px solid #DDD;
    //     border-bottom: unset;
    //     border-top: unset;
    //     border-right: unset;
    // }
    // .custm-ques-sec-bottom {
    //     border-bottom: 1px solid #DDD;
    // }

    .view-btn {
        a {
            padding: 10px 50px;
            text-decoration: none;
            border-radius: 8px;
            border: 1px solid rgba(27, 55, 92, 0.64);
            background: var(--light-background-transparent, rgba(255, 255, 255, 0.00));
            color: #1B375C;
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
        }
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .interview-question-listing {
        h1 {
            font-size: 25px;
        }

        h3 {
            font-size: 18px;
        }

        .view-btn {
            a {
                padding: 4px 22px;
                font-size: 14px;
            }
        }
    }
}